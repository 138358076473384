import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  useContext,
  useEffect,
  useReducer,
  useState
} from 'react';
import { getColor, getItemFromStore } from 'helpers/utils';
import { Config, initialConfig } from 'config';
import { ACTIONTYPE, configReducer, SET_CONFIG } from 'reducers/ConfigReducer';
import { User, onAuthStateChanged, Auth } from 'firebase/auth';
import {
  firebaseAuth,
  getUserInfo,
  IUserInfo,
  ITeamInfo,
  TRankInfo,
  getTeams,
  getRank,
  getUsers,
  ICategory,
  ICategoryInfo,
  dbGetCategory
} from 'lib/firebase';
import {
  getSecureStorage,
  setSecureStorage,
  removeSecureStorage
} from 'lib/useLocalStorage';

interface AppContextInterFace {
  config: Config;
  configDispatch: Dispatch<ACTIONTYPE>;
  toggleTheme: () => void;
  setConfig: (payload: Partial<Config>) => void;
  getThemeColor: (name: string) => string;
  user: User | null;
  auth: Auth | null;
  admin: boolean;
  userInfo: IUserInfo | undefined;
  updateUserInfo: () => void;
  teamList: ITeamInfo[];
  setTeamList: Dispatch<ITeamInfo[]>;
  rankList: TRankInfo[];
  setRankList: Dispatch<TRankInfo[]>;
  userList: IUserInfo[];
  setUserList: Dispatch<IUserInfo[]>;
  categoryList: ICategoryInfo[];
  setCategoryList: Dispatch<ICategoryInfo[]>;
}

export const AppContext = createContext({} as AppContextInterFace);

const AppProvider = ({ children }: PropsWithChildren) => {
  const configState: Config = {
    isNavbarVerticalCollapsed: getItemFromStore(
      'isNavbarVerticalCollapsed',
      initialConfig.isNavbarVerticalCollapsed
    ),
    openNavbarVertical: initialConfig.openNavbarVertical,
    theme: getItemFromStore('theme', initialConfig.theme),
    navbarTopAppearance: getItemFromStore(
      'navbarTopAppearance',
      initialConfig.navbarTopAppearance
    ),
    navbarVerticalAppearance: getItemFromStore(
      'navbarVerticalAppearance',
      initialConfig.navbarVerticalAppearance
    ),
    navbarPosition: getItemFromStore(
      'navbarPosition',
      initialConfig.navbarPosition
    ),
    navbarTopShape: getItemFromStore(
      'navbarTopShape',
      initialConfig.navbarTopShape
    ),
    isRTL: getItemFromStore('isRTL', initialConfig.isRTL),
    isChatWidgetVisible: getItemFromStore(
      'isChatWidgetVisible',
      initialConfig.isChatWidgetVisible
    )
  };

  const auth = firebaseAuth;
  const [config, configDispatch] = useReducer(configReducer, configState);
  const [user, setUser] = useState<User | null>(getSecureStorage('token'));
  const [userInfo, setUserInfo] = useState<IUserInfo>();
  const [teamList, setTeamList] = useState<ITeamInfo[]>([]);
  const [rankList, setRankList] = useState<TRankInfo[]>([]);
  const [userList, setUserList] = useState<IUserInfo[]>([]);
  const [categoryList, setCategoryList] = useState<ICategoryInfo[]>([]);
  const [admin, setAdmin] = useState(false);

  const updateUserInfo = async () => {
    if (user && user.emailVerified) {
      const userInfoRet = await getUserInfo(user.uid);

      setAdmin(userInfoRet.auth > 1 ? true : false);

      // if (user.email) {
      //   userInfoRet.email = user.email;
      // }
      if (userInfoRet.companyUuid) {
        const teamInfoRet = await getTeams(userInfoRet.companyUuid);
        setTeamList(teamInfoRet);

        const rankInfoRet = await getRank(userInfoRet.companyUuid);
        setRankList(rankInfoRet);

        const userList = await getUsers(userInfoRet.companyUuid);
        setUserList(userList);

        const category = await dbGetCategory(userInfoRet.companyUuid);
        setCategoryList(category);
      }

      setUserInfo(userInfoRet);
    }
  };

  const setConfig = (payload: Partial<Config>) => {
    configDispatch({
      type: SET_CONFIG,
      payload
    });
  };

  const toggleTheme = () => {
    configDispatch({
      type: SET_CONFIG,
      payload: {
        theme: config.theme === 'dark' ? 'light' : 'dark'
      }
    });
  };

  const getThemeColor = (name: string) => {
    return getColor(name);
  };

  useEffect(() => {
    if (config.navbarTopShape === 'slim') {
      document.body.classList.add('nav-slim');
    } else {
      document.body.classList.remove('nav-slim');
    }

    if (config.navbarPosition === 'combo') {
      document.documentElement.classList.add('navbar-combo');
    } else {
      document.documentElement.classList.remove('navbar-combo');
    }

    if (config.navbarPosition === 'dual') {
      setConfig({
        navbarTopShape: 'default'
      });
      document.documentElement.classList.add('dual-nav');
    } else {
      document.documentElement.classList.remove('dual-nav');
    }

    if (config.navbarPosition === 'horizontal') {
      document.documentElement.classList.add('navbar-horizontal');
    } else {
      document.documentElement.classList.remove('navbar-horizontal');
    }

    if (config.isNavbarVerticalCollapsed) {
      document.documentElement.classList.add('navbar-vertical-collapsed');
    } else {
      document.documentElement.classList.remove('navbar-vertical-collapsed');
    }
  }, [config]);

  useEffect(() => {
    onAuthStateChanged(auth, pUser => {
      setUser(pUser);
      if (pUser) {
        setSecureStorage('token', pUser.toJSON());
        //updateUserInfo();
      } else {
        removeSecureStorage('token');
      }
    });
  }, []);

  useEffect(() => {
    updateUserInfo();
  }, [user]);

  return (
    <AppContext.Provider
      value={{
        config,
        setConfig,
        toggleTheme,
        getThemeColor,
        configDispatch,
        user,
        auth,
        admin,
        userInfo,
        updateUserInfo,
        teamList,
        setTeamList,
        rankList,
        setRankList,
        userList,
        setUserList,
        categoryList,
        setCategoryList
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);

export default AppProvider;
