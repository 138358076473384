import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { ChangeEvent } from 'react';
import { getLocalStorage, setLocalStorage } from 'lib/useLocalStorage';
import { signIn } from 'lib/firebase';

const SignInForm = () => {
  const savedEmail: string = getLocalStorage('email');
  const navigate = useNavigate();

  let email: string = savedEmail ? savedEmail : '';
  let password: string = '';
  let remember: boolean = true;

  const handleSignIn = async () => {
    if (email.length <= 0) {
      alert('이메일 주소를 입력하세요');
      return;
    }

    if (password.length < 6) {
      alert('비밀번호는 최소 6자리를 입력하세요');
      return;
    }

    if (remember) {
      setLocalStorage('email', email);
    } else {
      setLocalStorage('email', null);
    }

    const signInResult = await signIn(email, password);
    switch (signInResult) {
      case 0:
        alert('사용자 정보 조회 실패');
        break;
      case 1:
        alert('이메일, 비밀번호를 다시 확인해주세요.');
        break;
      case 2:
        alert('이메일 인증이 되지 않았습니다.');
        break;
      case 3:
        navigate('/pages/auth/no-auth');
        break;
      case 10:
        navigate('/');
        break;
      default:
    }
  };

  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-1000">TOMORROW</h3>
        <p className="text-700">------------------------------</p>
      </div>
      {/* <AuthSocialButtons title="Sign in" />
      <div className="position-relative">
        <hr className="bg-200 mt-5 mb-4" />
        <div className="divider-content-center">or use email</div>
      </div> */}
      <Form.Group className="mb-3 text-start">
        <Form.Label htmlFor="email">아이디(이메일)</Form.Label>
        <div className="form-icon-container">
          <Form.Control
            id="email"
            type="email"
            className="form-icon-input"
            placeholder="name@example.com"
            // value={email}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              email = e.target.value;
            }}
            defaultValue={email}
          />
          <FontAwesomeIcon icon={faUser} className="text-900 fs-9 form-icon" />
        </div>
      </Form.Group>
      <Form.Group className="mb-3 text-start">
        <Form.Label htmlFor="password">비밀번호</Form.Label>
        <div className="form-icon-container">
          <Form.Control
            id="password"
            type="password"
            className="form-icon-input"
            placeholder="Password"
            onKeyDown={event => {
              if (event.key === 'Enter') {
                handleSignIn();
              }
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              password = e.target.value;
            }}
          />
          <FontAwesomeIcon icon={faKey} className="text-900 fs-9 form-icon" />
        </div>
      </Form.Group>
      <Row className="flex-between-center mb-7">
        <Col xs="auto">
          <Form.Check type="checkbox" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember-me"
              id="remember-me"
              defaultChecked
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                remember = e.target.checked;
              }}
            />
            <Form.Check.Label htmlFor="remember-me" className="mb-0">
              아이디 저장
            </Form.Check.Label>
          </Form.Check>
        </Col>
        <Col xs="auto">
          <Link
            to={`/pages/auth/forgot-password`}
            className="fs-9 fw-semi-bold"
          >
            비밀번호 찿기
          </Link>
        </Col>
      </Row>
      <Button variant="primary" className="w-100 mb-3" onClick={handleSignIn}>
        로그인
      </Button>
      <div className="text-center">
        <Link to={`/pages/auth/sign-up`} className="fs-9 fw-bold">
          회원 가입
        </Link>
      </div>
    </>
  );
};

export default SignInForm;
