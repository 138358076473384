import { getFileExtension } from 'helpers/utils';
import AttachmentPreview from 'components/common/AttachmentPreview';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusSquare,
  faTimes,
  faClose
} from '@fortawesome/free-solid-svg-icons';
import {
  Col,
  Row,
  Form,
  InputGroup,
  FormControl,
  Modal,
  Table
} from 'react-bootstrap';
import Button from 'components/base/Button';
import {
  ChangeEvent,
  useEffect,
  useState,
  Dispatch,
  SetStateAction
} from 'react';
import {
  ITeamInfo,
  IUserInfo,
  getUserList,
  getTeamUserInfo
} from 'lib/firebase';
import { IProcess, IProcDelivery, ITeamUserTable, IProcFile } from 'interfaces';
import { fileSizeToString } from 'lib/fbStorage';

const ProjectDelivery = ({
  procList,
  setProcList,
  teamList,
  userList,
  show,
  setShow,
  procIndex
}: {
  procList: IProcess[];
  setProcList: Dispatch<SetStateAction<IProcess[]>>;
  teamList: ITeamInfo[];
  userList: IUserInfo[];
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  procIndex: number;
}) => {
  // const { addProcList } = useProjectCreateContext();
  const [selectedRecipient, setSelectedRecipient] = useState(-1);
  const [recipientList, setRecipientList] = useState<IUserInfo[]>([]);
  const [recipientTable, setRecipientTable] = useState<ITeamUserTable[]>([]);
  const [attachments, setAttachments] = useState<IProcFile[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [inputs, setInputs] = useState({
    title: '',
    content: '',
    timeFrame: 0,
    recipientTeam: '',
    recipientTeamId: '',
    recipient: '',
    recipientId: ''
  });
  const [inputMode, setInputMode] = useState({
    index: -1,
    button: '추가'
  });

  const onAddRecipientTable = () => {
    if (!inputs.recipientTeamId) {
      alert('업무참조 부서를 선택하세요');
      return;
    }

    if (!inputs.recipientId) {
      alert('업무참조 직원을 선택하세요');
      return;
    }

    if (recipientTable.find(v => v.userId === inputs.recipientId)) {
      alert('이미 수령인에 포함되어 있습니다');
      return;
    }

    recipientTable.push({
      team: inputs.recipientTeam,
      teamId: inputs.recipientTeamId,
      user: inputs.recipient,
      userId: inputs.recipientId
    });
    setRecipientTable([...recipientTable]);
  };

  const hCloseModal = () => {
    setShow(false);
    setOpenModal(false);
  };

  const hAddProcess = () => {
    const deliveryData: IProcDelivery = {
      recipient: [],
      attachment: []
    };

    recipientTable.map(value => deliveryData.recipient.push(value.userId));
    attachments.map(value => deliveryData.attachment.push(value));

    if (inputMode.index < 0) {
      procList.push({
        id: '',
        class: 'delivery',
        title: inputs.title,
        content: inputs.content,
        timeFrame: inputs.timeFrame,
        delivery: deliveryData
      });
    } else {
      const proc = procList.at(inputMode.index);
      if (proc) {
        proc.title = inputs.title;
        proc.content = inputs.content;
        proc.timeFrame = inputs.timeFrame;
        proc.delivery = deliveryData;
      }
    }
    setProcList([...procList]);
    hCloseModal();
  };

  const openProcModify = () => {
    const proc = procList.at(procIndex);

    if (proc) {
      setInputMode({
        index: procIndex,
        button: '수정'
      });

      if (proc.class === 'delivery') {
        setInputs(old => {
          return {
            ...old,
            title: proc.title,
            content: proc.content,
            timeFrame: proc.timeFrame
          };
        });

        const table: ITeamUserTable[] = [];
        proc.delivery?.recipient.map(value => {
          const user = getTeamUserInfo(userList, teamList, value);
          table.push({
            team: user.team,
            teamId: user.teamId,
            user: user.user,
            userId: user.userId
          });
        });
        setRecipientTable(table);

        if (proc.delivery?.attachment) {
          setAttachments(proc.delivery.attachment);
        } else {
          setAttachments([]);
        }

        setSelectedRecipient(-1);
        setOpenModal(true);
      }
    }
  };

  const openProcAdd = () => {
    setInputMode({
      index: -1,
      button: '추가'
    });
    setInputs(old => {
      return {
        ...old,
        title: '',
        content: '',
        timeFrame: 0
      };
    });
    setAttachments([]);
    setRecipientTable([]);
    setSelectedRecipient(-1);
    setOpenModal(true);
  };

  useEffect(() => {
    const selUserList = getUserList(userList, '');
    setRecipientList(selUserList);
  }, [userList]);

  useEffect(() => {
    if (show) {
      if (procIndex < 0) {
        openProcAdd();
      } else {
        openProcModify();
      }
    }
  }, [show]);

  return (
    <div>
      <Modal
        size="lg"
        show={openModal}
        centered
        backdrop="static"
        onHide={hCloseModal}
        contentClassName="bg-100 p-6 border"
      >
        <Modal.Header className="border-0 p-0 mb-2">
          <h3 className="mb-0">전달</h3>
          <Button variant="phoenix-secondary" onClick={hCloseModal} size="sm">
            <FontAwesomeIcon icon={faTimes} className="text-danger" />
          </Button>
        </Modal.Header>
        <Modal.Body className="px-0 mb-6">
          <Row>
            <Row className="g-1">
              <Form.Label column sm={2}>
                업무명
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  id="title"
                  type="text"
                  placeholder="업무명"
                  value={inputs.title}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setInputs(old => {
                      return {
                        ...old,
                        title: e.target.value
                      };
                    });
                  }}
                />
              </Col>
            </Row>
            <Row className="g-1">
              <Form.Label column sm={2}>
                업무내용
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  as="textarea"
                  rows={6}
                  value={inputs.content}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setInputs(old => {
                      return {
                        ...old,
                        content: e.target.value
                      };
                    });
                  }}
                />
              </Col>
            </Row>
            <Row className="g-1">
              <Form.Label column sm={2}>
                소요일
              </Form.Label>
              <Col sm={3}>
                <InputGroup>
                  <FormControl
                    placeholder="소요일"
                    aria-label="소요일"
                    aria-describedby="basic-addon2"
                    value={inputs.timeFrame}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInputs(old => {
                        return {
                          ...old,
                          timeFrame: Number(e.target.value)
                        };
                      });
                    }}
                  />
                  <InputGroup.Text id="basic-addon2">일</InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>
            <Row className="g-1">
              <Form.Label column sm={2}>
                수령인
                {/* <Button className="px-2 py-0" onClick={onAddRecipientTable}>
                  <FontAwesomeIcon icon={faPlusSquare} />
                </Button> */}
              </Form.Label>
              <Col sm={3}>
                <Form.Select
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    if (e.target.value) {
                      const team = teamList.at(Number(e.target.value));
                      inputs.recipientTeam = team ? team.name : '';
                      inputs.recipientTeamId = team ? team.code : '';
                    } else {
                      inputs.recipientTeam = '';
                      inputs.recipientTeamId = '';
                    }
                    const selUserList = getUserList(
                      userList,
                      inputs.recipientTeamId
                    );
                    setRecipientList(selUserList);
                    setSelectedRecipient(-1);
                  }}
                >
                  <option value={''}>선택</option>
                  {teamList.map((team, index) => (
                    <option key={index} value={index}>
                      {team.name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col sm={3}>
                <Form.Select
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    const user = recipientList.at(Number(e.target.value));
                    if (user) {
                      inputs.recipientId = user.uid;
                      inputs.recipient = user.userName;
                    }
                    setSelectedRecipient(Number(e.target.value));
                  }}
                  value={selectedRecipient}
                >
                  <option>선택</option>
                  {recipientList.map((user, index) => (
                    <option key={index} value={index}>
                      {user.userName}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col sm={2}>
                <Button
                  variant="phoenix-secondary"
                  onClick={onAddRecipientTable}
                >
                  추가
                </Button>
              </Col>
            </Row>
            <Row className="g-1">
              <Col sm={2}></Col>
              <Col sm={7}>
                <Table hover size="sm">
                  <thead>
                    <tr>
                      <th className="bg-white text-center fs-9">부서</th>
                      <th className="bg-white text-center fs-9">참조</th>
                      <td className="border border-100 fs-9" width="14%"></td>
                    </tr>
                  </thead>
                  <tbody>
                    {recipientTable.map((value, index) => (
                      <tr key={index}>
                        <td className="text-center align-middle fs-9">
                          {value.team}
                        </td>
                        <td className="text-center align-middle fs-9">
                          {value.user}
                        </td>
                        <td width="14%" className="border border-100 fs-9">
                          <Button
                            variant="phoenix-secondary"
                            className="btn-icon fs-9"
                            onClick={() => {
                              if (recipientTable.length > 1) {
                                recipientTable.splice(index, 1);
                                setRecipientTable([...recipientTable]);
                              }
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faClose}
                              className="text-danger"
                            />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row className="g-1">
              <Form.Label column sm={2}>
                배포자료
                <Button className="px-2">
                  <label
                    className="text-900 fs-9 cursor-pointer"
                    htmlFor="attachments"
                  >
                    <FontAwesomeIcon icon={faPlusSquare} />
                  </label>
                </Button>
                <Form.Control
                  className="d-none"
                  type="file"
                  id="attachments"
                  multiple
                  onChange={({
                    target: { files }
                  }: ChangeEvent<HTMLInputElement>) => {
                    if (files) {
                      for (let i = 0; i < files.length; i++) {
                        attachments.push({
                          name: files[i].name,
                          url: URL.createObjectURL(files[i]),
                          size: files[i].size,
                          projectId: ''
                        });
                      }
                      setAttachments([...attachments]);
                    }
                  }}
                />
              </Form.Label>
              <Col sm={9}>
                {attachments && (
                  <div className={classNames({ 'mb-2': attachments })}>
                    {attachments.map((value, index) => (
                      <AttachmentPreview
                        key={index}
                        attachment={{
                          name: value.name,
                          size: fileSizeToString(value.size),
                          format: getFileExtension(value.name)
                        }}
                        size="lg"
                        handleRemove={() =>
                          setAttachments(
                            attachments.filter((_, i) => index !== i)
                          )
                        }
                      />
                    ))}
                  </div>
                )}
              </Col>
            </Row>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border-0 p-0">
          <Button
            variant="link"
            className="text-danger px-3 my-0"
            onClick={hCloseModal}
          >
            취소
          </Button>
          <Button variant="primary" className="my-0" onClick={hAddProcess}>
            {inputMode.button}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProjectDelivery;
