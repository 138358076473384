import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  Col,
  Row,
  Form,
  InputGroup,
  FormControl,
  Modal
} from 'react-bootstrap';
import Button from 'components/base/Button';
import {
  ChangeEvent,
  useEffect,
  useState,
  useRef,
  Dispatch,
  SetStateAction
} from 'react';
import {
  ITeamInfo,
  IUserInfo,
  getUserList,
  getTeamUserInfo
} from 'lib/firebase';
import { ITeamUserTable } from 'interfaces';

const SelectionTeamUser = ({
  teamList,
  userList,
  show,
  setShow,
  setTeamUserInfo
}: {
  teamList: ITeamInfo[];
  userList: IUserInfo[];
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  setTeamUserInfo: Dispatch<SetStateAction<ITeamUserTable>>;
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [userCombo, setUserCombo] = useState<IUserInfo[]>([]);
  const selectedUser = useRef<ITeamUserTable>({
    team: '',
    teamId: '',
    user: '',
    userId: ''
  });

  const hCloseModal = () => {
    setShow(false);
    setOpenModal(false);
  };

  const hSelect = () => {
    setTeamUserInfo(selectedUser.current);
    setShow(false);
    setOpenModal(false);
  };

  useEffect(() => {
    if (show) {
      selectedUser.current.team = '';
      selectedUser.current.teamId = '';
      selectedUser.current.user = '';
      selectedUser.current.userId = '';
      setOpenModal(true);
    }
  }, [show]);

  return (
    <div>
      <Modal
        // size="sm"
        show={openModal}
        centered
        backdrop="static"
        onHide={hCloseModal}
        contentClassName="bg-100 p-6 border"
      >
        <Modal.Header className="border-0 p-0 mb-2">
          <h3 className="mb-0">부서 / 직원 선택</h3>
          <Button variant="phoenix-secondary" onClick={hCloseModal} size="sm">
            <FontAwesomeIcon icon={faTimes} className="text-danger" />
          </Button>
        </Modal.Header>
        <Modal.Body className="px-0 mb-6">
          <Row>
            <Row className="g-1">
              <Form.Label column sm={2}>
                부서
              </Form.Label>
              <Col sm={6}>
                <Form.Select
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    if (e.target.value) {
                      const team = teamList.at(Number(e.target.value));
                      selectedUser.current.team = team ? team.name : '';
                      selectedUser.current.teamId = team ? team.code : '';
                    } else {
                      selectedUser.current.team = '';
                      selectedUser.current.teamId = '';
                    }
                    const selUserList = getUserList(
                      userList,
                      selectedUser.current.teamId
                    );
                    setUserCombo(selUserList);
                  }}
                >
                  <option value={''}>선택</option>
                  {teamList.map((team, index) => (
                    <option key={index} value={index}>
                      {team.name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
            <Row className="g-1">
              <Form.Label column sm={2}>
                직원
              </Form.Label>
              <Col sm={6}>
                <Form.Select
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    const user = userCombo.at(Number(e.target.value));
                    if (user) {
                      selectedUser.current.user = user.userName;
                      selectedUser.current.userId = user.uid;
                    }
                  }}
                >
                  <option>선택</option>
                  {userCombo.map((user, index) => (
                    <option key={index} value={index}>
                      {user.userName}
                    </option>
                  ))}
                </Form.Select>
              </Col>
            </Row>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border-0 p-0">
          <Button
            variant="link"
            className="text-danger px-3 my-0"
            onClick={hCloseModal}
          >
            취소
          </Button>
          <Button variant="primary" className="my-0" onClick={hSelect}>
            선택
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SelectionTeamUser;
