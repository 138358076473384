import Tree from 'rc-tree';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'react-bootstrap';
import Button from 'components/base/Button';
import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { ICategory, ICategoryInfo, getCategoryInfo } from 'lib/firebase';
import { Key } from 'rc-tree/lib/interface';

const STYLE = `
.rc-tree-child-tree {
  display: block;
}

.node-motion {
  transition: all .3s;
  overflow-y: hidden;
}
`;

const motion = {
  motionName: 'node-motion',
  motionAppear: false,
  onAppearStart: (node: HTMLDivElement) => {
    // console.log('Start Motion:', node);
    return { height: 0 };
  },
  onAppearActive: (node: HTMLDivElement) => ({ height: node.scrollHeight }),
  onLeaveStart: (node: HTMLDivElement) => ({ height: node.offsetHeight }),
  onLeaveActive: () => ({ height: 0 })
};

const SelectionCategory = ({
  show,
  setShow,
  categoryList,
  setCategory
}: {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  categoryList: ICategoryInfo[];
  setCategory: Dispatch<SetStateAction<ICategory>>;
}) => {
  const [openModal, setOpenModal] = useState(false);
  // const [categoryInfo, setCategoryInfo] = useState<ICategoryInfo[]>([]);
  const [selectedKey, setSelectedKey] = useState('');

  // const findObject = (
  //   data: ICategoryInfo[],
  //   key: Key
  // ): ICategoryInfo | void => {
  //   let retItem;
  //   data.some((item: ICategoryInfo) => {
  //     if (item.key === key) {
  //       // console.log('find item', item);
  //       retItem = item;
  //       return true;
  //     } else if (item.children) {
  //       retItem = findObject(item.children, key);
  //       if (retItem) {
  //         return true;
  //       }
  //     }
  //   });
  //   return retItem;
  // };

  const hCloseModal = () => {
    setShow(false);
    setOpenModal(false);
  };

  const hSelect = () => {
    const node = getCategoryInfo(categoryList, selectedKey);
    if (node) {
      const category: ICategory = {
        root: node.root,
        key: node.key,
        title: node.title
      };
      setCategory(category);
    } else {
      alert('업무 카테고리를 선택하세요');
      return;
    }

    setShow(false);
    setOpenModal(false);
  };

  const onSelect = (selectedKeys: Key[]) => {
    if (selectedKeys[0]) {
      setSelectedKey(selectedKeys[0].toString());
    }
  };

  useEffect(() => {
    // if (show && userInfo) {
    //   dbGetCategory(userInfo.companyUuid).then(data => {
    //     setCategoryInfo(data);
    //     setOpenModal(true);
    //   });
    // }
    if (show) {
      setOpenModal(true);
    }
  }, [show]);

  return (
    <div>
      <Modal
        // size="sm"
        show={openModal}
        centered
        backdrop="static"
        onHide={hCloseModal}
        contentClassName="bg-100 p-6 border"
      >
        <Modal.Header className="border-0 p-0 mb-2">
          <h3 className="mb-0">업무 카테고리 선택</h3>
          <Button variant="phoenix-secondary" onClick={hCloseModal} size="sm">
            <FontAwesomeIcon icon={faTimes} className="text-danger" />
          </Button>
        </Modal.Header>
        <Modal.Body className="px-0 mb-6">
          <div className="mt-5 mb-5">
            <style dangerouslySetInnerHTML={{ __html: STYLE }} />
            <Tree
              defaultExpandAll={true}
              onSelect={onSelect}
              treeData={categoryList}
              motion={motion}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 p-0">
          <Button
            variant="link"
            className="text-danger px-3 my-0"
            onClick={hCloseModal}
          >
            취소
          </Button>
          <Button variant="primary" className="my-0" onClick={hSelect}>
            선택
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default SelectionCategory;
