import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import {
  ChangeEvent,
  useEffect,
  useState,
  createContext,
  useContext
} from 'react';
import { Col, Row, Form, Modal, Table } from 'react-bootstrap';
import {
  getUserInfo,
  getSiteUsers,
  setUserInfo,
  IUserInfo,
  ICompanyList,
  getCompanyList,
  addCompany
} from 'lib/firebase';
import { useAppContext } from 'providers/AppProvider';
import PhoenixLoader from 'components/common/PhoenixLoader';
import PhoenixDocCard from 'components/base/PhoenixDocCard';
import Button from 'components/base/Button';
import { faTimes, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SingleMsg from 'pages/pages/SingleMsg';
import AdvanceTable from 'components/base/AdvanceTable';
import { ColumnDef } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import Avatar from 'components/base/Avatar';
import FeatherIcon from 'feather-icons-react';

const authList: IAuthInfo[] = [
  { code: 0, name: '승인 대기' },
  { code: 1, name: '사용자' },
  { code: 2, name: '관리자' },
  { code: 9, name: '사이트 관리자' }
];

interface UserManagerContextInterFace {
  openEditModal: boolean;
  // setOpenEditModal: Dispatch<SetStateAction<boolean>>;
  openEditModel: (index: number) => void;
}

export const UserManagerContext = createContext(
  {} as UserManagerContextInterFace
);

const userManagerTablecolumns: ColumnDef<IUserInfo>[] = [
  {
    accessorKey: 'name',
    header: '이름',
    cell: ({ row: { original } }) => {
      const { userName, userImageUrl } = original;
      return (
        <Link
          to="#!"
          className=" d-flex align-items-center text-900 text-hover-1000"
        >
          <Avatar src={userImageUrl} size="m" />
          <p className="mb-0 ms-3 text-1100 fw-semi-bold">{userName}</p>
        </Link>
      );
    },
    meta: {
      headerProps: {
        // style: { minWidth: '100px' },
        style: { width: '20%' },
        className: 'px-1'
      },
      cellProps: { className: 'align-middle white-space-nowrap py-2' }
    }
  },
  {
    accessorKey: 'email',
    header: '이메일',
    cell: ({ row: { original } }) => {
      const { email } = original;
      return (
        <Link to={`mailto:${email}`} className="fw-semi-bold">
          {email}
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '20%' }
      },
      cellProps: { className: 'white-space-nowrap' }
    }
  },
  {
    accessorKey: 'mobile',
    header: '휴대폰 번호',
    cell: ({ row: { original } }) => {
      const { mobilePhone } = original;
      return (
        <Link to={`tel:${mobilePhone}`} className="fw-bold text-1100">
          {mobilePhone}
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: '20%' },
        className: 'pe-3'
      }
    }
  },
  {
    accessorKey: 'companyName',
    header: '회사',
    meta: {
      headerProps: { style: { width: '10%' } },
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'auth',
    header: '권한',
    cell: ({ row: { original } }) => {
      const { auth } = original;
      let data = String(auth);
      const authName = authList.find(v => v.code === auth);
      if (authName) {
        data = authName.name;
      }
      return <div className="text-900">{data}</div>;
    },
    meta: {
      headerProps: { style: { width: '10%' } },
      cellProps: { className: 'text-900' }
    }
  },
  {
    id: 'action',
    cell: ({ row }) => {
      const { openEditModel } = useUserManagerContext();
      return (
        <Button variant="link" onClick={() => openEditModel(row.index)}>
          <FeatherIcon icon="edit" size={16} />
        </Button>
      );
    },
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end' },
      cellProps: { className: 'text-end' }
    }
  }
];

const UserManagerTable = () => {
  return (
    <div>
      <AdvanceTable
        tableProps={{ className: 'phoenix-table fs-9' }}
        rowClassName="position-static hover-bg-100"
      />
      <AdvanceTableFooter pagination />
    </div>
  );
};

interface IAuthInfo {
  code: number;
  name: string;
}

const UserManager = () => {
  const { userInfo, userList, setUserList } = useAppContext();
  const [loading, setLoading] = useState(true);
  const [siteManager, setSiteManager] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [inputs, setInputs] = useState({
    uid: '',
    name: '',
    mobilePhone: '',
    companyCode: '',
    auth: ''
  });
  const [editCompany, setEditCompany] = useState('');
  const [companyList, setCompanyList] = useState<ICompanyList[]>([]);

  const table = useAdvanceTable({
    data: userList ? userList : [],
    columns: userManagerTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const handleEditModalClose = () => {
    setOpenEditModal(false);
  };

  const openEditModel = (index: number) => {
    setEditIndex(index);
    if (userList) {
      const authName = authList.find(v => v.code === userList[index].auth)
        ?.name;

      setInputs({
        ...inputs,
        uid: userList[index].uid,
        name: userList[index].userName,
        mobilePhone: userList[index].mobilePhone,
        companyCode: userList[index].companyUuid,
        auth: authName ? authName : ''
      });
      setOpenEditModal(true);
    }
  };

  const handleSaveEditUser = async () => {
    setOpenEditModal(false);

    const editUserInfo = await getUserInfo(inputs.uid);

    if (editUserInfo.companyUuid) {
      const authCode = authList.find(v => v.name === inputs.auth)?.code;

      if (9 === authCode) {
        alert('사이트 관리자는 수정 할 수 없습니다');
      } else {
        editUserInfo.userName = inputs.name;
        editUserInfo.mobilePhone = inputs.mobilePhone;
        editUserInfo.companyUuid = inputs.companyCode;
        editUserInfo.auth = authCode ? authCode : 0;

        setUserInfo(editUserInfo);

        if (userList && editIndex >= 0) {
          userList[editIndex] = editUserInfo;
          setUserList([...userList]);
        }
      }
    } else {
      alert('선택한 사용자의 정보를 조회할 수 없습니다');
    }
  };

  const handleAddConpanyCode = () => {
    if (editCompany) {
      addCompany(editCompany);
      getCompanyList().then(ret => {
        setCompanyList(ret);
      });
    } else {
      alert('추가 할 회사 이름을 입력하세요!');
    }
  };

  useEffect(() => {
    if (userInfo) {
      if (9 === userInfo.auth) {
        setSiteManager(true);
      }

      getCompanyList().then(ret => {
        setCompanyList(ret);
      });

      getSiteUsers().then(userList => {
        setUserList(userList);
        setLoading(false);
      });
    }
  }, [userInfo]);

  if (!siteManager) {
    return <SingleMsg msg="사이트 관리자만 사용 가능합니다." />;
  }

  if (loading) return <PhoenixLoader />;
  return (
    <UserManagerContext.Provider value={{ openEditModal, openEditModel }}>
      <div>
        <div className="mb-9">
          <h2 className="mb-5">사용자 관리</h2>
          <Row>
            <Col sm={3}>
              <PhoenixDocCard className="mb-4">
                <PhoenixDocCard.Header noPreview>
                  <div
                    style={{ height: '25px' }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <Form.Group>
                      {/* <Form.Label className="form-label-header mb-2">
                        회사 코드
                      </Form.Label> */}
                      <Form.Control
                        id="companyCode"
                        type="text"
                        placeholder="Enter company name"
                        value={editCompany}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          setEditCompany(e.target.value);
                        }}
                      />
                    </Form.Group>
                    {'\u00A0'}
                    <Button
                      variant="primary"
                      className="my-0"
                      onClick={handleAddConpanyCode}
                    >
                      추가
                    </Button>
                  </div>
                </PhoenixDocCard.Header>
                <PhoenixDocCard.Body>
                  <Table bordered hover size="sm">
                    <thead>
                      <tr>
                        <th className="bg-100 text-center fs-9">회사</th>
                        <th className="bg-100 text-center fs-9">코드</th>
                      </tr>
                    </thead>
                    <tbody>
                      {companyList.map((value, index) => (
                        <tr key={index}>
                          <td className="text-center align-middle fs-9">
                            {value.name}
                          </td>
                          <td className="text-center align-middle fs-9">
                            {value.code}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </PhoenixDocCard.Body>
              </PhoenixDocCard>
            </Col>
            <Col sm={9}>
              <PhoenixDocCard className="mb-4">
                <PhoenixDocCard.Body>
                  <AdvanceTableProvider {...table}>
                    <div className="mb-4">
                      <Row className="g-3">
                        <Col xs="auto">
                          <SearchBox
                            placeholder="Search member"
                            onChange={handleSearchInputChange}
                          />
                        </Col>
                        <Col
                          xs="auto"
                          className="scrollbar overflow-hidden-y flex-grow-1"
                        ></Col>
                        {/* <Col xs="auto">
                          <Button variant="link" className="text-900 me-4 px-0">
                            <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                            Export
                          </Button>
                          <Button variant="primary">
                            <FontAwesomeIcon icon={faPlus} className="me-2" />
                            Add member
                          </Button>
                        </Col> */}
                      </Row>
                    </div>

                    <div className="mx-n4 px-4 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
                      <UserManagerTable />
                    </div>
                  </AdvanceTableProvider>
                </PhoenixDocCard.Body>
              </PhoenixDocCard>
            </Col>
          </Row>
        </div>

        <Modal
          size="sm"
          show={openEditModal}
          centered
          backdrop="static"
          onHide={handleEditModalClose}
          contentClassName="bg-100 p-6 border"
        >
          <Modal.Header className="border-0 p-0 mb-2">
            <h3 className="mb-0">Member Edit</h3>
            <Button
              variant="phoenix-secondary"
              onClick={handleEditModalClose}
              size="sm"
            >
              <FontAwesomeIcon icon={faTimes} className="text-danger" />
            </Button>
          </Modal.Header>
          <Modal.Body className="px-0 mb-6">
            <Row className="g-4">
              <Col className="d-flex flex-column gap-4">
                <Form.Group>
                  <Form.Label className="form-label-header mb-2">
                    이름
                  </Form.Label>
                  <Form.Control
                    id="name"
                    type="text"
                    placeholder="Enter name"
                    value={inputs.name}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInputs({
                        ...inputs,
                        [e.target.id]: e.target.value
                      });
                    }}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label className="form-label-header mb-2">
                    휴대폰 번호
                  </Form.Label>
                  <Form.Control
                    id="mobilePhone"
                    type="text"
                    placeholder="Mobile Number"
                    value={inputs.mobilePhone}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInputs({
                        ...inputs,
                        [e.target.id]: e.target.value
                      });
                    }}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label className="form-label-header mb-2">
                    회사 코드
                  </Form.Label>
                  <Form.Control
                    id="companyCode"
                    type="text"
                    placeholder="Enter company code"
                    value={inputs.companyCode}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInputs({
                        ...inputs,
                        [e.target.id]: e.target.value
                      });
                    }}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label className="form-label-header mb-2">
                    권한
                  </Form.Label>
                  <Form.Select
                    id="auth"
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      setInputs({
                        ...inputs,
                        [e.target.id]: e.target.value
                      });
                    }}
                    defaultValue={inputs.auth}
                  >
                    <option>선택 안함</option>
                    {authList.map((v, i) => (
                      <option key={v.name}>{v.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="border-0 p-0">
            <Button
              variant="link"
              className="text-danger px-3 my-0"
              onClick={handleEditModalClose}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="my-0"
              onClick={handleSaveEditUser}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </UserManagerContext.Provider>
  );
};

export const useUserManagerContext = () => useContext(UserManagerContext);
export default UserManager;
