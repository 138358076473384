import {
  ChangeEvent,
  useEffect,
  useState,
  useRef,
  createContext,
  useContext
} from 'react';
import Button from 'components/base/Button';
import { Col, Row, Form } from 'react-bootstrap';
import { ICategoryInfo, dbSetCategory, dbGetCategory } from 'lib/firebase';
import { useAppContext } from 'providers/AppProvider';
import PhoenixLoader from 'components/common/PhoenixLoader';
import PhoenixDocCard from 'components/base/PhoenixDocCard';
import SingleMsg from 'pages/pages/SingleMsg';
import Tree from 'rc-tree';
import { Key } from 'rc-tree/lib/interface';
import 'rc-tree/assets/index.css';

const STYLE = `
.rc-tree-child-tree {
  display: block;
}

.node-motion {
  transition: all .3s;
  overflow-y: hidden;
}
`;

const motion = {
  motionName: 'node-motion',
  motionAppear: false,
  onAppearStart: (node: HTMLDivElement) => {
    // console.log('Start Motion:', node);
    return { height: 0 };
  },
  onAppearActive: (node: HTMLDivElement) => ({ height: node.scrollHeight }),
  onLeaveStart: (node: HTMLDivElement) => ({ height: node.offsetHeight }),
  onLeaveActive: () => ({ height: 0 })
};

interface CategoryManagerContextInterFace {
  editCategoryInfo: (code: string) => void;
  deleteCategoryInfo: (code: string) => void;
}

export const CategoryManagerContext = createContext(
  {} as CategoryManagerContextInterFace
);

const CategoryManager = ({ showEdit }: { showEdit: boolean }) => {
  const { userInfo, admin } = useAppContext();
  const [loading, setLoading] = useState(true);
  const [treeData, setTreeData] = useState<ICategoryInfo[]>([]);

  const [inputs, setInputs] = useState({
    code: '',
    name: ''
  });
  const selectedRef = useRef({
    key: '',
    root: ''
  });
  const [selectedTitle, setSelectedTitle] = useState('');

  const findObject = (
    data: ICategoryInfo[],
    key: Key
  ): ICategoryInfo | void => {
    let retItem;
    data.some((item: ICategoryInfo) => {
      if (item.key === key) {
        // console.log('find item', item);
        retItem = item;
        return true;
      } else if (item.children) {
        retItem = findObject(item.children, key);
        if (retItem) {
          return true;
        }
      }
    });
    return retItem;
  };

  const editCategoryInfo = () => {};
  const deleteCategoryInfo = () => {};

  const onClinkModify = () => {
    if (!inputs.code) {
      alert('카테고리 코드를 입력하세요');
      return;
    }

    if (!inputs.name) {
      alert('카테고리 이름을 입력하세요');
      return;
    }

    if (!selectedRef.current.key || !selectedRef.current.root) {
      alert('추가 할 상위카테고리를 선택하세요.');
      return;
    }

    if (findObject(treeData, inputs.code)) {
      alert('코드가 중복됩니다.');
      return;
    }

    const node = findObject(treeData, selectedRef.current.key);
    if (node) {
      // const data: ICategoryInfo = {
      //   root: selectedRef.current.root,
      //   key: inputs.code,
      //   title: inputs.name,
      //   children: []
      // };
      node.key = inputs.code;
      node.title = inputs.name;
      setTreeData([...treeData]);
    }
  };

  const removeObject = (
    data: ICategoryInfo[],
    key: Key,
    callback: (item: ICategoryInfo, index: number, arr: ICategoryInfo[]) => void
  ) => {
    data.forEach((item: ICategoryInfo, index: number, arr: ICategoryInfo[]) => {
      if (item.key === key) {
        callback(item, index, arr);
      }
      if (item.children) {
        removeObject(item.children, key, callback);
      }
    });
  };

  const onClickDelete = () => {
    const node = findObject(treeData, selectedRef.current.key);
    if (node) {
      removeObject(treeData, selectedRef.current.key, (item, index, arr) => {
        arr.splice(index, 1);
      });
      // console.log('treeData', treeData);
      setTreeData([...treeData]);
    }
  };

  const onClickAddChild = () => {
    if (!inputs.code) {
      alert('카테고리 코드를 입력하세요.');
      return;
    }

    if (!inputs.name) {
      alert('카테고리 이름을 입력하세요.');
      return;
    }

    if (!selectedRef.current.key || !selectedRef.current.root) {
      alert('추가 할 상위카테고리를 선택하세요.');
      return;
    }

    if (findObject(treeData, inputs.code)) {
      alert('코드가 중복됩니다.');
      return;
    }

    const node = findObject(treeData, selectedRef.current.key);
    if (node) {
      const data: ICategoryInfo = {
        root: selectedRef.current.root,
        key: inputs.code,
        title: inputs.name,
        children: []
      };

      if (!node.children) {
        node.children = [];
      }
      node.children.push(data);
      // console.log('onClickAddChild, node', node);
      setTreeData([...treeData]);
    }
  };

  const onSelect = (selectedKeys: Key[]) => {
    const node = findObject(treeData, selectedKeys[0]);
    if (node) {
      selectedRef.current.key = node.key;
      selectedRef.current.root = node.root;
      setSelectedTitle(node.title + ' ( code : ' + node.key + ' )');
    }
  };

  const onApply = () => {
    if (userInfo && userInfo.companyUuid) {
      dbSetCategory(userInfo.companyUuid, treeData);
      alert('적용 되었습니다.');
    }
  };

  useEffect(() => {
    if (userInfo && userInfo.companyUuid) {
      dbGetCategory(userInfo.companyUuid).then(retData => {
        setTreeData(retData);
        setLoading(false);
      });
    }
  }, [userInfo]);

  // if (!admin) {
  //   return <SingleMsg msg="관리자만 사용 가능합니다." />;
  // }

  if (loading) return <PhoenixLoader />;
  return (
    <CategoryManagerContext.Provider
      value={{ editCategoryInfo, deleteCategoryInfo }}
    >
      <div>
        <div className="mb-9">
          <h2 className="mb-5">
            {showEdit ? '업무 카테고리 관리' : '업무 카테고리'}
          </h2>
          <Row>
            <Col sm={5}>
              <PhoenixDocCard className="mt-1 mb-1">
                <PhoenixDocCard.Header title={''} noPreview>
                  <div
                    style={{ height: '20px' }}
                    className="d-flex justify-content-start align-items-center"
                  >
                    {showEdit && (
                      <Button
                        variant="primary"
                        // className="w-100"
                        style={{ width: '120px' }}
                        size="sm"
                        onClick={() => {
                          onApply();
                        }}
                      >
                        {'  적용  '}
                      </Button>
                    )}
                  </div>
                </PhoenixDocCard.Header>
                <PhoenixDocCard.Body>
                  <div className="mt-5 mb-5">
                    <style dangerouslySetInnerHTML={{ __html: STYLE }} />
                    <Tree
                      defaultExpandAll={true}
                      onSelect={onSelect}
                      treeData={treeData}
                      motion={motion}
                    />
                  </div>
                </PhoenixDocCard.Body>
              </PhoenixDocCard>
            </Col>
            {showEdit && (
              <Col sm={4}>
                <PhoenixDocCard className="mt-1 mb-1">
                  <PhoenixDocCard.Header
                    title={'선택 : ' + selectedTitle}
                    noPreview
                  />
                  <PhoenixDocCard.Body>
                    <div>
                      <Form>
                        <Row className="mb-2">
                          <Form.Group className="mb-3 text-start">
                            <Form.Label htmlFor="name">
                              카테고리 코드
                            </Form.Label>
                            <Form.Control
                              id="code"
                              type="text"
                              placeholder="카테고리 코드"
                              value={inputs.code}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setInputs({
                                  ...inputs,
                                  [e.target.id]: e.target.value
                                });
                              }}
                            />
                          </Form.Group>
                          <Form.Group className="mb-3 text-start">
                            <Form.Label htmlFor="name">
                              카테고리 이름
                            </Form.Label>
                            <Form.Control
                              id="name"
                              type="text"
                              placeholder="카테고리 이름"
                              value={inputs.name}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setInputs({
                                  ...inputs,
                                  [e.target.id]: e.target.value
                                });
                              }}
                            />
                          </Form.Group>
                        </Row>

                        <Row className="mt-5 mb-5">
                          {/* <Col>
                            <Button
                              variant="primary"
                              // type="submit"
                              className="w-100 mb-3"
                              onClick={onClinkModify}
                            >
                              수정
                            </Button>
                          </Col> */}
                          <Col>
                            <Button
                              variant="primary"
                              className="w-100"
                              onClick={onClickDelete}
                            >
                              삭제
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              variant="primary"
                              className="w-100"
                              onClick={() => {
                                onClickAddChild();
                              }}
                            >
                              하위 카테고리 추가
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </PhoenixDocCard.Body>
                </PhoenixDocCard>
              </Col>
            )}
          </Row>
        </div>
      </div>
    </CategoryManagerContext.Provider>
  );
};

export const useCategoryManagerContext = () =>
  useContext(CategoryManagerContext);
export default CategoryManager;
