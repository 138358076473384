import {
  faAngleRight,
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import classNames from 'classnames';
import FeatherIcon from 'feather-icons-react';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
// import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { Link } from 'react-router-dom';
import { useEffect, useState, ChangeEvent, KeyboardEvent } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAppContext } from 'providers/AppProvider';
import {
  dbGetProject,
  getTeamName,
  getUserName,
  CATEGORY_STANDARD,
  getCategoryInfo
} from 'lib/firebase';
import { TYPE_PERIOD } from 'interfaces';
import dayjs from 'dayjs';
import SearchBox from 'components/common/SearchBox';

interface Project {
  id: number;
  key: string;
  rootCategory: string;
  category: string;
  name: string;
  presideTeam: string;
  president: string;
  startAt: string;
  period: string;
}

const columns: ColumnDef<Project>[] = [
  {
    accessorKey: 'name',
    header: '업무',
    cell: ({ row: { original } }) => {
      const { name, key, rootCategory } = original;
      return (
        <Link
          to={'/project-detail/' + key + '?category=' + rootCategory}
          className="text-decoration-none fw-bold fs-8"
        >
          {name}
        </Link>
      );
    },
    meta: {
      cellProps: { className: 'white-space-nowrap' },
      headerProps: { style: { width: '30%' } }
    }
  },
  {
    header: '카테고리',
    accessorKey: 'category',
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-900 white-space-nowrap' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    }
  },
  {
    header: '주관 부서',
    accessorKey: 'presideTeam',
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-900 white-space-nowrap' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    }
  },
  {
    header: '주관인',
    accessorKey: 'president',
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-900 white-space-nowrap' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    }
  },
  {
    accessorKey: 'startAt',
    header: '시작일',
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-900 white-space-nowrap' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    }
  },
  {
    header: '업무주기',
    accessorKey: 'period',
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-900 white-space-nowrap' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    }
  },
  {
    id: 'action',
    cell: ({ row: { original } }) => {
      const { key, rootCategory } = original;
      return (
        <Link to={'/project-create/' + key + '?category=' + rootCategory}>
          <FeatherIcon icon="edit" size={16} />
        </Link>
      );
    },
    meta: {
      headerProps: { style: { width: '1%' }, className: 'text-end' },
      cellProps: { className: 'text-end' }
    }
  }
];

const StandardProjectList = () => {
  const { userInfo, teamList, userList, categoryList } = useAppContext();
  const [projectList, setProjectList] = useState<Project[]>([]);
  const [firstDataKey, setFirstDataKey] = useState<string>();
  const [lastDataKey, setLastDataKey] = useState<string>();
  const table = useAdvanceTable({
    data: projectList,
    columns
    // pageSize: 6,
    // pagination: false,
    // sortable: false
  });
  const [pageNo, setPageNo] = useState(0);
  const [isNext, setIsNext] = useState(true);
  const pageCount = 10;
  const dataCount = pageCount + 1;

  const updateProjectList = (first: boolean, next: boolean) => {
    if (userInfo) {
      const projects: Project[] = [];
      let lastKey = next ? lastDataKey : firstDataKey;

      if (first) {
        lastKey = '';
      }

      dbGetProject(userInfo, CATEGORY_STANDARD, dataCount, lastKey, next).then(
        retData => {
          let id: number = 1;
          retData.forEach((n, i) => {
            const period = TYPE_PERIOD.at(n.period);

            setIsNext(retData.length >= dataCount ? true : false);

            if (i < retData.length - 1 || retData.length < dataCount) {
              const categoryInfo = getCategoryInfo(categoryList, n.category);
              let categoryName = n.category;
              if (categoryInfo) {
                categoryName = categoryInfo.title;
              }

              projects.push({
                id: id++,
                key: n.key ? n.key : '',
                rootCategory: n.rootCategory,
                category: categoryName,
                name: n.title,
                presideTeam: getTeamName(teamList, n.presideTeam),
                president: getUserName(userList, n.president),
                startAt: n.createAt
                  ? dayjs(n.createAt).format('YYYY-MM-DD')
                  : '-',
                period: period ? period : ''
              });
            }

            if (i === 0) {
              setFirstDataKey(n.key);
            }
            if (i === dataCount - 1) {
              setLastDataKey(n.key);
            }
          });
          setProjectList(projects);
          // table.setPageSize(table.getPrePaginationRowModel().rows.length);
          table.setPageSize(projects.length);
        }
      );
    }
  };

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  useEffect(() => {
    updateProjectList(true, false);
  }, [userInfo]);

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <Row className="align-items-center justify-content-between g-3 mb-6">
          <Col md="auto">
            <h2 className="mb-0">
              <span className="me-3">표준 업무</span>{' '}
              {/* <span className="fw-normal text-700">({projectList.length})</span> */}
            </h2>
          </Col>
          <Col md="auto">
            <SearchBox
              placeholder="Search"
              onChange={handleSearchInputChange}
              onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                if (e.code === 'Enter') {
                  e.preventDefault();
                }
              }}
            />
          </Col>
        </Row>
        <AdvanceTable
          tableProps={{ className: 'phoenix-table border-top border-200 fs-9' }}
          rowClassName="hover-bg-100"
        />
        {/* <AdvanceTableFooter pagination /> */}
        <Row className={'align-items-center py-1'}>
          <Col></Col>
          <Col xs="auto" className="d-flex gap-2">
            <Button
              variant="link"
              startIcon={
                <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
              }
              className={classNames('px-1', {
                disabled: pageNo === 0
              })}
              onClick={() => {
                let page = pageNo - 1;
                if (page < 0) {
                  page = 0;
                }
                setPageNo(page);
                updateProjectList(false, false);
              }}
            >
              이전
            </Button>
            <div className={'py-2'}>{' ' + (pageNo + 1) + ' '}</div>
            <Button
              variant="link"
              endIcon={
                <FontAwesomeIcon icon={faChevronRight} className="ms-2" />
              }
              className={classNames('px-1', {
                disabled: !isNext
              })}
              onClick={() => {
                let page = pageNo + 1;
                if (page > pageCount) {
                  page = pageCount;
                }
                setPageNo(page);
                updateProjectList(false, true);
              }}
            >
              다음
            </Button>
          </Col>
        </Row>
      </AdvanceTableProvider>
    </div>
  );
};

export default StandardProjectList;
