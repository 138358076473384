import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState, KeyboardEvent } from 'react';
import { Col, Row } from 'react-bootstrap';
import MembersTable, {
  membersTablecolumns
} from 'components/tables/MembersTable';
import { getUsers } from 'lib/firebase';
import { useAppContext } from 'providers/AppProvider';
import PhoenixLoader from 'components/common/PhoenixLoader';

const Members = () => {
  const { userInfo, userList, setUserList } = useAppContext();
  const [loading, setLoading] = useState(true);
  // const [userList, setUserList] = useState<IUserInfo[]>();

  const table = useAdvanceTable({
    data: userList ? userList : [],
    columns: membersTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  useEffect(() => {
    if (userInfo && userInfo.companyUuid) {
      getUsers(userInfo.companyUuid).then(userList => {
        setUserList(userList);
        setLoading(false);
      });
    }
  }, [userInfo]);

  if (loading) return <PhoenixLoader />;
  return (
    <div>
      <div className="mb-9">
        <AdvanceTableProvider {...table}>
          <Row className="align-items-center justify-content-between g-3 mb-6">
            <Col md="auto">
              <h2 className="mb-0">
                <span className="me-3">직원</span>{' '}
              </h2>
            </Col>
            <Col xs="auto">
              <SearchBox
                placeholder="Search members"
                onChange={handleSearchInputChange}
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                  if (e.code === 'Enter') {
                    e.preventDefault();
                  }
                }}
              />
            </Col>
            {/* <Col xs="auto">
              <Button variant="link" className="text-900 me-4 px-0">
                <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                Export
              </Button>
              <Button variant="primary">
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Add member
              </Button>
            </Col> */}
          </Row>

          <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
            <MembersTable />
          </div>
        </AdvanceTableProvider>
      </div>
    </div>
  );
};

export default Members;
