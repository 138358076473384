import {
  ChangeEvent,
  useEffect,
  useState,
  useRef,
  createContext,
  useContext
} from 'react';
import { Col, Row, Form, Table, Modal, Stack } from 'react-bootstrap';
import Button from 'components/base/Button';
import PhoenixDocCard from 'components/base/PhoenixDocCard';
import {
  dbAddBulletinBoard,
  dbBulletinBoardInfo,
  dbModifyBulletinBoard,
  dbRemoveBulletinBoard
} from 'lib/firebase';
import { useAppContext } from 'providers/AppProvider';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEdit, faTrash, faClose } from '@fortawesome/free-solid-svg-icons';
// import FeatherIcon from 'feather-icons-react';
import { IBulletinBoard } from 'interfaces';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PhoenixLoader from 'components/common/PhoenixLoader';

interface IMemberManagerContext {
  setTitle: (title: string) => void;
}

export const BulletinBoardCreateContext = createContext(
  {} as IMemberManagerContext
);

const BulletinBoardCreate = () => {
  const { key } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { userInfo } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [modifyMode, setModifyMode] = useState(false);
  const [data, setData] = useState<IBulletinBoard>();
  const inputsRef = useRef({
    title: '',
    text: ''
  });
  const [title, setTitle] = useState('');

  const onClickAdd = async () => {
    if (userInfo && userInfo.companyUuid) {
      const newData: IBulletinBoard = {
        key: '',
        userId: userInfo.uid,
        title: inputsRef.current.title,
        text: inputsRef.current.text,
        createAt: 0
      };

      setLoading(true);
      if (modifyMode) {
        if (data) {
          newData.key = data.key;
          newData.createAt = data.createAt;
          await dbModifyBulletinBoard(userInfo, newData);
        }
      } else {
        await dbAddBulletinBoard(userInfo, newData);
      }
      setLoading(false);
      workEnd();
    }
  };

  const onClickDelete = () => {
    if (window.confirm('삭제 하시겠습니까?')) {
      if (userInfo && data?.key) {
        dbRemoveBulletinBoard(userInfo, data.key);
        workEnd();
      }
    }
  };

  const workEnd = () => {
    navigate('/bulletinboard');
  };

  useEffect(() => {
    if (key && userInfo && userInfo.companyUuid) {
      if (key === 'new') {
        const newData: IBulletinBoard = {
          key: '',
          userId: userInfo.uid,
          title: '',
          text: '',
          createAt: 0
        };
        setData(newData);
        setModifyMode(false);
      } else {
        dbBulletinBoardInfo(userInfo, key).then(retData => {
          setModifyMode(true);
          setData(retData);
        });
      }
    }
  }, [userInfo, key]);

  useEffect(() => {
    if (data) {
      inputsRef.current.title = data.title;
      inputsRef.current.text = data.text;
    }
  }, [data]);

  return (
    <BulletinBoardCreateContext.Provider value={{ setTitle }}>
      <div>
        <div className="mb-9">
          <Row className="g-1 justify-content-between">
            <Col sm={5}>
              <h3 className="mb-5">
                {'게시판 글' + (modifyMode ? ' 수정' : '쓰기')}
              </h3>
            </Col>
          </Row>
          <Row>
            <Col sm={7}>
              <PhoenixDocCard className="mt-1 mb-1">
                <PhoenixDocCard.Header title={''} noPreview>
                  <Row>
                    <Col sm={8}>
                      <div
                        className="d-flex align-items-center gap-2"
                        style={{ height: '25px' }}
                      >
                        <Button
                          variant="primary"
                          className="w-30"
                          onClick={onClickAdd}
                        >
                          {modifyMode ? '수정' : '등록'}
                        </Button>
                        {modifyMode && (
                          <Button
                            variant="phoenix-danger"
                            className="w-30"
                            onClick={onClickDelete}
                          >
                            삭제
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                </PhoenixDocCard.Header>
                <PhoenixDocCard.Body>
                  <div
                    className="container-small"
                    style={{ minHeight: '600px' }}
                  >
                    <Row>
                      <Row className="g-1">
                        <Form.Label column sm={3}>
                          제목
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            id="title"
                            type="text"
                            placeholder="제목"
                            defaultValue={inputsRef.current.title}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              inputsRef.current.title = e.target.value;
                              setTitle(e.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="g-1">
                        <Form.Label column sm={3}>
                          본문
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            as="textarea"
                            rows={25}
                            defaultValue={inputsRef.current.text}
                            onChange={e => {
                              inputsRef.current.text = e.target.value;
                            }}
                          />
                        </Col>
                      </Row>
                    </Row>
                  </div>
                </PhoenixDocCard.Body>
              </PhoenixDocCard>
            </Col>
          </Row>
        </div>

        <Modal
          size="sm"
          show={loading}
          centered
          backdrop="static"
          onHide={() => setLoading(false)}
        >
          <Modal.Body>
            <PhoenixLoader />
          </Modal.Body>
        </Modal>
      </div>
    </BulletinBoardCreateContext.Provider>
  );
};

export const useBulletinBoardCreateContext = () =>
  useContext(BulletinBoardCreateContext);
export default BulletinBoardCreate;
