import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import classNames from 'classnames';
import FeatherIcon from 'feather-icons-react';
import { ColumnDef, ColumnFiltersState } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
// import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState, ChangeEvent, KeyboardEvent } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAppContext } from 'providers/AppProvider';
import { dbGetBulletinBoard, getUserName } from 'lib/firebase';
import { IBulletinBoard } from 'interfaces';
import dayjs from 'dayjs';
import SearchBox from 'components/common/SearchBox';

interface ListData extends IBulletinBoard {
  id: number;
  userName: string;
  date: string;
  edit: boolean;
}

const columns: ColumnDef<ListData>[] = [
  {
    accessorKey: 'title',
    header: '제목',
    meta: {
      cellProps: { className: 'white-space-nowrap' },
      headerProps: { style: { width: '30%' } }
    },
    cell: ({ row: { original } }) => {
      const { title, key } = original;
      return (
        <Link
          to={'/bulletinboard-detail/' + key}
          className="text-decoration-none fw-bold fs-8"
        >
          {title}
        </Link>
      );
    }
  },
  {
    header: '작성자',
    accessorKey: 'userName',
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-900 white-space-nowrap' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    }
  },
  {
    accessorKey: 'date',
    header: '작성일',
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-900 white-space-nowrap' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    },
    enableGlobalFilter: false
  },
  {
    id: 'action',
    cell: ({ row: { original } }) => {
      const { key, edit } = original;
      // const { admin } = useAppContext();
      return edit ? (
        <Link to={'/bulletinboard-create/' + key}>
          <FeatherIcon icon="edit" size={16} />
        </Link>
      ) : (
        <FeatherIcon icon="edit" size={16} />
      );
    },
    meta: {
      headerProps: { style: { width: '1%' }, className: 'text-end' },
      cellProps: { className: 'text-end' }
    }
  }
];

const BulletinBoard = () => {
  const navigate = useNavigate();
  const { userInfo, userList, admin } = useAppContext();
  const [listData, setListData] = useState<ListData[]>([]);
  const [firstDataKey, setFirstDataKey] = useState<string>();
  const [lastDataKey, setLastDataKey] = useState<string>();
  const table = useAdvanceTable({
    data: listData,
    columns
    // pageSize: 6,
    // pagination: false,
    // sortable: false
  });
  const [pageNo, setPageNo] = useState(0);
  const [isNext, setIsNext] = useState(false);
  const pageCount = 10;
  const dataCount = pageCount + 1;

  const updateProjectList = (first: boolean, next: boolean) => {
    if (userInfo) {
      const listDatas: ListData[] = [];
      let lastKey = next ? lastDataKey : firstDataKey;

      if (first) {
        lastKey = '';
      }

      dbGetBulletinBoard(userInfo, dataCount, lastKey, next).then(retData => {
        retData.forEach((n, i) => {
          setIsNext(retData.length >= dataCount ? true : false);

          if (i < retData.length - 1 || retData.length < dataCount) {
            let editAuth = false;
            if (admin || n.userId === userInfo.uid) {
              editAuth = true;
            }

            listDatas.push({
              id: 0,
              key: n.key,
              userId: n.userId,
              userName: getUserName(userList, n.userId),
              title: n.title,
              text: n.text,
              createAt: n.createAt,
              date: n.createAt
                ? dayjs(n.createAt).format('YYYY-MM-DD HH:mm:ss')
                : '-',
              edit: editAuth
            });
          }

          if (i === 0) {
            setFirstDataKey(n.key);
          }
          if (i === dataCount - 1) {
            setLastDataKey(n.key);
          }
        });
        setListData(listDatas);
        // table.setPageSize(table.getPrePaginationRowModel().rows.length);
        table.setPageSize(listDatas.length);
      });
    }
  };

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    // const filters: ColumnFiltersState = [
    //   {
    //     id: 'title',
    //     value: e.target.value
    //   },
    //   {
    //     id: 'userName',
    //     value: e.target.value
    //   }
    // ];
    // table.setColumnFilters(filters);
    table.setGlobalFilter(e.target.value || undefined);
  };

  useEffect(() => {
    updateProjectList(true, false);
  }, [userInfo]);

  return (
    <div>
      <AdvanceTableProvider {...table}>
        {/* <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center">
        </div> */}
        <Row className="align-items-center justify-content-between g-3 mb-6">
          <Col md="auto">
            <h2 className="mb-0">
              <span className="me-3">게시판</span>{' '}
              {/* <span className="fw-normal text-700">({projectList.length})</span> */}
            </h2>
          </Col>
          <Col>
            <Button
              variant="primary"
              className="w-10"
              onClick={() => {
                navigate('/bulletinboard-create/new');
              }}
            >
              글쓰기
            </Button>
          </Col>
          <Col md="auto">
            <SearchBox
              placeholder="Search"
              onChange={handleSearchInputChange}
              onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                if (e.code === 'Enter') {
                  e.preventDefault();
                }
              }}
            />
          </Col>
        </Row>
        <AdvanceTable
          tableProps={{ className: 'phoenix-table border-top border-200 fs-9' }}
          rowClassName="hover-bg-100"
        />
        {/* <AdvanceTableFooter pagination /> */}
        <Row className={'align-items-center py-1'}>
          <Col></Col>
          <Col xs="auto" className="d-flex gap-2">
            <Button
              variant="link"
              startIcon={
                <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
              }
              className={classNames('px-1', {
                disabled: pageNo === 0
              })}
              onClick={() => {
                let page = pageNo - 1;
                if (page < 0) {
                  page = 0;
                }
                setPageNo(page);
                updateProjectList(false, false);
              }}
            >
              이전
            </Button>
            <div className={'py-2'}>{' ' + (pageNo + 1) + ' '}</div>
            <Button
              variant="link"
              endIcon={
                <FontAwesomeIcon icon={faChevronRight} className="ms-2" />
              }
              className={classNames('px-1', {
                disabled: !isNext
              })}
              onClick={() => {
                let page = pageNo + 1;
                if (page > pageCount) {
                  page = pageCount;
                }
                setPageNo(page);
                updateProjectList(false, true);
              }}
            >
              다음
            </Button>
          </Col>
        </Row>
      </AdvanceTableProvider>
    </div>
  );
};

export default BulletinBoard;
