import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import {
  ChangeEvent,
  useEffect,
  useState,
  createContext,
  useContext,
  KeyboardEvent
} from 'react';
import { Col, Row, Form, Modal } from 'react-bootstrap';
import MemberManagerTable, {
  memberManagerTablecolumns
} from 'components/tables/MemberManagerTable';
import { getUserInfo, getUsers, setUserInfo } from 'lib/firebase';
import { useAppContext } from 'providers/AppProvider';
import PhoenixLoader from 'components/common/PhoenixLoader';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';
import PhoenixDocCard from 'components/base/PhoenixDocCard';
import Button from 'components/base/Button';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SingleMsg from 'pages/pages/SingleMsg';

interface MemberManagerContextInterFace {
  openEditModal: boolean;
  // setOpenEditModal: Dispatch<SetStateAction<boolean>>;
  openEditModel: (index: number) => void;
}

export const MemberManagerContext = createContext(
  {} as MemberManagerContextInterFace
);

const MemberManager = () => {
  const { userInfo, teamList, rankList, userList, setUserList, admin } =
    useAppContext();
  const [loading, setLoading] = useState(true);
  // const [userList, setUserList] = useState<IUserInfo[]>();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [inputs, setInputs] = useState({
    uid: '',
    name: '',
    mobilePhone: '',
    team: '',
    rank: ''
  });

  const table = useAdvanceTable({
    data: userList ? userList : [],
    columns: memberManagerTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const handleEditModalClose = () => {
    setOpenEditModal(false);
  };

  const openEditModel = (index: number) => {
    setEditIndex(index);
    if (userList) {
      const teamName = teamList.find(v => v.code === userList[index].teamCode)
        ?.name;
      const rankName = rankList.find(v => v.code === userList[index].rankCode)
        ?.name;

      setInputs({
        ...inputs,
        uid: userList[index].uid,
        name: userList[index].userName,
        mobilePhone: userList[index].mobilePhone,
        team: teamName ? teamName : '',
        rank: rankName ? rankName : ''
      });
      setOpenEditModal(true);
    }
  };

  const handleSaveEditUser = async () => {
    setOpenEditModal(false);

    const editUserInfo = await getUserInfo(inputs.uid);

    if (editUserInfo.companyUuid) {
      const teamCode = teamList.find(v => v.name === inputs.team)?.code;
      const rankCode = rankList.find(v => v.name === inputs.rank)?.code;

      editUserInfo.userName = inputs.name;
      editUserInfo.mobilePhone = inputs.mobilePhone;
      editUserInfo.teamCode = teamCode ? teamCode : '';
      editUserInfo.rankCode = rankCode ? rankCode : '';

      setUserInfo(editUserInfo);

      if (userList && editIndex >= 0) {
        userList[editIndex] = editUserInfo;
        setUserList([...userList]);
      }
    } else {
      alert('선택한 사용자의 정보를 조회할 수 없습니다.');
    }
  };

  useEffect(() => {
    if (userInfo && userInfo.companyUuid) {
      getUsers(userInfo.companyUuid).then(userList => {
        setUserList(userList);
        setLoading(false);
      });
    }
  }, [userInfo]);

  if (!admin) {
    return <SingleMsg msg="관리자만 사용 가능합니다." />;
  }

  if (loading) return <PhoenixLoader />;
  return (
    <MemberManagerContext.Provider value={{ openEditModal, openEditModel }}>
      <div>
        <div className="mb-9">
          <h2 className="mb-5">직원 관리</h2>
          <Row>
            <PhoenixDocCard className="mb-4">
              <PhoenixDocCard.Body>
                <AdvanceTableProvider {...table}>
                  <div className="mb-4">
                    <Row className="g-3">
                      <Col xs="auto">
                        <SearchBox
                          placeholder="Search member"
                          onChange={handleSearchInputChange}
                          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                            if (e.code === 'Enter') {
                              e.preventDefault();
                            }
                          }}
                        />
                      </Col>
                      <Col
                        xs="auto"
                        className="scrollbar overflow-hidden-y flex-grow-1"
                      ></Col>
                      {/* <Col xs="auto">
                        <Button variant="link" className="text-900 me-4 px-0">
                          <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                          Export
                        </Button>
                        <Button variant="primary">
                          <FontAwesomeIcon icon={faPlus} className="me-2" />
                          Add member
                        </Button>
                      </Col> */}
                    </Row>
                  </div>

                  <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
                    <MemberManagerTable />
                  </div>
                </AdvanceTableProvider>
              </PhoenixDocCard.Body>
            </PhoenixDocCard>
          </Row>
        </div>

        <Modal
          size="sm"
          show={openEditModal}
          centered
          backdrop="static"
          onHide={handleEditModalClose}
          contentClassName="bg-100 p-6 border"
        >
          <Modal.Header className="border-0 p-0 mb-2">
            <h3 className="mb-0">Member Edit</h3>
            <Button
              variant="phoenix-secondary"
              onClick={handleEditModalClose}
              size="sm"
            >
              <FontAwesomeIcon icon={faTimes} className="text-danger" />
            </Button>
          </Modal.Header>
          <Modal.Body className="px-0 mb-6">
            <Row className="g-4">
              <Col className="d-flex flex-column gap-4">
                <Form.Group>
                  <Form.Label className="form-label-header mb-2">
                    이름
                  </Form.Label>
                  <Form.Control
                    id="name"
                    type="text"
                    placeholder="Enter name"
                    value={inputs.name}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInputs({
                        ...inputs,
                        [e.target.id]: e.target.value
                      });
                    }}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label className="form-label-header mb-2">
                    휴대폰 번호
                  </Form.Label>
                  <Form.Control
                    id="mobilePhone"
                    type="text"
                    placeholder="Mobile Number"
                    value={inputs.mobilePhone}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInputs({
                        ...inputs,
                        [e.target.id]: e.target.value
                      });
                    }}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label className="form-label-header mb-2">
                    부서
                  </Form.Label>
                  <Form.Select
                    id="team"
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      setInputs({
                        ...inputs,
                        [e.target.id]: e.target.value
                      });
                    }}
                    defaultValue={inputs.team}
                  >
                    <option>선택 안함</option>
                    {teamList.map(n => (
                      <option key={n.name}>{n.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Form.Group>
                  <Form.Label className="form-label-header mb-2">
                    직급
                  </Form.Label>
                  <Form.Select
                    id="rank"
                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                      setInputs({
                        ...inputs,
                        [e.target.id]: e.target.value
                      });
                    }}
                    defaultValue={inputs.rank}
                  >
                    <option>선택 안함</option>
                    {rankList.map(n => (
                      <option key={n.name}>{n.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="border-0 p-0">
            <Button
              variant="link"
              className="text-danger px-3 my-0"
              onClick={handleEditModalClose}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="my-0"
              onClick={handleSaveEditUser}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </MemberManagerContext.Provider>
  );
};

export const useMemberManagerContext = () => useContext(MemberManagerContext);
export default MemberManager;
