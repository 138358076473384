import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useState
} from 'react';
import { useAppContext } from 'providers/AppProvider';
import { Navigate } from 'react-router-dom';

interface MainLayoutContextInterface {
  contentClass: string;
  setContentClass: Dispatch<SetStateAction<string>>;
  footerClass: string;
  setFooterClass: Dispatch<SetStateAction<string>>;
}

export const MainLayoutContext = createContext(
  {} as MainLayoutContextInterface
);

const MainLayoutProvider = ({ children }: PropsWithChildren) => {
  const [contentClass, setContentClass] = useState('');
  const [footerClass, setFooterClass] = useState('');
  const { user } = useAppContext();

  let isLoggedIn = false;
  if (user) {
    isLoggedIn = true;
  }

  return (
    <MainLayoutContext.Provider
      value={{ contentClass, setContentClass, footerClass, setFooterClass }}
    >
      {!isLoggedIn && <Navigate to="/pages/auth/sign-in" />}
      {isLoggedIn && children}
    </MainLayoutContext.Provider>
  );
};

export const useMainLayoutContext = () => useContext(MainLayoutContext);

export default MainLayoutProvider;
