import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import { Link } from 'react-router-dom';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import Avatar from 'components/base/Avatar';
import { IUserInfo } from 'lib/firebase';
import { useAppContext } from 'providers/AppProvider';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import { Dropdown } from 'react-bootstrap';
import { useMemberManagerContext } from 'pages/pages/MemberManager';

export const memberManagerTablecolumns: ColumnDef<IUserInfo>[] = [
  {
    accessorKey: 'name',
    header: '이름',
    cell: ({ row: { original } }) => {
      const { userName, userImageUrl } = original;
      return (
        <Link
          to="#!"
          className=" d-flex align-items-center text-900 text-hover-1000"
        >
          <Avatar src={userImageUrl} size="m" />
          <p className="mb-0 ms-3 text-1100 fw-semi-bold">{userName}</p>
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { minWidth: '100px' },
        className: 'px-1'
      },
      cellProps: { className: 'align-middle white-space-nowrap py-2' }
    }
  },
  {
    accessorKey: 'email',
    header: '이메일',
    cell: ({ row: { original } }) => {
      const { email } = original;
      return (
        <Link to={`mailto:${email}`} className="fw-semi-bold">
          {email}
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { minWidth: '200px' }
      },
      cellProps: { className: 'white-space-nowrap' }
    }
  },
  {
    accessorKey: 'mobile',
    header: '휴대폰 번호',
    cell: ({ row: { original } }) => {
      const { mobilePhone } = original;
      return (
        <Link to={`tel:${mobilePhone}`} className="fw-bold text-1100">
          {mobilePhone}
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { minWidth: '200px' },
        className: 'pe-3'
      }
    }
  },
  {
    accessorKey: 'team',
    header: '부서',
    cell: ({ row: { original } }) => {
      const { teamCode } = original;
      const { teamList } = useAppContext();
      let data = teamCode;
      if (teamList) {
        const teamInfo = teamList.find(v => v.code === teamCode);
        if (teamInfo) {
          data = teamInfo.name;
        }
      }
      return <div className="text-900">{data}</div>;
    },
    meta: {
      headerProps: { style: { width: '10%' } },
      cellProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'rank',
    header: '직급',
    cell: ({ row: { original } }) => {
      const { rankCode } = original;
      const { rankList } = useAppContext();
      let data = rankCode;
      if (rankList) {
        const rankInfo = rankList.find(v => v.code === rankCode);
        if (rankInfo) {
          data = rankInfo.name;
        }
      }
      return <div className="text-900">{data}</div>;
    },
    meta: {
      headerProps: {
        style: { minWidth: '200px' },
        className: 'text-end'
      },
      cellProps: {
        className: 'text-end text-700'
      }
    }
  },
  {
    id: 'action',
    cell: ({ row }) => {
      const { openEditModel } = useMemberManagerContext();
      return (
        <RevealDropdownTrigger>
          <RevealDropdown>
            <Dropdown.Item onClick={() => openEditModel(row.index)}>
              Edit
            </Dropdown.Item>
          </RevealDropdown>
        </RevealDropdownTrigger>
      );
    },
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end' },
      cellProps: { className: 'text-end' }
    }
  }
];

const MemberManagerTable = () => {
  return (
    <div>
      <AdvanceTable
        tableProps={{ className: 'phoenix-table fs-9' }}
        rowClassName="position-static hover-bg-100"
      />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default MemberManagerTable;
