import FeatherIcon from 'feather-icons-react';
import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { Link, useNavigate } from 'react-router-dom';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import { ProgressBar, Row, Col } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useAppContext } from 'providers/AppProvider';
import {
  dbGetProject,
  dbGetMyProject,
  CATEGORY_PRIVATE,
  getUserName,
  getProjectNotify,
  dbProcessInfo,
  setProcessCheck,
  CATEGORY_PROJECT
} from 'lib/firebase';
import { BadgeBg } from 'components/base/Badge';
import Badge from 'components/base/Badge';
import NotificationItem from 'components/common/NotificationItem';
import { Notification } from 'data/notifications';
import { getClassName, IProcess, INotifyInfo } from 'interfaces';
import dayjs from 'dayjs';
import ProcessInfo from './ProcessInfo';

import { EventClickArg } from '@fullcalendar/core';
import CalendarProvider, {
  useCalendarContext
} from 'providers/CalendarProvider';
import CalendarHeader from 'pages/apps/calendar/CalendarHeader';
import CalendarEventModal from 'components/modals/CalendarEventModal';
import { HANDLE_SELECT, SET_CALENDAR_STATE } from 'reducers/CalendarReducer';
import FullCalendar from 'components/base/FullCalendar';
import { CalendarEvent } from 'data/calendarEvents';

interface Status {
  label: string;
  bg: BadgeBg;
}

interface Project {
  id: number;
  key: string;
  category: string;
  name: string;
  president: string;
  manager: string;
  startAt: string;
  progress: {
    min: number;
    max: number;
  };
  task: number;
  status: Status;
  startDate: Date;
  endDate: Date;
}

const columns: ColumnDef<Project>[] = [
  {
    accessorKey: 'name',
    header: '업무명',
    cell: ({ row: { original } }) => {
      const { name, key, category } = original;
      return (
        <Link
          to={'/project-detail/' + key + '?category=' + category}
          className="text-decoration-none fw-bold fs-8"
        >
          {name}
        </Link>
      );
    },
    meta: {
      cellProps: { className: 'white-space-nowrap' },
      headerProps: { style: { width: '30%' } }
    }
  },
  {
    accessorKey: 'president',
    header: '주관인',
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-900 white-space-nowrap' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    }
  },
  {
    accessorKey: 'manager',
    header: '진행자',
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-900 white-space-nowrap' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    }
  },
  {
    accessorKey: 'startAt',
    header: '시작일',
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-900 white-space-nowrap' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    }
  },
  {
    id: 'progress',
    header: '진행',
    cell: ({ row: { original } }) => {
      const { progress } = original;

      return (
        <>
          <p className="text-800 fs-10 mb-0">
            {progress.min} / {progress.max}
          </p>
          <ProgressBar
            now={(progress.min / progress.max) * 100}
            style={{ height: 3 }}
            variant="success"
          />
        </>
      );
    },
    meta: {
      cellProps: { className: 'ps-3' },
      headerProps: { style: { width: '5%' }, className: 'ps-3' }
    }
  },
  {
    id: 'status',
    header: '상태',
    cell: ({ row: { original } }) => {
      const { status } = original;

      return (
        <Badge
          variant="phoenix"
          bg={status.bg}
          className="fs-10 ms-auto py-1 mb-1"
          style={{ width: '80%' }}
        >
          {status.label}
        </Badge>
      );
    },
    meta: {
      cellProps: { className: 'ps-8' },
      headerProps: { style: { width: '10%' }, className: 'ps-8' }
    }
  }
  // {
  //   id: 'action',
  //   cell: ({ row: { original } }) => {
  //     const { key, category } = original;
  //     return (
  //       <Link to={'/project-create/' + key + '?category=' + category}>
  //         <FeatherIcon icon="edit" size={16} />
  //       </Link>
  //     );
  //   },
  //   meta: {
  //     headerProps: { style: { width: '1%' }, className: 'text-end' },
  //     cellProps: { className: 'text-end' }
  //   }
  // }
];

const Dashboard = () => {
  const { userInfo, userList } = useAppContext();
  const [priProjectList, setPriProjectList] = useState<Project[]>([]);
  const [stdProjectList, setStdProjectList] = useState<Project[]>([]);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [processList, setProcessList] = useState<INotifyInfo[]>([]);

  const [openProcessInfo, setOpenProcessInfo] = useState(false);
  const [notifyProcess, setNotifyProcess] = useState<IProcess>();
  const [notifyProjectId, setNotifyProjectId] = useState('');
  const [notifyProcessIndex, setNotifyProcessIndex] = useState(0);

  const [events, setEvents] = useState<CalendarEvent[]>([]);

  const tableStdProject = useAdvanceTable({
    data: stdProjectList,
    columns,
    // pageSize: 10,
    // pagination: true,
    sortable: false
  });

  const tablePriProject = useAdvanceTable({
    data: priProjectList,
    columns,
    // pageSize: 10,
    // pagination: true,
    sortable: false
  });

  const getStatus = (complete: boolean): Status => {
    if (complete) {
      return {
        label: '완료',
        bg: 'success'
      };
    }
    return {
      label: '진행중',
      bg: 'warning'
    };
  };

  const processOk = (processIndex: number) => {
    const processInfo = processList[processIndex];
    if (userInfo && processInfo) {
      setProcessCheck(userInfo, processInfo.projectId, processIndex);
    }
  };

  useEffect(() => {
    if (userInfo) {
      dbGetMyProject(userInfo, 10).then(retData => {
        const projects: Project[] = [];
        retData.forEach(n => {
          let id: number = 1;
          projects.push({
            id: id++,
            key: n.key ? n.key : '',
            category: n.rootCategory,
            name: n.title,
            president: getUserName(userList, n.president),
            manager: getUserName(userList, n.manager),
            startAt: n.createAt ? dayjs(n.createAt).format('YYYY-MM-DD') : '-',
            progress: {
              min: n.progress ? n.progress : 0,
              max: n.process.length
            },
            task: 287,
            status: getStatus(n.complete ? n.complete : false),
            startDate: dayjs(n.createAt).toDate(),
            endDate: dayjs(n.endAt).toDate()
          });
        });
        setStdProjectList(projects);
        tableStdProject.setPageSize(projects.length);
      });

      dbGetProject(userInfo, CATEGORY_PRIVATE, 10).then(retData => {
        const projects: Project[] = [];
        retData.forEach(n => {
          let id: number = 1;
          projects.push({
            id: id++,
            key: n.key ? n.key : '',
            category: n.rootCategory,
            name: n.title,
            president: getUserName(userList, n.president),
            manager: getUserName(userList, n.manager),
            startAt: dayjs(n.createAt).format('YYYY-MM-DD'),
            progress: {
              min: n.progress ? n.progress : 0,
              max: n.process.length
            },
            task: 287,
            status: getStatus(n.complete ? n.complete : false),
            startDate: dayjs(n.createAt).toDate(),
            endDate: dayjs(n.endAt).toDate()
          });
        });
        setPriProjectList(projects);
        tablePriProject.setPageSize(projects.length);
      });

      getProjectNotify(userInfo.uid, 10).then(data => {
        setProcessList(data);

        const notifyList: Notification[] = [];
        data.forEach((v, i) => {
          notifyList.push({
            id: i,
            interactionIcon: '💬',
            name: getClassName(v.classCode),
            interaction: v.projectTitle + ', ',
            detail: v.processTitle,
            ago: '',
            icon: 'clock',
            time: dayjs(v.receiveAt).format('HH:mm:ss '),
            date: dayjs(v.receiveAt).format('YYYY-MM-DD'),
            read: false
          });
        });
        setNotifications(notifyList);
      });
    }
  }, [userInfo]);

  useEffect(() => {
    const eventData: CalendarEvent[] = [];
    let eventCount = 0;

    for (let i = 0; i < priProjectList.length; i++) {
      eventCount++;
      eventData.push({
        id: eventCount.toString(),
        title: priProjectList[i].name,
        start: priProjectList[i].startDate,
        end: priProjectList[i].endDate,
        description: '',
        url:
          '/project-detail/' +
          priProjectList[i].key +
          '?category=' +
          CATEGORY_PRIVATE,
        className: 'text-warning'
      });
    }

    for (let i = 0; i < stdProjectList.length; i++) {
      eventCount++;
      eventData.push({
        id: eventCount.toString(),
        title: stdProjectList[i].name,
        start: stdProjectList[i].startDate,
        end: stdProjectList[i].endDate,
        description: '',
        url:
          '/project-detail/' +
          stdProjectList[i].key +
          '?category=' +
          CATEGORY_PROJECT,
        className: 'text-primary'
      });
    }
    setEvents(eventData);
  }, [priProjectList, stdProjectList]);

  return (
    <>
      {/* <Row>
        <CalendarProvider>
          <Calendar />
        </CalendarProvider>
      </Row> */}

      <Row className="g-0">
        <Col xs={12} xxl={4} className="px-3 pe-3">
          <div className="h-100">
            <div className="dark__bg-1100 h-100">
              <div className="p-4 p-lg-6">
                <h3 className="text-1000 mb-4 fw-bold">업무 알림</h3>
              </div>
              <div className="mx-n4 mx-lg-n6 mb-5 border-top border-300">
                {notifications.length > 0 &&
                  notifications.map((notification, index) => (
                    <div
                      key={index}
                      onClick={async () => {
                        const processInfo = processList[index];
                        if (userInfo && processInfo) {
                          const process = await dbProcessInfo(
                            userInfo,
                            processInfo.rootCategory,
                            processInfo.projectId,
                            processInfo.processId
                          );
                          setNotifyProjectId(processInfo.projectId);
                          setNotifyProcessIndex(processInfo.processId);
                          setNotifyProcess(process);
                          setOpenProcessInfo(true);
                        }
                      }}
                    >
                      <NotificationItem
                        notification={notification}
                        type="pageItem"
                        className="border-bottom"
                        key={notification.id}
                      />
                    </div>
                  ))}
              </div>
              {notifications.length <= 0 && (
                <>
                  <br />
                  <div className="flex-center text-300 text-center">...</div>
                  <br />
                  <br />
                  <div className="mx-n4 mx-lg-n6 mb-5 border-bottom border-300" />
                </>
              )}
            </div>
          </div>
        </Col>
        <Col xs={12} xxl={8} className="px-0 bg-soft">
          {/* <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 pt-6"> */}
          <div className="px-4 px-lg-6 pt-6">
            <CalendarProvider>
              <Calendar eventData={events} />
            </CalendarProvider>
          </div>
          <div className="px-4 px-lg-6 pt-6">
            <h3>협업 업무</h3>
            <p className="text-700 lh-sm mb-4">
              Brief summary of all team projects
            </p>
            <AdvanceTableProvider {...tableStdProject}>
              <AdvanceTable
                tableProps={{
                  className: 'phoenix-table border-top border-200 fs-9'
                }}
                rowClassName="hover-bg-100"
              />
              {/* <AdvanceTableFooter pagination /> */}
            </AdvanceTableProvider>
          </div>
          <div className="px-4 px-lg-6 pt-6">
            <h3>개인 업무</h3>
            <p className="text-700 lh-sm mb-4">
              Brief summary of all my projects
            </p>
            <AdvanceTableProvider {...tablePriProject}>
              <AdvanceTable
                tableProps={{
                  className: 'phoenix-table border-top border-200 fs-9'
                }}
                rowClassName="hover-bg-100"
              />
              {/* <AdvanceTableFooter pagination /> */}
            </AdvanceTableProvider>
          </div>
        </Col>
      </Row>

      <ProcessInfo
        show={openProcessInfo}
        setShow={setOpenProcessInfo}
        userInfo={userInfo}
        userList={userList}
        process={notifyProcess}
        projectId={notifyProjectId}
        processIndex={notifyProcessIndex}
        processOk={processOk}
      />
    </>
  );
};

interface propsCanlendar {
  eventData: CalendarEvent[];
}

const Calendar = (props: propsCanlendar) => {
  const { calendarDispatch } = useCalendarContext();
  const [events, setEvents] = useState<CalendarEvent[]>([]);
  const navigate = useNavigate();

  const handleEventClick = (info: EventClickArg) => {
    if (info.event.url) {
      // window.open(info.event.url);
      navigate(info.event.url);
      info.jsEvent.preventDefault();
    } else {
      calendarDispatch({
        type: SET_CALENDAR_STATE,
        payload: {
          selectedEvent: info.event
        }
      });
    }
  };

  useEffect(() => {
    setEvents(props.eventData);
  }, [props.eventData]);

  return (
    <div>
      {/* <CalendarTop /> */}
      <CalendarHeader />
      <div className="mt-6 mb-9">
        <FullCalendar
          // height={625}
          select={info => {
            calendarDispatch({
              type: HANDLE_SELECT,
              payload: info
            });
          }}
          events={events}
          eventClick={handleEventClick}
        />
      </div>
      <CalendarEventModal />
      {/* <CalendarAddNewEventModal /> */}
    </div>
  );
};

export default Dashboard;
