import { firebaseConfig } from 'config';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail
} from 'firebase/auth';
import {
  getFirestore,
  updateDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
  collection,
  query,
  where,
  deleteDoc,
  addDoc
} from 'firebase/firestore';
import {
  getDatabase,
  ref as dbRef,
  set as dbSet,
  push as dbPush,
  child as dbChild,
  update as dbUpdate,
  remove as dbRemove,
  orderByChild as dbOrderByChild,
  orderByKey as dbOrderByKey,
  equalTo as dbEqualTo,
  startAt as dbStartAt,
  startAfter as dbStartAfter,
  endBefore as dbEndBefore,
  endAt as dbEndAt,
  get as dbGet,
  query as dbQuery,
  onValue,
  ListenOptions,
  limitToLast as dbLimitToLast,
  limitToFirst as dbLimitToFirst
} from 'firebase/database';
import {
  IProjectTempleate,
  ITeamUserTable,
  INotifyInfo,
  IProcess,
  ICheckInfo,
  IBulletinBoard
} from 'interfaces';
import {
  uploadProjectFile,
  deleteProjectFiles,
  copyProjectFiles
} from 'lib/fbStorage';

// ====================================================================================================
// common
// ====================================================================================================
const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(firebaseApp);
export const fStore = getFirestore(firebaseApp);
//export const fDb = getDatabase(firebaseApp);

export interface ICompanyInfo {
  companyName: string | null;
  companyImageUrl: string | null;
}

export interface ICompanyList {
  code: string;
  name: string;
}

export const getCompanyList = async (): Promise<ICompanyList[]> => {
  const companyList: ICompanyList[] = [];

  try {
    const dbRef = fStore;
    const docSnap = await getDocs(collection(dbRef, 'companys'));

    if (docSnap.size > 0) {
      docSnap.forEach(doc => {
        companyList.push({
          code: doc.id,
          name: doc.get('companyName') ? doc.get('companyName') : ''
        });
      });
    }
  } catch (error) {
    console.log(error);
  }
  return companyList;
};

// ====================================================================================================
// sign-in
// ====================================================================================================
export const signIn = async (email: string, pw: string): Promise<number> => {
  // return value
  // 0  : 회원 정보 없음
  // 1  : id, pw 오류
  // 2  : 이메일 인증 없음
  // 3  : 가입 승인 대기
  // 10 : 로그인 성공
  const auth = getAuth();

  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, pw);

    if (userCredential) {
      const user = userCredential.user;
      if (user.emailVerified) {
        const userAuth = await getUserAuth(user.uid);
        if (userAuth === 0) {
          // 가입 승인 대기중
          if (user.uid && user.email) {
            const userInfo: IUserInfo = {
              uid: user.uid,
              email: user.email,
              auth: 0,
              userName: '',
              userImageUrl: '',
              mobilePhone: '',
              companyUuid: '',
              companyName: '',
              companyImageUrl: '',
              teamCode: '',
              rankCode: ''
            };
            setUserInfo(userInfo);
          }

          return 3;
        } else {
          // 로그인 성공
          return 10;
        }
      } else {
        signOut(auth);
        // alert('이메일 인증이 되지 않았습니다.');
        return 2;
      }
    } else {
      // alert('사용자 정보 조회 실패');
      return 0;
    }
  } catch (error) {
    // alert('이메일, 비밀번호를 다시 확인해주세요.');
    console.log(error);
    return 1;
  }
};

export const changePassword = async (email: string): Promise<boolean> => {
  if (email) {
    const auth = getAuth();
    try {
      await sendPasswordResetEmail(auth, email);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
  return false;
};

export const getUserAuth = async (uid: string) => {
  if (uid) {
    const dbRef = fStore;
    const userDoc = await getDoc(doc(dbRef, 'users', uid));

    if (userDoc.exists()) {
      const userAuth: number = userDoc.get('auth');
      return userAuth ? userAuth : 0;
    }
  }
  return 0;
};

// ====================================================================================================
// user
// ====================================================================================================
export interface IUserInfo {
  uid: string;
  email: string;
  auth: number;
  userName: string;
  userImageUrl: string;
  mobilePhone: string;
  companyUuid: string;
  companyName: string;
  companyImageUrl: string;
  teamCode: string;
  rankCode: string;
}

// export type TUserList = {
//   uid: string;
//   avatar?: string;
//   name: string;
//   email: string;
//   mobile: number | string;
//   teamCode: string;
//   rankCode: string;
// };

export const getUserInfo = async (uid: string): Promise<IUserInfo> => {
  const userInfo: IUserInfo = {
    uid: '',
    email: '',
    auth: 0,
    userName: '',
    userImageUrl: '',
    mobilePhone: '',
    companyUuid: '',
    companyName: '',
    companyImageUrl: '',
    teamCode: '',
    rankCode: ''
  };

  if (uid) {
    const dbRef = fStore;
    const userDoc = await getDoc(doc(dbRef, 'users', uid));

    if (userDoc.exists()) {
      const {
        uid,
        email,
        auth,
        userName,
        userImageUrl,
        mobilePhone,
        companyUuid,
        companyName,
        companyImageUrl,
        teamCode,
        rankCode
      } = userDoc.data();

      if (uid) userInfo.uid = uid;
      if (email) userInfo.email = email;
      if (auth) userInfo.auth = auth;
      if (userName) userInfo.userName = userName;
      if (userImageUrl) userInfo.userImageUrl = userImageUrl;
      if (mobilePhone) userInfo.mobilePhone = mobilePhone;
      if (companyUuid) userInfo.companyUuid = companyUuid;
      if (companyName) userInfo.companyName = companyName;
      if (companyImageUrl) userInfo.companyImageUrl = companyImageUrl;
      if (teamCode) userInfo.teamCode = teamCode;
      if (rankCode) userInfo.rankCode = rankCode;

      // userInfo.uid = userDoc.id;
      // userInfo.email = userDoc.get('email');
      // userInfo.auth = userDoc.get('auth');
      // userInfo.userName = userDoc.get('userName');
      // userInfo.userImageUrl = userDoc.get('userImageUrl');
      // userInfo.mobilePhone = userDoc.get('mobilePhone');
      // userInfo.companyUuid = userDoc.get('companyUuid');
      // userInfo.companyName = userDoc.get('companyName');
      // userInfo.companyImageUrl = userDoc.get('companyImageUrl');
      // userInfo.teamCode = userDoc.get('teamCode');
      // userInfo.rankCode = userDoc.get('rankCode');
    }

    if (userInfo.companyUuid) {
      const companyDoc = await getDoc(
        doc(dbRef, 'companys', userInfo.companyUuid)
      );

      if (companyDoc.exists()) {
        const { companyName, companyImageUrl } = companyDoc.data();
        // userInfo.companyName = companyDoc.get('companyName');
        // userInfo.companyImageUrl = companyDoc.get('companyImageUrl');
        if (companyName) userInfo.companyName = companyName;
        if (companyImageUrl) userInfo.companyImageUrl = companyImageUrl;
      }
    }
  }
  return userInfo;
};

export const setUserInfo = (userInfo: IUserInfo) => {
  /*
export interface IUserInfo {
  uid: string;
  email: string;
  auth?: number;
  userName: string;
  userImageUrl: string;
  mobilePhone: string;
  companyUuid: string;
  companyName: string;
  companyImageUrl: string;
  teamCode: string;
  rankCode: string;
}
  */
  if (userInfo.uid) {
    const dbRef = fStore;

    // updateDoc(doc(dbRef, 'users', uid), {
    //   email: userInfo.email,
    //   userName: userInfo.userName,
    //   mobilePhone: userInfo.mobilePhone,
    //   userImageUrl: userInfo.userImageUrl,
    //   teamCode: userInfo.teamCode,
    //   rankCode: userInfo.rankCode
    // // });
    setDoc(doc(dbRef, 'users', userInfo.uid), userInfo);
  }
};

export const addCompany = (name: string) => {
  const dbRef = fStore;
  const collectionRef = collection(dbRef, 'companys');
  addDoc(collectionRef, {
    companyName: name,
    companyImageUrl: ''
  });
};

export const setCompanyInfo = (uid: string, companyInfo: ICompanyInfo) => {
  if (uid) {
    const dbRef = fStore;

    updateDoc(doc(dbRef, 'companys', uid), {
      companyName: companyInfo.companyName,
      companyImageUrl: companyInfo.companyImageUrl
    });
  }
};

export const getUsers = async (companyUuid: string): Promise<IUserInfo[]> => {
  const userList: IUserInfo[] = [];

  if (companyUuid) {
    const dbRef = fStore;
    const collectionRef = collection(dbRef, 'users');
    const q = query(collectionRef, where('companyUuid', '==', companyUuid));
    const docSnap = await getDocs(q);
    docSnap.forEach(doc => {
      userList.push({
        uid: doc.id,
        email: doc.get('email'),
        auth: doc.get('auth'),
        userName: doc.get('userName'),
        userImageUrl: doc.get('userImageUrl'),
        mobilePhone: doc.get('mobilePhone'),
        companyUuid: doc.get('companyUuid'),
        companyName: doc.get('companyName'),
        companyImageUrl: doc.get('companyImageUrl'),
        teamCode: doc.get('teamCode'),
        rankCode: doc.get('rankCode')
      });
    });
  }
  return userList;
};

export const getSiteUsers = async (): Promise<IUserInfo[]> => {
  const userList: IUserInfo[] = [];

  const dbRef = fStore;
  const collectionRef = collection(dbRef, 'users');
  const q = query(collectionRef);
  const docSnap = await getDocs(q);
  docSnap.forEach(doc => {
    userList.push({
      uid: doc.id,
      email: doc.get('email'),
      auth: doc.get('auth'),
      userName: doc.get('userName'),
      userImageUrl: doc.get('userImageUrl'),
      mobilePhone: doc.get('mobilePhone'),
      companyUuid: doc.get('companyUuid'),
      companyName: doc.get('companyName'),
      companyImageUrl: doc.get('companyImageUrl'),
      teamCode: doc.get('teamCode'),
      rankCode: doc.get('rankCode')
    });
  });

  return userList;
};

export const getUserList = (
  userList: IUserInfo[],
  teamCode: string
): IUserInfo[] => {
  let selUserList;
  if (teamCode) {
    selUserList = userList.filter(user => user.teamCode === teamCode);
  } else {
    // selUserList = userList.filter(user => !user.teamCode);
    selUserList = JSON.parse(JSON.stringify(userList));
  }
  return selUserList;
};

export const getTeamUserInfo = (
  userList: IUserInfo[],
  teamList: ITeamInfo[],
  userId: string
): ITeamUserTable => {
  const teamUserInfo: ITeamUserTable = {
    team: '',
    teamId: '',
    user: '',
    userId: ''
  };
  const user = userList.find(e => e.uid === userId);
  if (user) {
    teamUserInfo.user = user.userName;
    teamUserInfo.userId = user.uid;

    const team = teamList.find(e => e.code === user.teamCode);
    if (team) {
      teamUserInfo.teamId = user.teamCode;
      teamUserInfo.team = team.name;
    }
  }
  return teamUserInfo;
};

export const getTeamName = (teamList: ITeamInfo[], code: string): string => {
  let teamName = '';

  const team = teamList.find(e => e.code === code);
  if (team) {
    teamName = team.name;
  }

  return teamName;
};

export const getUserName = (userList: IUserInfo[], id: string): string => {
  let userName = '';

  const user = userList.find(e => e.uid === id);
  if (user) {
    userName = user.userName;
  }

  return userName;
};

// ====================================================================================================
// teams
// ====================================================================================================
export interface ITeamInfo {
  parentId: string;
  code: string;
  name: string;
  phone: string;
}

export const getTeams = async (companyUuid: string): Promise<ITeamInfo[]> => {
  const userList: ITeamInfo[] = [];

  if (companyUuid) {
    try {
      const dbRef = fStore;
      const docSnap = await getDocs(collection(dbRef, 'teams_' + companyUuid));

      if (docSnap.size > 0) {
        docSnap.forEach(doc => {
          userList.push({
            parentId: doc.get('parentId') ? doc.get('parentId') : '',
            code: doc.id,
            name: doc.get('teamName') ? doc.get('teamName') : '',
            phone: doc.get('phone') ? doc.get('phone') : ''
          });
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  return userList;
};

export const setTeams = (companyUuid: string, teamInfo: ITeamInfo[]) => {
  if (companyUuid) {
    try {
      const dbRef = fStore;
      teamInfo.forEach(data => {
        setDoc(doc(dbRef, 'teams_' + companyUuid, data.code), {
          teamName: data.name
        });
      });
    } catch (error) {
      console.log(error);
    }
  }
};

export const setTeam = (companyUuid: string, teamInfo: ITeamInfo) => {
  if (companyUuid) {
    try {
      const dbRef = fStore;
      setDoc(doc(dbRef, 'teams_' + companyUuid, teamInfo.code), {
        parentId: teamInfo.parentId,
        teamName: teamInfo.name,
        phone: teamInfo.phone
      });
    } catch (error) {
      console.log(error);
    }
  }
};

export const deleteTeam = (companyUuid: string, teamCode: string) => {
  if (companyUuid) {
    try {
      const dbRef = fStore;
      deleteDoc(doc(dbRef, 'teams_' + companyUuid, teamCode));
    } catch (error) {
      console.log(error);
    }
  }
};

// ====================================================================================================
// rank
// ====================================================================================================
export type TRankInfo = {
  code: string;
  name: string;
};

export const getRank = async (companyUuid: string): Promise<TRankInfo[]> => {
  const userList: TRankInfo[] = [];

  if (companyUuid) {
    try {
      const dbRef = fStore;
      const docSnap = await getDocs(collection(dbRef, 'rank_' + companyUuid));

      if (docSnap.size > 0) {
        docSnap.forEach(doc => {
          userList.push({
            code: doc.id,
            name: doc.get('rankName')
          });
        });
      }
    } catch (error) {
      console.log(error);
    }
  }
  return userList;
};

export const setRank = (companyUuid: string, rankInfo: TRankInfo) => {
  if (companyUuid) {
    try {
      const dbRef = fStore;
      setDoc(doc(dbRef, 'rank_' + companyUuid, rankInfo.code), {
        rankName: rankInfo.name
      });
    } catch (error) {
      console.log(error);
    }
  }
};

export const deleteRank = (companyUuid: string, teamCode: string) => {
  if (companyUuid) {
    try {
      const dbRef = fStore;
      deleteDoc(doc(dbRef, 'rank_' + companyUuid, teamCode));
    } catch (error) {
      console.log(error);
    }
  }
};

// ====================================================================================================
// project
// ====================================================================================================
// export const dbAddProject = (companyUuid: string, info: IProjectTempleate) => {
//   const db = getDatabase(firebaseApp);
//   const path = companyUuid + '_stdJob';
//   const newKey = dbPush(dbChild(dbRef(db), path)).key;
//   const ref = dbRef(db, path + '/' + newKey);
//   dbSet(ref, info);
// };

export const dbGetBulletinBoard = async (
  userInfo: IUserInfo,
  limit: number,
  endAtKey?: string,
  next?: boolean
): Promise<IBulletinBoard[]> => {
  const bulletinBoard: IBulletinBoard[] = [];
  const path = userInfo.companyUuid + '_BulletinBoard';
  const db = getDatabase(firebaseApp);
  let q;
  if (limit > 0 && endAtKey) {
    q = dbQuery(
      dbRef(db, path),
      next ? dbEndAt(null, endAtKey) : dbStartAt(null, endAtKey),
      next ? dbLimitToLast(limit) : dbLimitToFirst(limit)
    );
  } else if (limit > 0) {
    q = dbQuery(dbRef(db, path), dbLimitToLast(limit));
  } else {
    q = dbQuery(dbRef(db, path));
  }

  const snapshot = await dbGet(q);

  if (snapshot.exists()) {
    snapshot.forEach(data => {
      // projectList.push(data.val());
      bulletinBoard.unshift(data.val());
      // const project = projectList.at(-1);
      const oneData = bulletinBoard.at(0);
      if (oneData) {
        oneData.key = data.key as string;
      }
    });
  }
  return bulletinBoard;
};

export const dbBulletinBoardInfo = async (
  userInfo: IUserInfo,
  key: string
): Promise<IBulletinBoard> => {
  let data: IBulletinBoard = {
    key: '',
    userId: '',
    title: '',
    text: '',
    createAt: 0
  };
  const db = getDatabase(firebaseApp);
  const snapshot = await dbGet(
    dbQuery(dbRef(db, userInfo.companyUuid + '_BulletinBoard' + '/' + key))
  );
  if (snapshot.exists()) {
    data = snapshot.val();
    data.key = snapshot.key as string;
  }
  return data;
};

export const dbAddBulletinBoard = async (
  userInfo: IUserInfo,
  data: IBulletinBoard
) => {
  const db = getDatabase(firebaseApp);
  const path = userInfo.companyUuid + '_BulletinBoard';
  const key = dbPush(dbChild(dbRef(db), path)).key;
  if (key) {
    const ref = dbRef(db, path + '/' + key);

    data.key = key;
    data.createAt = Date.now();

    dbSet(ref, data);
  }
};

export const dbModifyBulletinBoard = async (
  userInfo: IUserInfo,
  data: IBulletinBoard
) => {
  if (data.key) {
    const db = getDatabase(firebaseApp);
    const path = userInfo.companyUuid + '_BulletinBoard/' + data.key;
    const ref = dbRef(db, path);

    data.createAt = Date.now();
    dbSet(ref, data);
  }
};

export const dbRemoveBulletinBoard = (userInfo: IUserInfo, key: string) => {
  if (key) {
    const db = getDatabase(firebaseApp);
    const path = userInfo.companyUuid + '_BulletinBoard/' + key;
    const ref = dbRef(db, path);
    dbRemove(ref);
  }
};

export const getProjectClassId = (
  userInfo: IUserInfo,
  category: string
): string => {
  let classId = '';
  switch (category) {
    case CATEGORY_STANDARD:
    case CATEGORY_PROJECT:
      classId = userInfo.companyUuid;
      break;
    case CATEGORY_PRIVATE:
      classId = userInfo.uid;
      break;
  }
  return classId;
};

export const dbGetProject = async (
  userInfo: IUserInfo,
  rootCategory: string,
  limit: number,
  endAtKey?: string,
  next?: boolean
): Promise<IProjectTempleate[]> => {
  const projectList: IProjectTempleate[] = [];
  const classId = getProjectClassId(userInfo, rootCategory);

  const db = getDatabase(firebaseApp);
  let q;
  if (limit > 0 && endAtKey) {
    q = dbQuery(
      dbRef(db, classId + '_JOB_' + rootCategory),
      next ? dbEndAt(null, endAtKey) : dbStartAt(null, endAtKey),
      next ? dbLimitToLast(limit) : dbLimitToFirst(limit)
    );
  } else if (limit > 0) {
    q = dbQuery(
      dbRef(db, classId + '_JOB_' + rootCategory),
      dbLimitToLast(limit)
    );
  } else {
    q = dbQuery(dbRef(db, classId + '_JOB_' + rootCategory));
  }

  const snapshot = await dbGet(q);

  if (snapshot.exists()) {
    snapshot.forEach(data => {
      // projectList.push(data.val());
      projectList.unshift(data.val());
      // const project = projectList.at(-1);
      const project = projectList.at(0);
      if (project) {
        project.key = data.key as string;
        project.timeFrame = getTotalTimeFrame(project);
        project.progress = getProjectProgress(project);
      }
    });
  }
  return projectList;
};

export const dbGetMyProjectList = async (
  userId: string,
  limit: number,
  endAtKey?: string,
  next?: boolean
): Promise<IUserProjectId[]> => {
  const userProjectId = await dbGetUserProjectId(userId, limit, endAtKey, next);
  return userProjectId;
};

export const dbGetMyProjectInfo = async (
  userInfo: IUserInfo,
  userProjectId: IUserProjectId[]
): Promise<IProjectTempleate[]> => {
  const projectList: IProjectTempleate[] = [];

  for (let i = 0; i < userProjectId.length; i++) {
    const data = await dbProjectInfo(
      userInfo,
      CATEGORY_PROJECT,
      userProjectId[i].projectId
    );

    if (data.key) {
      if (isUserInProject(userInfo.uid, data)) {
        projectList.push(data);
      } else {
        dbRemoveUserProjectId(userInfo.uid, userProjectId[i].key);
      }
    } else {
      dbRemoveUserProjectId(userInfo.uid, userProjectId[i].key);
    }
  }
  return projectList;
};

export const dbGetMyProject = async (
  userInfo: IUserInfo,
  limit: number
): Promise<IProjectTempleate[]> => {
  const projectList: IProjectTempleate[] = [];
  const userProjectId = await dbGetUserProjectId(userInfo.uid, limit);

  for (let i = 0; i < userProjectId.length; i++) {
    const data = await dbProjectInfo(
      userInfo,
      CATEGORY_PROJECT,
      userProjectId[i].projectId
    );

    if (data.key) {
      if (isUserInProject(userInfo.uid, data)) {
        projectList.push(data);
      } else {
        dbRemoveUserProjectId(userInfo.uid, userProjectId[i].key);
      }
    } else {
      dbRemoveUserProjectId(userInfo.uid, userProjectId[i].key);
    }
  }
  return projectList;
};

export const getTotalTimeFrame = (project: IProjectTempleate): number => {
  let totalTimeFrame = 0;
  if (project.process) {
    for (let i = 0; i < project.process.length; i++) {
      totalTimeFrame += project.process[i].timeFrame;
    }
  }
  return totalTimeFrame;
};

export const getProjectProgress = (project: IProjectTempleate): number => {
  let progress = 0;
  if (project.process) {
    for (let i = 0; i < project.process.length; i++) {
      if (project.process[i].complete) {
        progress++;
      }
    }
  }
  return progress;
};

const isUserInProject = (
  userId: string,
  project: IProjectTempleate
): boolean => {
  if (project.key) {
    if (project.president === userId) {
      return true;
    }
    if (project.manager === userId) {
      return true;
    }

    for (let i = 0; i < project.referrer.length; i++) {
      if (project.referrer[i] === userId) {
        return true;
      }
    }
  }

  return false;
};

const setProjectUserList = async (project: IProjectTempleate) => {
  if (project.key) {
    if (project.president) {
      await dbAddUserProjectId(project.president, project.key);
    }
    if (project.manager) {
      await dbAddUserProjectId(project.manager, project.key);
    }

    for (let i = 0; i < project.referrer.length; i++) {
      await dbAddUserProjectId(project.referrer[i], project.key);
    }
  }
};

const isLocalFile = (url: string): boolean => {
  if (url.includes('http://localhost')) {
    return true;
  }
  return false;
};

export const uploadFiles = async (project: IProjectTempleate) => {
  if (project.key) {
    for (let i = 0; i < project.process.length; i++) {
      if (project.process[i].class === 'delivery') {
        const delivery = project.process[i].delivery;
        if (delivery && delivery.attachment) {
          for (let j = 0; j < delivery.attachment.length; j++) {
            if (isLocalFile(delivery.attachment[j].url)) {
              const url = await uploadProjectFile(
                project.key,
                delivery.attachment[j].url,
                delivery.attachment[j].name
              );
              if (url.length > 0) {
                delivery.attachment[j].url = url;
                delivery.attachment[j].projectId = project.key;
              } else {
                delivery.attachment[j] = {
                  name: '',
                  url: '',
                  size: 0,
                  projectId: ''
                };
              }
            }
            // else if (delivery.attachment[j].projectId != project.key) {
            //   console.log('copy');
            //   const url = await copyProjectFiles(
            //     project.key,
            //     delivery.attachment[j].url,
            //     delivery.attachment[j].name
            //   );
            //   console.log('url', url);
            //   if (url) {
            //     delivery.attachment[j].url = url;
            //   }
            // }
          }
        }
      } else if (project.process[i].class === 'approval') {
        const approval = project.process[i].approval;
        if (approval) {
          if (approval.formFile) {
            for (let j = 0; j < approval.formFile.length; j++) {
              if (isLocalFile(approval.formFile[j].url)) {
                const url = await uploadProjectFile(
                  project.key,
                  approval.formFile[j].url,
                  approval.formFile[j].name
                );
                if (url.length > 0) {
                  approval.formFile[j].url = url;
                  approval.formFile[j].projectId = project.key;
                } else {
                  approval.formFile[j] = {
                    name: '',
                    url: '',
                    size: 0,
                    projectId: ''
                  };
                }
              }
            }
          }
          if (approval.attachment) {
            for (let j = 0; j < approval.attachment.length; j++) {
              if (isLocalFile(approval.attachment[j].url)) {
                const url = await uploadProjectFile(
                  project.key,
                  approval.attachment[j].url,
                  approval.attachment[j].name
                );
                if (url.length > 0) {
                  approval.attachment[j].url = url;
                  approval.attachment[j].projectId = project.key;
                } else {
                  approval.attachment[j] = {
                    name: '',
                    url: '',
                    size: 0,
                    projectId: ''
                  };
                }
              }
            }
          }
        }
      } else if (project.process[i].class === 'request') {
        const request = project.process[i].request;
        if (request) {
          for (let j = 0; j < request.length; j++) {
            const attachment = request[j].attachment;
            if (attachment) {
              for (let k = 0; k < attachment.length; k++) {
                if (isLocalFile(attachment[k].url)) {
                  const url = await uploadProjectFile(
                    project.key,
                    attachment[k].url,
                    attachment[k].name
                  );
                  if (url.length > 0) {
                    attachment[k].url = url;
                    attachment[k].projectId = project.key;
                  } else {
                    attachment[k] = {
                      name: '',
                      url: '',
                      size: 0,
                      projectId: ''
                    };
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

export const setProjectComplete = async (
  userInfo: IUserInfo,
  rootCategory: string,
  projectId: string
): Promise<void> => {
  const classId = getProjectClassId(userInfo, rootCategory);
  const db = getDatabase(firebaseApp);
  const ref = dbRef(db, classId + '_JOB_' + rootCategory + '/' + projectId);
  dbUpdate(ref, {
    complete: true,
    completeAt: Date.now()
  });
};

export const setProcessComplete = async (
  userInfo: IUserInfo,
  rootCategory: string,
  projectId: string,
  processId: number
): Promise<void> => {
  const classId = getProjectClassId(userInfo, rootCategory);
  const db = getDatabase(firebaseApp);
  const ref = dbRef(
    db,
    classId +
      '_JOB_' +
      rootCategory +
      '/' +
      projectId +
      '/process/' +
      Number(processId)
  );
  dbUpdate(ref, {
    complete: true,
    completeAt: Date.now()
  });
};

export const setProcessCheck = async (
  userInfo: IUserInfo,
  projectId: string,
  processIndex: number
): Promise<void> => {
  const db = getDatabase(firebaseApp);
  const path =
    userInfo.companyUuid + '_CHECK' + '/' + projectId + '/' + processIndex;

  const check: ICheckInfo = {
    userId: userInfo.uid,
    checkedAt: Date.now()
  };

  const key = dbPush(dbChild(dbRef(db), path)).key;
  if (key) {
    const ref = dbRef(db, path + '/' + key);
    dbSet(ref, check);
    regsiterNotify(userInfo, CATEGORY_PROJECT, projectId);
  }
};

export const getProcessCheck = async (
  userInfo: IUserInfo,
  projectId: string,
  processIndex: number
): Promise<ICheckInfo[]> => {
  const checkList: ICheckInfo[] = [];

  const db = getDatabase(firebaseApp);
  const path =
    userInfo.companyUuid + '_CHECK' + '/' + projectId + '/' + processIndex;

  const snapshot = await dbGet(dbQuery(dbRef(db, path)));
  if (snapshot.exists()) {
    snapshot.forEach(data => {
      checkList.push(data.val());
    });
  }
  return checkList;
};

export const getProjectNotify = async (
  userId: string,
  limit: number,
  endAtKey?: string,
  next?: boolean
): Promise<INotifyInfo[]> => {
  const notifyList: INotifyInfo[] = [];

  const db = getDatabase(firebaseApp);
  let q;
  if (limit > 0 && endAtKey) {
    q = dbQuery(
      dbRef(db, userId + '_RCV_PROC'),
      next ? dbEndAt(null, endAtKey) : dbStartAt(null, endAtKey),
      next ? dbLimitToLast(limit) : dbLimitToFirst(limit)
    );
  } else if (limit > 0) {
    q = dbQuery(dbRef(db, userId + '_RCV_PROC'), dbLimitToLast(limit));
  } else {
    q = dbQuery(dbRef(db, userId + '_RCV_PROC'));
  }

  const snapshot = await dbGet(q);
  // const snapshot = await dbGet(dbQuery(dbRef(db, userId + '_RCV_PROC')));
  if (snapshot.exists()) {
    snapshot.forEach(data => {
      // notifyList.push(data.val());
      notifyList.unshift(data.val());

      const last = notifyList.at(0);
      if (last) {
        last.key = data.key as string;
      }
    });
  }
  return notifyList;
};

export const setProcessNotify = (userId: string, data: INotifyInfo) => {
  const db = getDatabase(firebaseApp);
  const path = userId + '_RCV_PROC';
  const key = dbPush(dbChild(dbRef(db), path)).key;
  if (key) {
    const ref = dbRef(db, path + '/' + key);
    dbSet(ref, data);
  }
};

export const regsiterNotify = async (
  userInfo: IUserInfo,
  rootCategory: string,
  projectId: string
) => {
  const project = await dbProjectInfo(userInfo, rootCategory, projectId);

  if (project.key) {
    let projectComplete = true;

    for (let i = 0; i < project.process.length; i++) {
      if (!project.process[i].complete) {
        const checkList = await getProcessCheck(userInfo, projectId, i);
        let findCount = 0;
        let processComplete = false;

        if (project.process[i].class === 'delivery') {
          const delivery = project.process[i].delivery;
          if (delivery) {
            for (let j = 0; j < delivery.recipient.length; j++) {
              const find = checkList.find(
                v => v.userId === delivery.recipient[j]
              );
              if (find) {
                findCount++;
              }
            }

            if (findCount === delivery.recipient.length) {
              processComplete = true;
            }
          }
        } else if (project.process[i].class === 'approval') {
          const approval = project.process[i].approval;
          if (approval) {
            for (let j = 0; j < approval.approver.length; j++) {
              const find = checkList.find(
                v => v.userId === approval.approver[j]
              );
              if (find) {
                findCount++;
              }
            }
            if (findCount === approval.approver.length) {
              processComplete = true;
            }
          }
        } else if (project.process[i].class === 'request') {
          const request = project.process[i].request;
          if (request) {
            for (let j = 0; j < request.length; j++) {
              const find = checkList.find(v => v.userId === request[j].sender);
              if (find) {
                findCount++;
              }
            }
            if (findCount === request.length) {
              processComplete = true;
            }
          }
        }

        if (processComplete) {
          project.process[i].complete = true;
          project.process[i].completeAt = Date.now();
          setProcessComplete(userInfo, rootCategory, projectId, i);
        }
      }

      if (project.process[i].complete) {
        continue;
      } else {
        const data: INotifyInfo = {
          key: '',
          classCode: project.process[i].class,
          rootCategory: rootCategory,
          projectTitle: project.title,
          processTitle: project.process[i].title,
          projectId: project.key,
          processId: i,
          receiveAt: Date.now()
        };

        if (project.process[i].class === 'delivery') {
          const delivery = project.process[i].delivery;
          if (delivery) {
            for (let j = 0; j < delivery.recipient.length; j++) {
              setProcessNotify(delivery.recipient[j], data);
            }
          }
        } else if (project.process[i].class === 'approval') {
          const approval = project.process[i].approval;
          if (approval) {
            for (let j = 0; j < approval.approver.length; j++) {
              setProcessNotify(approval.approver[j], data);
            }
          }
        } else if (project.process[i].class === 'request') {
          const request = project.process[i].request;
          if (request) {
            for (let j = 0; j < request.length; j++) {
              const sender = request[j].sender;
              if (sender) {
                setProcessNotify(sender, data);
              }
            }
          }
        }
        projectComplete = false;
        break;
      }
    }

    if (projectComplete) {
      setProjectComplete(userInfo, rootCategory, projectId);
    }
  }
};

const addDays = (date: number, days: number): number => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate.valueOf();
};

export const dbAddProject = async (
  userInfo: IUserInfo,
  project: IProjectTempleate
) => {
  const classId = getProjectClassId(userInfo, project.rootCategory);
  const db = getDatabase(firebaseApp);
  const path = classId + '_JOB_' + project.rootCategory;
  const projectId = dbPush(dbChild(dbRef(db), path)).key;
  if (projectId) {
    const ref = dbRef(db, path + '/' + projectId);

    await uploadFiles(project);

    project.key = projectId;
    project.createAt = Date.now();
    project.modifyAt = project.createAt;
    project.timeFrame = getTotalTimeFrame(project);
    project.endAt = addDays(project.createAt, project.timeFrame);

    dbSet(ref, project);

    if (project.rootCategory === CATEGORY_PROJECT) {
      await setProjectUserList(project);
      regsiterNotify(userInfo, project.rootCategory, projectId);
    }
  }
};

export const dbModifyProject = async (
  userInfo: IUserInfo,
  projectId: string,
  project: IProjectTempleate
) => {
  if (projectId) {
    const classId = getProjectClassId(userInfo, project.rootCategory);
    const db = getDatabase(firebaseApp);
    const path = classId + '_JOB_' + project.rootCategory;
    const ref = dbRef(db, path + '/' + projectId);

    await uploadFiles(project);

    project.modifyAt = Date.now();
    project.timeFrame = getTotalTimeFrame(project);
    if (!project.createAt) {
      project.createAt = project.modifyAt;
    }
    project.endAt = addDays(project.createAt, project.timeFrame);

    dbSet(ref, project);

    if (project.rootCategory === CATEGORY_PROJECT) {
      await setProjectUserList(project);
      regsiterNotify(userInfo, project.rootCategory, projectId);
    }
  }
};

export const dbRemoveProject = (
  userInfo: IUserInfo,
  project: IProjectTempleate
) => {
  if (project.key) {
    deleteProjectFiles(project.key);

    const classId = getProjectClassId(userInfo, project.rootCategory);
    const db = getDatabase(firebaseApp);
    const path = classId + '_JOB_' + project.rootCategory;
    const ref = dbRef(db, path + '/' + project.key);
    dbRemove(ref);
  }
};

export const dbProjectInfo = async (
  userInfo: IUserInfo,
  rootCategory: string,
  projectId: string
): Promise<IProjectTempleate> => {
  let project: IProjectTempleate = {
    key: '',
    rootCategory: '',
    category: '',
    title: '',
    presideTeam: '',
    president: '',
    period: 0,
    description: '',
    manager: '',
    referrer: [],
    process: []
  };
  const classId = getProjectClassId(userInfo, rootCategory);
  const db = getDatabase(firebaseApp);
  const snapshot = await dbGet(
    dbQuery(dbRef(db, classId + '_JOB_' + rootCategory + '/' + projectId))
  );
  if (snapshot.exists()) {
    project = snapshot.val();
    project.key = snapshot.key as string;
    // project.timeFrame = getTotalTimeFrame(project);
    project.progress = getProjectProgress(project);
  }
  return project;
};

export const dbProcessInfo = async (
  userInfo: IUserInfo,
  rootCategory: string,
  projectId: string,
  processIndex: number
): Promise<IProcess> => {
  let process: IProcess = {
    id: '',
    class: 'delivery',
    title: '',
    content: '',
    timeFrame: 0
  };
  const classId = getProjectClassId(userInfo, rootCategory);
  const db = getDatabase(firebaseApp);
  const snapshot = await dbGet(
    dbQuery(
      dbRef(
        db,
        classId +
          '_JOB_' +
          rootCategory +
          '/' +
          projectId +
          '/process/' +
          processIndex
      )
    )
  );
  if (snapshot.exists()) {
    process = snapshot.val();
  }
  return process;
};

export interface IUserProjectId {
  key: string;
  projectId: string;
}

export const dbIsUserProjectId = async (
  userId: string,
  projectId: string
): Promise<boolean> => {
  let ret = false;

  const userProjectId = await dbGetUserProjectId(userId, 0);
  if (userProjectId.find(v => v.projectId === projectId)) {
    ret = true;
  }

  return ret;
};

export const dbAddUserProjectId = async (userId: string, projectId: string) => {
  if (userId && projectId.length > 0) {
    const db = getDatabase(firebaseApp);
    const path = userId + '_JOB_PRJ';

    const data = await dbIsUserProjectId(userId, projectId);
    if (!data) {
      const key = dbPush(dbChild(dbRef(db), path)).key;
      if (key) {
        const ref = dbRef(db, path + '/' + key);
        dbSet(ref, {
          projectId: projectId
        });
      }
    }
  }
};

export const dbGetUserProjectId = async (
  userId: string,
  limit: number,
  endAtKey?: string,
  next?: boolean
): Promise<IUserProjectId[]> => {
  const projectId: IUserProjectId[] = [];

  const db = getDatabase(firebaseApp);
  let q;
  if (limit > 0 && endAtKey) {
    q = dbQuery(
      dbRef(db, userId + '_JOB_PRJ'),
      next ? dbEndAt(null, endAtKey) : dbStartAt(null, endAtKey),
      next ? dbLimitToLast(limit) : dbLimitToFirst(limit)
    );
  } else if (limit > 0) {
    q = dbQuery(dbRef(db, userId + '_JOB_PRJ'), dbLimitToLast(limit));
  } else {
    q = dbQuery(dbRef(db, userId + '_JOB_PRJ'));
  }

  const snapshot = await dbGet(q);

  if (snapshot.exists()) {
    snapshot.forEach(data => {
      // projectId.push(data.val());
      projectId.unshift(data.val());
      // const last = projectId.at(-1);
      const last = projectId.at(0);
      if (last) {
        last.key = data.key as string;
      }
    });
  }
  return projectId;
};

export const dbRemoveUserProjectId = (userId: string, key: string) => {
  if (userId && key) {
    const db = getDatabase(firebaseApp);
    const ref = dbRef(db, userId + '_JOB_PRJ/' + key);
    dbRemove(ref);
  }
};

// ====================================================================================================
// category
// ====================================================================================================
export const CATEGORY_STANDARD = 'STD';
export const CATEGORY_PROJECT = 'PRJ';
export const CATEGORY_PRIVATE = 'PRI';

export interface ICategory {
  root: string;
  key: string;
  title: string;
}

export interface ICategoryInfo extends ICategory {
  children: ICategoryInfo[];
}

export const dbSetCategory = (companyUuid: string, data: ICategoryInfo[]) => {
  const db = getDatabase(firebaseApp);
  const ref = dbRef(db, companyUuid + '_categoryInfo');
  dbSet(ref, data);
};

export const dbGetCategory = async (
  companyUuid: string
): Promise<ICategoryInfo[]> => {
  let categoryInfo: ICategoryInfo[] = [];
  const db = getDatabase(firebaseApp);
  const snapshot = await dbGet(
    dbQuery(dbRef(db, companyUuid + '_categoryInfo'))
  );
  if (snapshot.exists()) {
    categoryInfo = snapshot.val();
  } else {
    categoryInfo = [
      {
        root: CATEGORY_STANDARD,
        title: '표준 업무',
        key: '1',
        children: []
      },
      {
        root: CATEGORY_PROJECT,
        title: '프로젝트 업무',
        key: '2',
        children: []
      },
      {
        root: CATEGORY_PRIVATE,
        title: '개인 업무',
        key: '3',
        children: []
      }
    ];
  }
  return categoryInfo;
};

export const getCategoryInfo = (
  data: ICategoryInfo[],
  key: string
): ICategoryInfo | void => {
  let retItem;
  data.some((item: ICategoryInfo) => {
    if (item.key === key) {
      // console.log('find item', item);
      retItem = item;
      return true;
    } else if (item.children) {
      retItem = getCategoryInfo(item.children, key);
      if (retItem) {
        return true;
      }
    }
  });
  return retItem;
};
