import {
  faChevronLeft,
  faChevronRight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import classNames from 'classnames';
import { ColumnDef, ColumnFiltersState } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { useEffect, useState, ChangeEvent, KeyboardEvent } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useAppContext } from 'providers/AppProvider';
import {
  getProjectNotify,
  CATEGORY_STANDARD,
  CATEGORY_PROJECT,
  CATEGORY_PRIVATE,
  getProcessCheck
} from 'lib/firebase';
import { INotifyInfo, getClassName } from 'interfaces';
import dayjs from 'dayjs';
import SearchBox from 'components/common/SearchBox';

const getCategoryName = (rootCategory: string): string => {
  let title = '업무';

  switch (rootCategory) {
    case CATEGORY_STANDARD:
      title = '표준 업무';
      break;

    case CATEGORY_PROJECT:
      title = '프로젝트 업무';
      break;

    case CATEGORY_PRIVATE:
      title = '개인 업무';
      break;
  }
  return title;
};

interface INotifyInfo2 extends INotifyInfo {
  checkedAt: number;
}

const columns: ColumnDef<INotifyInfo2>[] = [
  {
    header: '분류',
    accessorKey: 'classCode',
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-900 white-space-nowrap' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    },
    cell: ({ row: { original } }) => {
      const { classCode } = original;
      return getClassName(classCode);
    }
  },
  {
    header: '카테고리',
    accessorKey: 'rootCategory',
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-900 white-space-nowrap' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    },
    cell: ({ row: { original } }) => {
      const { rootCategory } = original;
      return getCategoryName(rootCategory);
    }
  },
  {
    header: '프로젝트명',
    accessorKey: 'projectTitle',
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-900 white-space-nowrap' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    }
  },
  {
    accessorKey: 'processTitle',
    header: '업무명',
    meta: {
      cellProps: { className: 'white-space-nowrap' },
      headerProps: { style: { width: '30%' } }
    }
  },
  {
    header: '수신 시간',
    accessorKey: 'receiveAt',
    meta: {
      cellProps: { className: 'ps-3 fs-9 text-900 white-space-nowrap' },
      headerProps: { style: { width: '10%' }, className: 'ps-3' }
    },
    cell: ({ row: { original } }) => {
      const { receiveAt } = original;
      return dayjs(receiveAt).format('YYYY-MM-DD HH:mm:ss');
    }
  },
  {
    header: '확인',
    meta: {
      cellProps: { className: 'white-space-nowrap' },
      headerProps: { style: { width: '10%' } }
    },
    cell: ({ row: { original } }) => {
      const { checkedAt } = original;
      return checkedAt != 0
        ? dayjs(checkedAt).format('YYYY-MM-DD HH:mm:ss')
        : '-';
    }
  }
];

const NotyfyList = () => {
  const { userInfo } = useAppContext();
  const [notifyData, setNotifyData] = useState<INotifyInfo2[]>([]);
  const [firstDataKey, setFirstDataKey] = useState<string>();
  const [lastDataKey, setLastDataKey] = useState<string>();
  const table = useAdvanceTable({
    data: notifyData,
    columns
    // pageSize: 6,
    // pagination: false,
    // sortable: false
  });
  const [pageNo, setPageNo] = useState(0);
  const [isNext, setIsNext] = useState(true);
  const pageCount = 10;
  const dataCount = pageCount + 1;

  const updateProjectList = async (first: boolean, next: boolean) => {
    if (userInfo) {
      let lastKey = next ? lastDataKey : firstDataKey;

      if (first) {
        lastKey = '';
      }

      const data = await getProjectNotify(
        userInfo.uid,
        dataCount,
        lastKey,
        next
      );

      setIsNext(data.length >= dataCount ? true : false);

      const firstData = data.at(0);
      const lastData = data.at(-1);
      if (firstData) {
        setFirstDataKey(firstData.key);
      }
      if (lastData) {
        setLastDataKey(lastData.key);
      }

      const notifies: INotifyInfo2[] = [];
      for (let i = 0; i < data.length - 1; i++) {
        const check = await getProcessCheck(
          userInfo,
          data[i].projectId,
          data[i].processId
        );

        let checkedAt = check.at(0)?.checkedAt;
        if (!checkedAt) {
          checkedAt = 0;
        }

        notifies.push({
          key: data[i].key,
          classCode: data[i].classCode,
          rootCategory: data[i].rootCategory,
          projectTitle: data[i].projectTitle,
          processTitle: data[i].processTitle,
          projectId: data[i].projectId,
          processId: data[i].processId,
          receiveAt: data[i].receiveAt,
          checkedAt: checkedAt
        });
      }
      setNotifyData(notifies);
      table.setPageSize(notifies.length);
    }
  };

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const filters: ColumnFiltersState = [
      {
        id: 'processTitle',
        value: e.target.value
      }
    ];
    table.setColumnFilters(filters);
    // table.setGlobalFilter(e.target.value || undefined);
  };

  useEffect(() => {
    updateProjectList(true, false);
  }, [userInfo]);

  return (
    <div>
      <AdvanceTableProvider {...table}>
        {/* <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 align-items-center">
        </div> */}
        <Row className="align-items-center justify-content-between g-3 mb-6">
          <Col md="auto">
            <h2 className="mb-0">
              <span className="me-3">업무 알림</span>{' '}
              {/* <span className="fw-normal text-700">({projectList.length})</span> */}
            </h2>
          </Col>
          <Col md="auto">
            <SearchBox
              placeholder="Search"
              onChange={handleSearchInputChange}
              onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                if (e.code === 'Enter') {
                  e.preventDefault();
                }
              }}
            />
          </Col>
        </Row>
        <AdvanceTable
          tableProps={{ className: 'phoenix-table border-top border-200 fs-9' }}
          rowClassName="hover-bg-100"
        />
        {/* <AdvanceTableFooter pagination /> */}
        <Row className={'align-items-center py-1'}>
          <Col></Col>
          <Col xs="auto" className="d-flex gap-2">
            <Button
              variant="link"
              startIcon={
                <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
              }
              className={classNames('px-1', {
                disabled: pageNo === 0
              })}
              onClick={() => {
                let page = pageNo - 1;
                if (page < 0) {
                  page = 0;
                }
                setPageNo(page);
                updateProjectList(false, false);
              }}
            >
              이전
            </Button>
            <div className={'py-2'}>{' ' + (pageNo + 1) + ' '}</div>
            <Button
              variant="link"
              endIcon={
                <FontAwesomeIcon icon={faChevronRight} className="ms-2" />
              }
              className={classNames('px-1', {
                disabled: !isNext
              })}
              onClick={() => {
                let page = pageNo + 1;
                if (page > pageCount) {
                  page = pageCount;
                }
                setPageNo(page);
                updateProjectList(false, true);
              }}
            >
              다음
            </Button>
          </Col>
        </Row>
      </AdvanceTableProvider>
    </div>
  );
};

export default NotyfyList;
