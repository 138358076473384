// import SearchBox from 'components/common/SearchBox';
// import useAdvanceTable from 'hooks/useAdvanceTable';
// import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import {
  ChangeEvent,
  useEffect,
  useState,
  useRef,
  createContext,
  useContext
} from 'react';
import Button from 'components/base/Button';
import { Col, Row, Form, Modal } from 'react-bootstrap';
// import TeamManagerTable, {
//   teamManagerTablecolumns
// } from 'components/tables/TeamManagerTable';
import { deleteTeam, setTeam, getTeams, ITeamInfo } from 'lib/firebase';
import { useAppContext } from 'providers/AppProvider';
import PhoenixLoader from 'components/common/PhoenixLoader';
import PhoenixDocCard from 'components/base/PhoenixDocCard';
import SingleMsg from 'pages/pages/SingleMsg';
import { Chart } from 'react-google-charts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

interface TeamManagerContextInterFace {
  editTeamInfo: (code: string) => void;
  deleteTeamInfo: (code: string) => void;
}

export const TeamManagerContext = createContext(
  {} as TeamManagerContextInterFace
);

const chartOptions = {
  allowHtml: true,
  allowCollapse: false
};

// interface MemberManagerContextInterFace {
//   openEditModal: boolean;
//   openEditModel: (index: number) => void;
// }

const TeamManager = () => {
  const { userInfo, teamList, setTeamList, admin } = useAppContext();
  const [loading, setLoading] = useState(true);
  const [treeList, setTreeList] = useState<ITeamInfo[]>([]);

  const [inputs, setInputs] = useState({
    parentId: '',
    code: '',
    name: '',
    phone: '',
    addTeamCode: ''
  });
  const [addTeamCode, setAddTeamCode] = useState('');
  const selectedItem = useRef('');
  const selectedItemName = useRef('');
  const [openModal, setOpenModal] = useState(false);
  const [chartSelChange, setChartSelChange] = useState('');

  // const table = useAdvanceTable({
  //   data: teamList ? teamList : [],
  //   columns: teamManagerTablecolumns,
  //   pageSize: 10,
  //   pagination: true,
  //   sortable: true,
  //   selection: false
  // });

  // const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   table.setGlobalFilter(e.target.value || undefined);
  // };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleAddChild = () => {
    setOpenModal(false);
    onClickAddChild();
  };

  const editTeamInfo = (code: string) => {
    if (teamList) {
      // console.log('editTeamInfo');
      // console.log(teamList);
      // console.log(treeList);
      const data = teamList.find(v => v.code === code);
      if (data) {
        selectedItem.current = data.code;
        selectedItemName.current = data.name;
        setInputs({
          ...inputs,
          parentId: data.parentId,
          code: data.code,
          name: data.name,
          phone: data.phone
        });
      } else {
        selectedItem.current = userInfo ? userInfo.companyUuid : '';
        selectedItemName.current = userInfo ? userInfo.companyName : '';
        setInputs({
          ...inputs,
          parentId: '',
          code: '',
          name: '',
          phone: ''
        });
      }
    }
  };

  const deleteTeamInfo = (code: string) => {
    if (userInfo && userInfo.companyUuid && teamList) {
      const newTeamList = teamList.filter(team => team.code !== code);
      setTeamList(newTeamList);
      deleteTeam(userInfo.companyUuid, code);
      selectedItem.current = userInfo.companyUuid;
      selectedItemName.current = userInfo.companyName;
    }
  };

  const onClinkModify = () => {
    if (!inputs.code) {
      alert('팀 코드를 입력하세요');
      return;
    }

    if (!inputs.name) {
      alert('팀 이름을 입력하세요');
      return;
    }

    if (teamList && userInfo && userInfo.companyUuid) {
      const newTeamList = Array.from(teamList);
      const data = newTeamList.find(v => v.code === inputs.code);
      if (data) {
        data.parentId = inputs.parentId;
        data.code = inputs.code;
        data.name = inputs.name;
        data.phone = inputs.phone;
        setTeamList(newTeamList);
        setTeam(userInfo.companyUuid, data);

        selectedItemName.current = inputs.name;
      } else {
        alert('수정 할 부서를 선택하세요.');
      }
    }
  };

  const onClickDelete = () => {
    if (teamList && userInfo && userInfo.companyUuid) {
      const newTeamList = Array.from(teamList);
      const data = newTeamList.find(v => v.code === inputs.code);
      const childData = newTeamList.find(v => v.parentId === inputs.code);

      if (childData) {
        alert('하위 부서를 먼저 삭제하세요.');
      } else {
        if (data) {
          deleteTeamInfo(inputs.code);
        } else {
          alert('삭제 할 부서를 선택하세요.');
        }
      }
    }
  };

  const onClickAddChild = () => {
    if (!selectedItem.current) {
      alert('추가 할 상위부서를 선택하세요.');
      return;
    }

    if (teamList && userInfo && userInfo.companyUuid) {
      const data = teamList.find(v => v.code === addTeamCode);
      if (data) {
        alert('부서 코드가 중복되어 추가 할 수 없습니다.');
      } else {
        const newTeam = {
          parentId: selectedItem.current,
          code: addTeamCode,
          name: addTeamCode,
          phone: ''
        };
        teamList.push(newTeam);
        setTeamList([...teamList]);
        console.log('onClickAddChild');
        console.log(teamList);
        setTeam(userInfo.companyUuid, newTeam);
      }
    }
  };

  useEffect(() => {
    if (userInfo && userInfo.companyUuid) {
      getTeams(userInfo.companyUuid).then(ret => {
        setTeamList(ret);

        selectedItem.current = userInfo.companyUuid;
        selectedItemName.current = userInfo.companyName;
        setLoading(false);
      });
    }
  }, [userInfo]);

  const updateTreeData = () => {
    const tree: ITeamInfo[] = [];

    if (userInfo && userInfo.companyUuid) {
      tree.push({
        parentId: '',
        code: 'top',
        name: 'top',
        phone: ''
      });
      tree.push({
        parentId: '',
        code: userInfo.companyUuid,
        name: userInfo.companyName,
        phone: ''
      });

      const newTeamList = Array.from(teamList);
      newTeamList.forEach(n => {
        tree.push({
          parentId: n.parentId,
          code: n.code,
          name: n.name,
          phone: n.phone
        });
      });
    }
    setTreeList(tree);
  };

  useEffect(() => {
    updateTreeData();
  }, [teamList]);

  useEffect(() => {
    editTeamInfo(chartSelChange);
  }, [chartSelChange]);

  const treeData = treeList.map(item => [
    { v: item.code, f: item.name },
    item.parentId,
    ''
  ]);

  if (!admin) {
    return <SingleMsg msg="관리자만 사용 가능합니다." />;
  }

  if (loading) return <PhoenixLoader />;
  return (
    <TeamManagerContext.Provider value={{ editTeamInfo, deleteTeamInfo }}>
      <div>
        <div className="mb-9">
          <h2 className="mb-5">부서 관리</h2>

          <Row>
            <Col xs={12} xxl={6}>
              <PhoenixDocCard className="mb-4">
                <PhoenixDocCard.Header
                  title={'선택 부서 : ' + selectedItemName.current}
                  noPreview
                />
                <PhoenixDocCard.Body>
                  {/* <AdvanceTableProvider {...table}>
                    <div className="mb-4">
                      <Row className="g-3">
                        <Col xs="auto">
                          <SearchBox
                            placeholder="Search team"
                            onChange={handleSearchInputChange}
                          />
                        </Col>
                        <Col
                          xs="auto"
                          className="scrollbar overflow-hidden-y flex-grow-1"
                        ></Col>
                      </Row>
                    </div>

                    <div className="px-4 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
                      <TeamManagerTable />
                    </div>
                  </AdvanceTableProvider> */}
                  <div className="mt-15">
                    <Chart
                      chartType="OrgChart"
                      data={treeData}
                      options={chartOptions}
                      chartEvents={[
                        {
                          eventName: 'select',
                          callback: ({ chartWrapper }) => {
                            const sel = chartWrapper.getChart().getSelection();
                            const data = chartWrapper.getDataTable();
                            if (sel.length > 0) {
                              const selTeamCode = data
                                ?.getValue(sel[0].row, 0)
                                ?.toString();
                              if (selTeamCode) {
                                // editTeamInfo(selTeamCode);
                                setChartSelChange(selTeamCode);
                                // console.log('selected item = ' + selTeamCode);
                              }
                            }
                          }
                        }
                      ]}
                      width="100%"
                      height="400px"
                    />
                  </div>
                </PhoenixDocCard.Body>
              </PhoenixDocCard>
            </Col>
            <Col xs={12} xxl={6}>
              <PhoenixDocCard className="mb-4">
                <PhoenixDocCard.Body>
                  <div>
                    <Form>
                      <Row className="mb-2">
                        <Form.Group className="mb-3 text-start">
                          <Form.Label htmlFor="name">부서 코드</Form.Label>
                          <Form.Control
                            id="code"
                            type="text"
                            placeholder="부서 코드"
                            value={inputs.code}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              setInputs({
                                ...inputs,
                                [e.target.id]: e.target.value
                              });
                            }}
                            readOnly
                          />
                        </Form.Group>

                        <Form.Group className="mb-3 text-start">
                          <Form.Label htmlFor="name">부서 이름</Form.Label>
                          <Form.Control
                            id="name"
                            type="text"
                            placeholder="부서 이름"
                            value={inputs.name}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              setInputs({
                                ...inputs,
                                [e.target.id]: e.target.value
                              });
                            }}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3 text-start">
                          <Form.Label htmlFor="name">전화 번호</Form.Label>
                          <Form.Control
                            id="phone"
                            type="text"
                            placeholder="전화 번호"
                            value={inputs.phone}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              setInputs({
                                ...inputs,
                                [e.target.id]: e.target.value
                              });
                            }}
                          />
                        </Form.Group>
                      </Row>

                      <Row className="mt-5 mb-5">
                        <Col>
                          <Button
                            variant="primary"
                            // type="submit"
                            className="w-100 mb-3"
                            onClick={onClinkModify}
                          >
                            수정
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            variant="primary"
                            className="w-100 mb-3"
                            onClick={onClickDelete}
                          >
                            삭제
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            variant="primary"
                            className="w-100 mb-3"
                            onClick={() => {
                              setOpenModal(true);
                            }}
                          >
                            하위 부서 추가
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </PhoenixDocCard.Body>
              </PhoenixDocCard>
            </Col>
          </Row>
        </div>

        <Modal
          size="sm"
          show={openModal}
          centered
          backdrop="static"
          onHide={handleModalClose}
          contentClassName="bg-100 p-6 border"
        >
          <Modal.Header className="border-0 p-0 mb-2">
            <h3 className="mb-0">하위 부서 추가</h3>
            <Button
              variant="phoenix-secondary"
              onClick={handleModalClose}
              size="sm"
            >
              <FontAwesomeIcon icon={faTimes} className="text-danger" />
            </Button>
          </Modal.Header>
          <Modal.Body className="px-0 mb-6">
            <Row className="g-4">
              <Col className="d-flex flex-column gap-4">
                <Form.Group>
                  <Form.Label className="form-label-header mb-2">
                    부서 코드
                  </Form.Label>
                  <Form.Control
                    id="new-code"
                    type="text"
                    placeholder="부서 코드"
                    value={addTeamCode}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setAddTeamCode(e.target.value);
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="border-0 p-0">
            <Button
              variant="link"
              className="text-danger px-3 my-0"
              onClick={handleModalClose}
            >
              취소
            </Button>
            <Button variant="primary" className="my-0" onClick={handleAddChild}>
              추가
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </TeamManagerContext.Provider>
  );
};

export const useTeamManagerContext = () => useContext(TeamManagerContext);
export default TeamManager;
