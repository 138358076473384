import { ColumnDef } from '@tanstack/react-table';
import AdvanceTable from 'components/base/AdvanceTable';
import AdvanceTableFooter from 'components/base/AdvanceTableFooter';
import RevealDropdown, {
  RevealDropdownTrigger
} from 'components/base/RevealDropdown';
import { useRankManagerContext } from 'pages/pages/RankManager';
import { Dropdown } from 'react-bootstrap';
import { TRankInfo } from 'lib/firebase';

export const rankManagerTablecolumns: ColumnDef<TRankInfo>[] = [
  {
    accessorKey: 'code',
    header: '코드',
    cell: ({ row: { original } }) => {
      const { code } = original;
      return (
        <div>
          <p className="mb-0 ms-3 text-1100 fw-semi-bold">{code}</p>
        </div>
      );
    },
    meta: {
      headerProps: {
        //style: { width: '20%' },
        className: 'px-1'
      },
      cellProps: { className: 'align-middle white-space-nowrap py-2' }
    }
  },
  {
    accessorKey: 'name',
    header: '직급',
    meta: {
      //headerProps: { style: { width: '10%' } },
      cellProps: { className: 'align-middle' }
    }
  },
  {
    id: 'action',
    cell: ({ row: { original } }) => {
      const { code } = original;
      const { editRankInfo, deleteRankInfo } = useRankManagerContext();

      return (
        <RevealDropdownTrigger>
          <RevealDropdown>
            <Dropdown.Item onClick={() => editRankInfo(code)}>
              Edit
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => deleteRankInfo(code)}
              className="text-danger"
            >
              Remove
            </Dropdown.Item>
          </RevealDropdown>
        </RevealDropdownTrigger>
      );
    },
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end' },
      cellProps: { className: 'text-end' }
    }
  }
];

const RankManagerTable = () => {
  return (
    <div>
      <AdvanceTable
        tableProps={{ className: 'phoenix-table fs-9' }}
        rowClassName="hover-bg-100 position-static"
      />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default RankManagerTable;
