import {
  ChangeEvent,
  useEffect,
  useState,
  useRef,
  createContext,
  useContext
} from 'react';
import { Col, Row, Form, Table, Modal, Stack } from 'react-bootstrap';
import Button from 'components/base/Button';
import PhoenixDocCard from 'components/base/PhoenixDocCard';
import { ReactSortable } from 'react-sortablejs';
import {
  IUserInfo,
  dbAddProject,
  getUserList,
  dbProjectInfo,
  getTeamUserInfo,
  dbModifyProject,
  dbRemoveProject,
  ICategory,
  CATEGORY_STANDARD,
  CATEGORY_PROJECT,
  CATEGORY_PRIVATE,
  getCategoryInfo
} from 'lib/firebase';
import { useAppContext } from 'providers/AppProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faClose } from '@fortawesome/free-solid-svg-icons';
import FeatherIcon from 'feather-icons-react';
import {
  IProjectTempleate,
  IProcess,
  TYPE_PERIOD,
  ITeamUserTable,
  getClassName
} from 'interfaces';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ProjectDelivery from 'components/modules/project-management/ProjectDelivery';
import ProjectApproval from 'components/modules/project-management/ProjectApproval';
import ProjectRequest from 'components/modules/project-management/ProjectRequest';
import SelectionTeamUser from 'components/modules/project-management/SelectionTeamUser';
import SelectionCategory from './SelectionCategory';
import PhoenixLoader from 'components/common/PhoenixLoader';

interface IMemberManagerContext {
  setTitle: (title: string) => void;
}

export const ProjectCreateContext = createContext({} as IMemberManagerContext);

const ProjectCreate = () => {
  const { projectId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { userInfo, teamList, userList, categoryList } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [modifyMode, setModifyMode] = useState(false);
  const [project, setProject] = useState<IProjectTempleate>();
  const [procList, setProcList] = useState<IProcess[]>([]);
  const [title, setTitle] = useState('');
  const [period, setPeriod] = useState(0);
  const [category, setCategory] = useState<ICategory>({
    root: CATEGORY_STANDARD,
    title: '표준 업무',
    key: '1'
  });
  const inputsRef = useRef({
    title: '',
    referTeam: '',
    referTeamId: '',
    referrer: '',
    referrerId: '',
    description: ''
  });
  const [selectedReferrer, setSelectedReferrer] = useState(-1);
  const [referTable, setReferTable] = useState<ITeamUserTable[]>([]);
  const [referrerList, setReferrerList] = useState<IUserInfo[]>([]);
  const [procIndex, setProcIndex] = useState(-1);

  const [openDelivery, setOpenDelivery] = useState(false);
  const [openApproval, setOpenApproval] = useState(false);
  const [openRequest, setOpenRequest] = useState(false);
  const [openSelectCategory, setOpenSelectCategory] = useState(false);

  const [openSelectManager, setOpenSelectManager] = useState(false);
  const [selectedManager, setSelectedManager] = useState<ITeamUserTable>({
    team: '',
    teamId: '',
    user: '',
    userId: ''
  });

  const [openSelectPresident, setOpenSelectPresident] = useState(false);
  const [selectedPresident, setSelectedPresident] = useState<ITeamUserTable>({
    team: '',
    teamId: '',
    user: '',
    userId: ''
  });

  const getTitleName = (rootCategory: string): string => {
    let title = '업무';

    switch (rootCategory) {
      case CATEGORY_STANDARD:
        title = '표준 업무';
        break;

      case CATEGORY_PROJECT:
        title = '프로젝트 업무';
        break;

      case CATEGORY_PRIVATE:
        title = '개인 업무';
        break;
    }
    return title;
  };

  const onAddReferrerTable = () => {
    if (!inputsRef.current.referTeamId) {
      alert('업무참조 부서를 선택하세요');
      return;
    }

    if (!inputsRef.current.referrerId) {
      alert('업무참조 직원을 선택하세요');
      return;
    }

    if (referTable.find(v => v.userId === inputsRef.current.referrerId)) {
      alert('이미 업무참조에 포함되어 있습니다');
      return;
    }

    referTable.push({
      team: inputsRef.current.referTeam,
      teamId: inputsRef.current.referTeamId,
      user: inputsRef.current.referrer,
      userId: inputsRef.current.referrerId
    });
    setReferTable([...referTable]);
  };

  const onClickAddProject = async () => {
    if (userInfo && userInfo.companyUuid) {
      const newProject: IProjectTempleate = {
        rootCategory: category.root,
        category: category.key,
        title: inputsRef.current.title,
        presideTeam: selectedPresident.teamId,
        president: selectedPresident.userId,
        period: period,
        description: inputsRef.current.description,
        manager: selectedManager.userId,
        referrer: [],
        process: procList
      };
      referTable.map(v => newProject.referrer.push(v.userId));

      setLoading(true);
      if (modifyMode) {
        if (projectId) {
          newProject.key = project?.key;
          newProject.createAt = project?.createAt;
          await dbModifyProject(userInfo, projectId, newProject);
        }
      } else {
        await dbAddProject(userInfo, newProject);
      }
      setLoading(false);
      workEnd();
    }
  };

  const onClickDelete = () => {
    if (window.confirm('삭제 하시겠습니까?')) {
      if (userInfo && project?.key) {
        dbRemoveProject(userInfo, project);
        workEnd();
      }
    }
  };

  const workEnd = () => {
    switch (category.root) {
      case CATEGORY_STANDARD:
        navigate('/standard-project-list');
        break;

      // case CATEGORY_PROJECT:
      //   break;

      // case CATEGORY_PRIVATE:
      //   break;

      default:
        navigate('/');
        break;
    }
  };

  const openProcModify = (item: IProcess, index: number) => {
    setProcIndex(index);

    switch (item.class) {
      case 'delivery':
        setOpenDelivery(true);
        break;

      case 'approval':
        setOpenApproval(true);
        break;

      case 'request':
        setOpenRequest(true);
        break;
    }
  };

  const changeNewMode = () => {
    if (
      window.confirm('첨부파일은 다시 등록하여야 합니다.\n계속 하시겠습니까?')
    ) {
      if (project?.key) {
        project.key = '';

        for (let i = 0; i < project.process.length; i++) {
          if (project.process[i].class === 'delivery') {
            const delivery = project.process[i].delivery;
            if (delivery && delivery.attachment) {
              delivery.attachment.splice(0);
            }
          } else if (project.process[i].class === 'approval') {
            const approval = project.process[i].approval;
            if (approval) {
              if (approval.formFile) {
                approval.formFile.splice(0);
              }
              if (approval.attachment) {
                approval.attachment.splice(0);
              }
            }
          } else if (project.process[i].class === 'request') {
            const request = project.process[i].request;
            if (request) {
              for (let j = 0; j < request.length; j++) {
                const attachment = request[j].attachment;
                if (attachment) {
                  attachment.splice(0);
                }
              }
            }
          }
        }
      }
      setModifyMode(false);
    }
  };

  useEffect(() => {
    const selUserList = getUserList(userList, '');
    setReferrerList(selUserList);
  }, [userList]);

  useEffect(() => {
    if (projectId && userInfo && userInfo.companyUuid) {
      if (projectId === 'new') {
        const newProject: IProjectTempleate = {
          rootCategory: category.root,
          category: category.key,
          title: '',
          presideTeam: '',
          president: '',
          period: 0,
          description: '',
          manager: '',
          referrer: [],
          process: []
        };
        setProject(newProject);
        referTable.splice(0);
        referrerList.splice(0);
        setModifyMode(false);
      } else {
        const category = searchParams.get('category') as string;
        dbProjectInfo(userInfo, category, projectId).then(project => {
          setModifyMode(true);
          setProject(project);
        });
      }
    }
  }, [userInfo, projectId]);

  useEffect(() => {
    if (project) {
      const categorys = getCategoryInfo(categoryList, project.category);
      if (categorys) {
        setCategory({
          root: categorys.root,
          key: categorys.key,
          title: categorys.title
        });
      }

      inputsRef.current.title = project.title;
      setTitle(project.title);

      if (project.manager) {
        const user = getTeamUserInfo(userList, teamList, project.manager);
        setSelectedManager({
          team: user.team,
          teamId: user.teamId,
          user: user.user,
          userId: user.userId
        });
      } else {
        setSelectedManager(old => {
          return {
            team: '',
            teamId: '',
            user: '',
            userId: ''
          };
        });
      }

      if (project.president) {
        const user = getTeamUserInfo(userList, teamList, project.president);
        setSelectedPresident({
          team: user.team,
          teamId: user.teamId,
          user: user.user,
          userId: user.userId
        });
      } else {
        setSelectedPresident(old => {
          return {
            team: '',
            teamId: '',
            user: '',
            userId: ''
          };
        });
      }

      setPeriod(project.period);
      inputsRef.current.description = project.description;

      if (project.referrer) {
        const table: ITeamUserTable[] = [];
        project.referrer.map(value => {
          const user = getTeamUserInfo(userList, teamList, value);
          table.push({
            team: user.team,
            teamId: user.teamId,
            user: user.user,
            userId: user.userId
          });
        });
        setReferTable(table);
      }

      if (project.process) {
        setProcList(project.process);
      }
    }
  }, [project]);

  return (
    <ProjectCreateContext.Provider value={{ setTitle }}>
      <div>
        <div className="mb-9">
          <Row className="g-1 justify-content-between">
            <Col sm={5}>
              <h3 className="mb-5">
                {getTitleName(category.root) + (modifyMode ? ' 수정' : ' 등록')}
              </h3>
            </Col>
          </Row>
          <Row>
            <Col sm={5}>
              <PhoenixDocCard className="mt-1 mb-1">
                <PhoenixDocCard.Header noPreview>
                  <div
                    style={{ height: '25px' }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <h4>{title}</h4>
                  </div>
                </PhoenixDocCard.Header>
                <PhoenixDocCard.Body>
                  <ReactSortable
                    list={procList}
                    setList={setProcList}
                    animation={200}
                    delayOnTouchOnly={true}
                    delay={2}
                    handle=".dragHandle"
                    className="grid-container"
                    style={{ minHeight: '600px' }}
                  >
                    {procList.map((item, index) => (
                      <div
                        className="d-flex bg-100 justify-content-between align-items-center border-bottom border-300"
                        key={index}
                      >
                        <div className="btn btn-white p-3 dragHandle">
                          <FeatherIcon icon="menu" size={14} />
                        </div>
                        <div className="p-2 glex-grow-1 bg-100 border-300">
                          {'[' + getClassName(item.class) + '] ' + item.title}
                        </div>
                        <div className="p-2 glex-grow-1 bg-100 border-300">
                          <Button
                            variant="phoenix-secondary"
                            className="btn-icon fs-10 me-1"
                            onClick={() => {
                              openProcModify(item, index);
                            }}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </Button>
                          <Button
                            variant="phoenix-secondary"
                            className="btn-icon fs-10"
                            onClick={() => {
                              procList.splice(index, 1);
                              setProcList([...procList]);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              className="text-danger"
                            />
                          </Button>
                        </div>
                      </div>
                    ))}
                  </ReactSortable>
                </PhoenixDocCard.Body>
              </PhoenixDocCard>
            </Col>
            <Col sm={7}>
              <PhoenixDocCard className="mt-1 mb-1">
                <PhoenixDocCard.Header title={''} noPreview>
                  <Row>
                    <Col sm={8}>
                      <div
                        className="d-flex align-items-center gap-2"
                        style={{ height: '25px' }}
                      >
                        <Button
                          variant="primary"
                          className="w-30"
                          onClick={onClickAddProject}
                        >
                          {modifyMode ? '수정' : '등록'}
                        </Button>
                        {modifyMode && (
                          <Button
                            variant="phoenix-primary"
                            className="w-30"
                            onClick={changeNewMode}
                          >
                            신규로 전환
                          </Button>
                        )}
                        {modifyMode && (
                          <Button
                            variant="phoenix-danger"
                            className="w-30"
                            onClick={onClickDelete}
                          >
                            삭제
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                </PhoenixDocCard.Header>
                <PhoenixDocCard.Body>
                  <div
                    className="container-small"
                    style={{ minHeight: '600px' }}
                  >
                    <Row>
                      <Row className="g-1">
                        <Form.Label column sm={3}>
                          업무 카테고리
                        </Form.Label>
                        <Col sm={3}>
                          <Form.Control
                            id="category"
                            type="text"
                            placeholder="선택 안함"
                            value={category.title}
                            readOnly
                          />
                        </Col>
                        <Col sm={2}>
                          <Button
                            variant="phoenix-secondary"
                            className="w-100"
                            onClick={() => {
                              setOpenSelectCategory(true);
                            }}
                            disabled={modifyMode}
                          >
                            선택
                          </Button>
                        </Col>
                      </Row>
                      <Row className="g-1">
                        <Form.Label column sm={3}>
                          제목
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            id="title"
                            type="text"
                            placeholder="제목"
                            defaultValue={inputsRef.current.title}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              inputsRef.current.title = e.target.value;
                              setTitle(e.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="g-1">
                        <Form.Label column sm={3}>
                          주관부서
                        </Form.Label>
                        <Col sm={3}>
                          <Form.Control
                            id="president_team"
                            type="text"
                            placeholder="선택 암함"
                            value={selectedPresident.team}
                            readOnly
                          />
                        </Col>
                      </Row>
                      <Row className="g-1">
                        <Form.Label column sm={3}>
                          주관인
                        </Form.Label>
                        <Col sm={3}>
                          <Form.Control
                            id="president_user"
                            type="text"
                            placeholder="선택 안함"
                            value={selectedPresident.user}
                            readOnly
                          />
                        </Col>
                        <Col sm={2}>
                          <Button
                            variant="phoenix-secondary"
                            className="w-100"
                            onClick={() => {
                              setOpenSelectPresident(true);
                            }}
                          >
                            선택
                          </Button>
                        </Col>
                      </Row>
                      <Row className="g-1 align-items-center">
                        <Form.Label column sm={3}>
                          업무주기
                        </Form.Label>
                        <Col md={8}>
                          {TYPE_PERIOD.map((value, index) => (
                            <Form.Check
                              key={index}
                              inline
                              type="radio"
                              id={'radioPeriod_' + index}
                              label={value}
                              name="inline-radio"
                              checked={period === index ? true : false}
                              value={index}
                              onChange={e => {
                                setPeriod(Number(e.target.value));
                              }}
                            />
                          ))}
                        </Col>
                      </Row>
                      <Row className="g-1">
                        <Form.Label column sm={3}>
                          업무 정의 및 범위
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            as="textarea"
                            rows={6}
                            defaultValue={inputsRef.current.description}
                            onChange={e => {
                              inputsRef.current.description = e.target.value;
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="g-1">
                        <Form.Label column sm={3}>
                          진행자
                        </Form.Label>
                        <Col sm={3}>
                          <Form.Control
                            id="manager_team"
                            type="text"
                            placeholder="선택 암함"
                            value={selectedManager.team}
                            readOnly
                          />
                        </Col>
                        <Col sm={3}>
                          <Form.Control
                            id="manager_user"
                            type="text"
                            placeholder="선택 안함"
                            value={selectedManager.user}
                            readOnly
                          />
                        </Col>
                        <Col sm={2}>
                          <Button
                            variant="phoenix-secondary"
                            className="w-100"
                            onClick={() => {
                              setOpenSelectManager(true);
                            }}
                          >
                            선택
                          </Button>
                        </Col>
                      </Row>
                      <Row className="g-1">
                        <Form.Label column sm={3}>
                          업무참조
                          {/* <Button
                            className="px-2 py-0"
                            onClick={onAddReferrerTable}
                          >
                            <FontAwesomeIcon icon={faPlusSquare} />
                          </Button> */}
                        </Form.Label>
                        <Col sm={3}>
                          <Form.Select
                            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                              if (e.target.value) {
                                const referTeam = teamList.at(
                                  Number(e.target.value)
                                );
                                inputsRef.current.referTeam = referTeam
                                  ? referTeam.name
                                  : '';
                                inputsRef.current.referTeamId = referTeam
                                  ? referTeam.code
                                  : '';
                              } else {
                                inputsRef.current.referTeam = '';
                                inputsRef.current.referTeamId = '';
                              }
                              const selUserList = getUserList(
                                userList,
                                inputsRef.current.referTeamId
                              );
                              setReferrerList(selUserList);
                              setSelectedReferrer(-1);
                            }}
                          >
                            <option value={''}>선택</option>
                            {teamList.map((team, index) => (
                              <option key={index} value={index}>
                                {team.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                        <Col sm={3}>
                          <Form.Select
                            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                              const referrer = referrerList.at(
                                Number(e.target.value)
                              );
                              if (referrer) {
                                inputsRef.current.referrerId = referrer.uid;
                                inputsRef.current.referrer = referrer.userName;
                              }
                              setSelectedReferrer(Number(e.target.value));
                            }}
                            value={selectedReferrer}
                          >
                            <option>선택</option>
                            {referrerList.map((user, index) => (
                              <option key={index} value={index}>
                                {user.userName}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                        <Col sm={2}>
                          <Button
                            variant="phoenix-secondary"
                            className="w-100"
                            onClick={onAddReferrerTable}
                          >
                            추가
                          </Button>
                        </Col>
                      </Row>
                      <Row className="g-1">
                        <Col sm={3}></Col>
                        <Col sm={7}>
                          <Table bordered hover size="sm">
                            <thead>
                              <tr>
                                <th className="bg-100 text-center fs-9">
                                  부서
                                </th>
                                <th className="bg-100 text-center fs-9">
                                  참조
                                </th>
                                <td
                                  className="border border-white fs-9"
                                  width="14%"
                                ></td>
                              </tr>
                            </thead>
                            <tbody>
                              {referTable.map((value, index) => (
                                <tr key={index}>
                                  <td className="text-center align-middle fs-9">
                                    {value.team}
                                  </td>
                                  <td className="text-center align-middle fs-9">
                                    {value.user}
                                  </td>
                                  <td
                                    width="14%"
                                    className="border border-white fs-9"
                                  >
                                    <Button
                                      variant="phoenix-secondary"
                                      className="btn-icon fs-9"
                                      onClick={() => {
                                        referTable.splice(index, 1);
                                        setReferTable([...referTable]);
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faClose}
                                        className="text-danger"
                                      />
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                      <Row className="g-1">
                        <Form.Label column sm={3}>
                          업무 추가
                        </Form.Label>
                        <Col sm={8} className="d-flex gap-2">
                          <Button
                            variant="phoenix-primary"
                            className="w-30"
                            onClick={() => {
                              setProcIndex(-1);
                              setOpenDelivery(true);
                            }}
                          >
                            전달
                          </Button>
                          <Button
                            variant="phoenix-secondary"
                            className="w-30"
                            onClick={() => {
                              setProcIndex(-1);
                              setOpenApproval(true);
                            }}
                          >
                            승인
                          </Button>
                          <Button
                            variant="phoenix-success"
                            className="w-30"
                            onClick={() => {
                              setProcIndex(-1);
                              setOpenRequest(true);
                            }}
                          >
                            요청
                          </Button>
                        </Col>
                      </Row>
                      {/* <Row className="g-1">
                        <Form.Label column sm={3}>
                          테스트
                        </Form.Label>
                        <Col sm={8} className="d-flex gap-2">
                          <Button
                            variant="phoenix-primary"
                            className="w-30"
                            onClick={() => {
                              setLoading(true);
                            }}
                          >
                            ON
                          </Button>
                          <Button
                            variant="phoenix-secondary"
                            className="w-30"
                            onClick={() => {
                              setLoading(false);
                            }}
                          >
                            OFF
                          </Button>
                        </Col>
                      </Row> */}
                    </Row>
                  </div>
                </PhoenixDocCard.Body>
              </PhoenixDocCard>
            </Col>
          </Row>
        </div>

        <ProjectDelivery
          procList={procList}
          setProcList={setProcList}
          teamList={teamList}
          userList={userList}
          show={openDelivery}
          setShow={setOpenDelivery}
          procIndex={procIndex}
        />

        <ProjectApproval
          procList={procList}
          setProcList={setProcList}
          teamList={teamList}
          userList={userList}
          show={openApproval}
          setShow={setOpenApproval}
          procIndex={procIndex}
        />

        <ProjectRequest
          procList={procList}
          setProcList={setProcList}
          teamList={teamList}
          userList={userList}
          show={openRequest}
          setShow={setOpenRequest}
          procIndex={procIndex}
        />

        <SelectionTeamUser
          teamList={teamList}
          userList={userList}
          show={openSelectPresident}
          setShow={setOpenSelectPresident}
          setTeamUserInfo={setSelectedPresident}
        />

        <SelectionTeamUser
          teamList={teamList}
          userList={userList}
          show={openSelectManager}
          setShow={setOpenSelectManager}
          setTeamUserInfo={setSelectedManager}
        />

        <SelectionCategory
          show={openSelectCategory}
          setShow={setOpenSelectCategory}
          categoryList={categoryList}
          setCategory={setCategory}
        />

        <Modal
          size="sm"
          show={loading}
          centered
          backdrop="static"
          onHide={() => setLoading(false)}
        >
          <Modal.Body>
            <PhoenixLoader />
          </Modal.Body>
        </Modal>
      </div>
    </ProjectCreateContext.Provider>
  );
};

export const useProjectCreateContext = () => useContext(ProjectCreateContext);
export default ProjectCreate;
