import { getFileExtension } from 'helpers/utils';
import AttachmentPreview from 'components/common/AttachmentPreview';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusSquare,
  faTimes,
  faClose
} from '@fortawesome/free-solid-svg-icons';
import {
  Col,
  Row,
  Form,
  InputGroup,
  FormControl,
  Modal,
  Table
} from 'react-bootstrap';
import Button from 'components/base/Button';
import {
  ChangeEvent,
  useEffect,
  useState,
  Dispatch,
  SetStateAction
} from 'react';
import {
  ITeamInfo,
  IUserInfo,
  getUserList,
  getTeamUserInfo
} from 'lib/firebase';
import { IProcess, IProcApproval, ITeamUserTable, IProcFile } from 'interfaces';
import { fileSizeToString } from 'lib/fbStorage';
import SelectionTeamUser from 'components/modules/project-management/SelectionTeamUser';

const ProjectApproval = ({
  procList,
  setProcList,
  teamList,
  userList,
  show,
  setShow,
  procIndex
}: {
  procList: IProcess[];
  setProcList: Dispatch<SetStateAction<IProcess[]>>;
  teamList: ITeamInfo[];
  userList: IUserInfo[];
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  procIndex: number;
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [approverList, setApproverList] = useState<IUserInfo[]>([]);
  const [selectedApprover, setSelectedApprover] = useState(-1);
  const [approverTable, setApproverTable] = useState<ITeamUserTable[]>([]);
  const [formfiles, setFormfiles] = useState<IProcFile[]>([]);
  const [attachments, setAttachments] = useState<IProcFile[]>([]);
  const [inputMode, setInputMode] = useState({
    index: -1,
    button: '추가'
  });
  const [inputs, setInputs] = useState({
    title: '',
    content: '',
    timeFrame: 0,
    approverTeam: '',
    approverTeamId: '',
    approver: '',
    approverId: ''
  });

  const [openSelectManager, setOpenSelectManager] = useState(false);
  const [selectedManager, setSelectedManager] = useState<ITeamUserTable>({
    team: '',
    teamId: '',
    user: '',
    userId: ''
  });

  const onAddApprovalTable = () => {
    if (!inputs.approverTeamId) {
      alert('업무참조 부서를 선택하세요');
      return;
    }

    if (!inputs.approverId) {
      alert('업무참조 직원을 선택하세요');
      return;
    }

    if (approverTable.find(v => v.userId === inputs.approverId)) {
      alert('이미 수령인에 포함되어 있습니다');
      return;
    }

    approverTable.push({
      team: inputs.approverTeam,
      teamId: inputs.approverTeamId,
      user: inputs.approver,
      userId: inputs.approverId
    });
    setApproverTable([...approverTable]);
  };

  const hCloseModal = () => {
    setShow(false);
    setOpenModal(false);
  };

  const hAddProcess = () => {
    const approvalData: IProcApproval = {
      manager: '',
      approver: [],
      formFile: [],
      attachment: []
    };

    approvalData.manager = selectedManager.userId;
    approverTable.map(value => approvalData.approver.push(value.userId));
    formfiles.map(value => approvalData.formFile.push(value));
    attachments.map(value => approvalData.attachment.push(value));

    if (inputMode.index < 0) {
      procList.push({
        id: '',
        class: 'approval',
        title: inputs.title,
        content: inputs.content,
        timeFrame: inputs.timeFrame,
        approval: JSON.parse(JSON.stringify(approvalData))
      });
    } else {
      const proc = procList.at(inputMode.index);
      if (proc) {
        proc.title = inputs.title;
        proc.content = inputs.content;
        proc.timeFrame = inputs.timeFrame;
        proc.approval = JSON.parse(JSON.stringify(approvalData));
      }
    }
    setProcList([...procList]);
    hCloseModal();
  };

  const openProcModify = () => {
    const proc = procList.at(procIndex);

    if (proc) {
      setInputMode({
        index: procIndex,
        button: '수정'
      });

      if (proc.class === 'approval') {
        setInputs(old => {
          return {
            ...old,
            title: proc.title,
            content: proc.content,
            timeFrame: proc.timeFrame
          };
        });

        if (proc.approval) {
          if (proc.approval.manager) {
            const user = getTeamUserInfo(
              userList,
              teamList,
              proc.approval.manager
            );
            setSelectedManager(user);
          }

          const table: ITeamUserTable[] = [];
          proc.approval.approver.map(value => {
            const user = getTeamUserInfo(userList, teamList, value);
            table.push({
              team: user.team,
              teamId: user.teamId,
              user: user.user,
              userId: user.userId
            });
          });
          setApproverTable(table);

          if (proc.approval.formFile) {
            setFormfiles(proc.approval.formFile);
          } else {
            setFormfiles([]);
          }

          if (proc.approval.attachment) {
            setAttachments(proc.approval.attachment);
          } else {
            setAttachments([]);
          }
        }

        setOpenModal(true);
      }
    }
  };

  const openProcAdd = () => {
    setInputMode({
      index: -1,
      button: '추가'
    });
    setInputs(old => {
      return {
        ...old,
        title: '',
        content: '',
        timeFrame: 0
      };
    });
    setFormfiles([]);
    setAttachments([]);
    setApproverTable([]);
    setSelectedManager(old => {
      return {
        team: '',
        teamId: '',
        user: '',
        userId: ''
      };
    });
    setSelectedApprover(-1);
    setOpenModal(true);
  };

  useEffect(() => {
    const selUserList = getUserList(userList, '');
    setApproverList(selUserList);
  }, [userList]);

  useEffect(() => {
    if (show) {
      if (procIndex < 0) {
        openProcAdd();
      } else {
        openProcModify();
      }
    }
  }, [show]);

  return (
    <div>
      <Modal
        size="lg"
        show={openModal}
        centered
        backdrop="static"
        onHide={hCloseModal}
        contentClassName="bg-100 p-6 border"
      >
        <Modal.Header className="border-0 p-0 mb-2">
          <h3 className="mb-0">승인</h3>
          <Button variant="phoenix-secondary" onClick={hCloseModal} size="sm">
            <FontAwesomeIcon icon={faTimes} className="text-danger" />
          </Button>
        </Modal.Header>
        <Modal.Body className="px-0 mb-6">
          <Row>
            <Row className="g-1">
              <Form.Label column sm={2}>
                업무명
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  id="title"
                  type="text"
                  placeholder="업무명"
                  value={inputs.title}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setInputs(old => {
                      return {
                        ...old,
                        title: e.target.value
                      };
                    });
                  }}
                />
              </Col>
            </Row>
            <Row className="g-1">
              <Form.Label column sm={2}>
                업무내용
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  as="textarea"
                  rows={6}
                  value={inputs.content}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setInputs(old => {
                      return {
                        ...old,
                        content: e.target.value
                      };
                    });
                  }}
                />
              </Col>
            </Row>
            <Row className="g-1">
              <Form.Label column sm={2}>
                소요일
              </Form.Label>
              <Col sm={3}>
                <InputGroup>
                  <FormControl
                    placeholder="소요일"
                    aria-label="소요일"
                    aria-describedby="basic-addon2"
                    value={inputs.timeFrame}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInputs(old => {
                        return {
                          ...old,
                          timeFrame: Number(e.target.value)
                        };
                      });
                    }}
                  />
                  <InputGroup.Text id="basic-addon2">일</InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>
            <Row className="g-1">
              <Form.Label column sm={2}>
                담당자
              </Form.Label>
              <Col sm={3}>
                <Form.Control
                  id="manager_team"
                  type="text"
                  placeholder="선택 암함"
                  value={selectedManager.team}
                  readOnly
                />
              </Col>
              <Col sm={3}>
                <Form.Control
                  id="manager_user"
                  type="text"
                  placeholder="선택 안함"
                  value={selectedManager.user}
                  readOnly
                />
              </Col>
              <Col sm={2}>
                <Button
                  variant="phoenix-secondary"
                  onClick={() => {
                    setOpenSelectManager(true);
                  }}
                >
                  선택
                </Button>
              </Col>
            </Row>
            <Row className="g-1">
              <Form.Label column sm={2}>
                승인자
                {/* <Button className="px-2 py-0" onClick={onAddApprovalTable}>
                  <FontAwesomeIcon icon={faPlusSquare} />
                </Button> */}
              </Form.Label>
              <Col sm={3}>
                <Form.Select
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    if (e.target.value) {
                      const team = teamList.at(Number(e.target.value));
                      inputs.approverTeam = team ? team.name : '';
                      inputs.approverTeamId = team ? team.code : '';
                    } else {
                      inputs.approverTeam = '';
                      inputs.approverTeamId = '';
                    }
                    const selUserList = getUserList(
                      userList,
                      inputs.approverTeamId
                    );
                    setApproverList(selUserList);
                    setSelectedApprover(-1);
                  }}
                >
                  <option value={''}>선택</option>
                  {teamList.map((team, index) => (
                    <option key={index} value={index}>
                      {team.name}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col sm={3}>
                <Form.Select
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                    const user = approverList.at(Number(e.target.value));
                    if (user) {
                      inputs.approverId = user.uid;
                      inputs.approver = user.userName;
                    }
                    setSelectedApprover(Number(e.target.value));
                  }}
                  value={selectedApprover}
                >
                  <option>선택</option>
                  {approverList.map((user, index) => (
                    <option key={index} value={index}>
                      {user.userName}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col sm={2}>
                <Button
                  variant="phoenix-secondary"
                  onClick={onAddApprovalTable}
                >
                  추가
                </Button>
              </Col>
            </Row>
            <Row className="g-1">
              <Col sm={2}></Col>
              <Col sm={7}>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th className="bg-white text-center fs-9">부서</th>
                      <th className="bg-white text-center fs-9">참조</th>
                      <td className="border border-100 fs-9" width="14%"></td>
                    </tr>
                  </thead>
                  <tbody>
                    {approverTable.map((value, index) => (
                      <tr key={index}>
                        <td className="text-center align-middle fs-9">
                          {value.team}
                        </td>
                        <td className="text-center align-middle fs-9">
                          {value.user}
                        </td>
                        <td width="14%" className="border border-100 fs-9">
                          <Button
                            variant="phoenix-secondary"
                            className="btn-icon fs-9"
                            onClick={() => {
                              approverTable.splice(index, 1);
                              setApproverTable([...approverTable]);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faClose}
                              className="text-danger"
                            />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row className="g-1">
              <Form.Label column sm={2}>
                업무 양식
                <Button className="px-2">
                  <label
                    className="text-900 fs-9 cursor-pointer"
                    htmlFor="formFile"
                  >
                    <FontAwesomeIcon icon={faPlusSquare} />
                  </label>
                </Button>
                <Form.Control
                  className="d-none"
                  type="file"
                  id="formFile"
                  multiple
                  onChange={({
                    target: { files }
                  }: ChangeEvent<HTMLInputElement>) => {
                    if (files) {
                      for (let i = 0; i < files.length; i++) {
                        formfiles.push({
                          name: files[i].name,
                          url: URL.createObjectURL(files[i]),
                          size: files[i].size,
                          projectId: ''
                        });
                      }
                      setFormfiles([...formfiles]);
                    }
                  }}
                />
              </Form.Label>
              <Col sm={9}>
                {formfiles && (
                  <div className={classNames({ 'mb-2': formfiles })}>
                    {formfiles.map((value, index) => (
                      <AttachmentPreview
                        key={index}
                        attachment={{
                          name: value.name,
                          size: fileSizeToString(value.size),
                          format: getFileExtension(value.name)
                        }}
                        size="xl"
                        handleRemove={() =>
                          setFormfiles(formfiles.filter((_, i) => index !== i))
                        }
                      />
                    ))}
                  </div>
                )}
              </Col>
            </Row>
            <Row className="g-1">
              <Form.Label column sm={2}>
                첨부 자료
                <Button className="px-2">
                  <label
                    className="text-900 fs-9 cursor-pointer"
                    htmlFor="attachments"
                  >
                    <FontAwesomeIcon icon={faPlusSquare} />
                  </label>
                </Button>
                <Form.Control
                  className="d-none"
                  type="file"
                  id="attachments"
                  multiple
                  onChange={({
                    target: { files }
                  }: ChangeEvent<HTMLInputElement>) => {
                    if (files) {
                      for (let i = 0; i < files.length; i++) {
                        attachments.push({
                          name: files[i].name,
                          url: URL.createObjectURL(files[i]),
                          size: files[i].size,
                          projectId: ''
                        });
                      }
                      setAttachments([...attachments]);
                    }
                  }}
                />
              </Form.Label>
              <Col sm={9}>
                {attachments && (
                  <div className={classNames({ 'mb-2': attachments })}>
                    {attachments.map((value, index) => (
                      <AttachmentPreview
                        key={index}
                        attachment={{
                          name: value.name,
                          size: fileSizeToString(value.size),
                          format: getFileExtension(value.name)
                        }}
                        size="xl"
                        handleRemove={() =>
                          setAttachments(
                            attachments.filter((_, i) => index !== i)
                          )
                        }
                      />
                    ))}
                  </div>
                )}
              </Col>
            </Row>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border-0 p-0">
          <Button
            variant="link"
            className="text-danger px-3 my-0"
            onClick={hCloseModal}
          >
            취소
          </Button>
          <Button variant="primary" className="my-0" onClick={hAddProcess}>
            {inputMode.button}
          </Button>
        </Modal.Footer>
      </Modal>

      <SelectionTeamUser
        teamList={teamList}
        userList={userList}
        show={openSelectManager}
        setShow={setOpenSelectManager}
        setTeamUserInfo={setSelectedManager}
      />
    </div>
  );
};

export default ProjectApproval;
