// import { createContext, useContext, useMemo } from 'react';
// import { useNavigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

export interface userInfoObject {
  uid: string | null;
  email: string | null;
}

export const getLocalStorage = (key: string) => {
  const defaultValue = null;
  try {
    const value = window.localStorage.getItem(key);
    if (value) {
      return JSON.parse(value);
    } else {
      return null;
    }
  } catch (err) {
    return defaultValue;
  }
};

export const setLocalStorage = (key: string, data: any) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(data));
  } catch (err) {
    console.log(err);
  }
};

export const removeLocalStorage = (key: string) => {
  try {
    window.localStorage.removeItem(key);
  } catch (err) {
    console.log(err);
  }
};

export const getSecureStorage = (key: string) => {
  const defaultValue = null;
  try {
    const value = secureLocalStorage.getItem(key);
    if (value) {
      return JSON.parse(String(value));
    } else {
      return null;
    }
  } catch (err) {
    return defaultValue;
  }
};

export const setSecureStorage = (key: string, data: any) => {
  try {
    secureLocalStorage.setItem(key, JSON.stringify(data));
  } catch (err) {
    console.log(err);
  }
};

export const removeSecureStorage = (key: string) => {
  try {
    secureLocalStorage.removeItem(key);
  } catch (err) {
    console.log(err);
  }
};
