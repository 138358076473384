//import Button from 'components/base/Button';
import { Link } from 'react-router-dom';
//import { Col, Form, Row } from 'react-bootstrap';
import AuthCardLayout from 'layouts/AuthCardLayout';

const SignInNoAuth = () => {
  return (
    <AuthCardLayout className="pb-md-7">
      <>
        <div className="text-center mb-7">
          <h3 className="text-1000">가입 승인 대기중</h3>
          <p className="text-700">관리자가 승인 후 로그인 가능합니다.</p>
        </div>
        <div className="text-center">
          <Link to={`/pages/auth/sign-in`} className="fs-9 fw-bold">
            Sign in to an existing account
          </Link>
        </div>
        <div className="text-center">
          <Link to={`/pages/auth/sign-up`} className="fs-9 fw-bold">
            Create an account
          </Link>
        </div>
      </>
    </AuthCardLayout>
  );
};

export default SignInNoAuth;
