import { useEffect, useState } from 'react';
import { useAppContext } from 'providers/AppProvider';
import {
  dbProjectInfo,
  getTeamName,
  getUserName,
  CATEGORY_STANDARD,
  CATEGORY_PROJECT,
  CATEGORY_PRIVATE,
  getProcessCheck
} from 'lib/firebase';
import { downloadFile } from 'lib/fbStorage';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  IProjectTempleate,
  IProcess,
  TYPE_PERIOD,
  getClassName,
  ICheckInfo
} from 'interfaces';
import classNames from 'classnames';
import SingleMsg from 'pages/pages/SingleMsg';
import { Card, Row, Col } from 'react-bootstrap';
import dayjs from 'dayjs';

const ProjectDetail = () => {
  const { projectId } = useParams();
  const [searchParams] = useSearchParams();
  const { userInfo, teamList, userList } = useAppContext();
  const [projoct, setProject] = useState<IProjectTempleate>();
  const [processList, setProcessList] = useState<IProcess[]>([]);
  const [checkList, setCheckList] = useState<ICheckInfo[][]>();

  const getCheckList = async () => {
    const newCheckList: ICheckInfo[][] = [];

    if (projoct && userInfo && projectId) {
      for (let i = 0; i < projoct.process.length; i++) {
        const retData = await getProcessCheck(userInfo, projectId, i);
        if (retData.length > 0) {
          newCheckList[i] = new Array(retData.length);
          for (let j = 0; j < retData.length; j++) {
            newCheckList[i][j] = retData[j];
          }
        }
      }
      setCheckList(newCheckList);
    }
  };

  useEffect(() => {
    if (userInfo && projectId) {
      const category = searchParams.get('category') as string;
      dbProjectInfo(userInfo, category, projectId).then(projoct => {
        setProject(projoct);
      });
    }
  }, [userInfo]);

  useEffect(() => {
    if (projoct?.process) {
      setProcessList(projoct.process);
      getCheckList();
    }
  }, [projoct]);

  if (projoct && !projoct.key) {
    return <SingleMsg msg="데이터 없음" />;
  }

  return (
    <div className="d-flex flex-column gap-2 mb-4">
      <Card>
        <Card.Body>
          <Row className="gy-4 py-0 gx-0 h-100">
            <Col xs={12} lg={8} className="h-100 scrollbar">
              <Row className="mt-0 top-0 gy-4 pb-3 gx-0 px-3">
                <Col xs={4} sm={3}>
                  <h6 className="text-600 fw-bolder lh-sm mt-1">제목</h6>
                </Col>
                <Col xs={8} sm={9}>
                  <h4 className="mb-0 text-1100 lh-sm">{projoct?.title}</h4>
                </Col>
              </Row>
              <Row className="mt-0 top-0 gy-0 pb-1 gx-0 px-3">
                <Col xs={4} sm={3}>
                  <h6 className="text-600 fw-bolder lh-sm mt-1">주관부서</h6>
                </Col>
                <Col xs={8} sm={9}>
                  <p className="mb-0 text-1100 fw-semi-bold">
                    {projoct && getTeamName(teamList, projoct.presideTeam)}
                  </p>
                </Col>
              </Row>
              <Row className="mt-0 top-0 gy-0 pb-1 gx-0 px-3">
                <Col xs={4} sm={3}>
                  <h6 className="text-600 fw-bolder lh-sm mt-1">주관인</h6>
                </Col>
                <Col xs={8} sm={9}>
                  <p className="mb-0 text-1100 fw-semi-bold">
                    {projoct && getUserName(userList, projoct.president)}
                  </p>
                </Col>
              </Row>
              <Row className="mt-0 top-0 gy-0 pb-1 gx-0 px-3">
                <Col xs={4} sm={3}>
                  <h6 className="text-600 fw-bolder lh-sm mt-1">업무주기</h6>
                </Col>
                <Col xs={8} sm={9}>
                  <p className="mb-0 text-1100 fw-semi-bold">
                    {projoct && TYPE_PERIOD.at(projoct.period)}
                  </p>
                </Col>
              </Row>
              <Row className="mt-0 top-0 gy-2 pb-3 gx-0 px-3">
                <Col xs={4} sm={3}>
                  <h6 className="text-600 fw-bolder lh-sm mt-1">
                    업무 정의 및 범위
                  </h6>
                </Col>
                <Col xs={8} sm={9}>
                  {projoct?.description.split('\n').map((value, index) => {
                    return (
                      <p className="fs-9 mb-0" key={index}>
                        {value}
                      </p>
                    );
                  })}
                </Col>
              </Row>
              <Row className="mt-0 top-0 gy-0 pb-1 gx-0 px-3">
                <Col xs={4} sm={3}>
                  <h6 className="text-600 fw-bolder lh-sm mt-1">진행자</h6>
                </Col>
                <Col xs={8} sm={9}>
                  <p className="mb-0 text-1100 fw-semi-bold">
                    {projoct && getUserName(userList, projoct.manager)}
                  </p>
                </Col>
              </Row>
              <Row className="mt-0 top-0 gy-0 pb-1 gx-0 px-3">
                <Col xs={4} sm={3}>
                  <h6 className="text-600 fw-bolder lh-sm mt-1">업무 참조</h6>
                </Col>
                <Col xs={8} sm={9}>
                  <p className="mb-0 text-1100 fw-semi-bold">
                    {projoct &&
                      projoct.referrer &&
                      projoct.referrer.map((v, i) => (
                        <span key={i}>
                          {i > 0 && ','}
                          {getUserName(userList, v)}
                        </span>
                      ))}
                  </p>
                </Col>
              </Row>
              <Row className="mt-0 top-0 gy-0 pb-1 gx-0 px-3">
                <Col xs={4} sm={3}>
                  <h6 className="text-600 fw-bolder lh-sm mt-1">시작일</h6>
                </Col>
                <Col xs={8} sm={9}>
                  <p className="mb-0 text-1100 fw-semi-bold">
                    {projoct?.createAt &&
                      dayjs(projoct.createAt).format('YYYY-MM-DD HH:mm:ss')}
                  </p>
                </Col>
              </Row>
              <Row className="mt-0 top-0 gy-0 pb-1 gx-0 px-3">
                <Col xs={4} sm={3}>
                  <h6 className="text-600 fw-bolder lh-sm mt-1">수정일</h6>
                </Col>
                <Col xs={8} sm={9}>
                  <p className="mb-0 text-1100 fw-semi-bold">
                    {projoct?.modifyAt &&
                      dayjs(projoct?.modifyAt).format('YYYY-MM-DD HH:mm:ss')}
                  </p>
                </Col>
              </Row>
              <Row className="mt-0 top-0 gy-0 pb-1 gx-0 px-3">
                <Col xs={4} sm={3}>
                  <h6 className="text-600 fw-bolder lh-sm mt-1">종료일</h6>
                </Col>
                <Col xs={8} sm={9}>
                  <p className="mb-0 text-1100 fw-semi-bold">
                    {projoct?.endAt &&
                      dayjs(projoct?.endAt).format('YYYY-MM-DD HH:mm:ss')}
                  </p>
                </Col>
              </Row>
              <Row className="mt-0 top-0 gy-0 pb-1 gx-0 px-3">
                <Col xs={4} sm={3}>
                  <h6 className="text-600 fw-bolder lh-sm mt-1">소요일</h6>
                </Col>
                <Col xs={8} sm={9}>
                  <p className="mb-0 text-1100 fw-semi-bold">
                    {projoct && projoct.timeFrame} 일
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {processList.map((proc, index) => (
        <div
          key={index}
          className={classNames('border-bottom border-300 py-0 px-4', {
            'pb-0 border-bottom-0': index === processList.length - 1
          })}
        >
          <Row className="gy-1 py-3 gx-0 h-100">
            <Col xs={12} lg={8} className="h-100 scrollbar">
              <Row className="mt-0 top-0 gy-0 pb-1 gx-0 px-3">
                <Col xs={4} sm={3}>
                  <h6 className="text-600 fw-bolder lh-sm mt-1">업무명</h6>
                </Col>
                <Col xs={8} sm={9}>
                  <h5 className="mb-0 text-1100 lh-sm">
                    {/* {'[' + (index + 1) + '] ' + proc.title} */}
                    {proc.title}
                  </h5>
                </Col>
              </Row>
              <Row className="mt-0 top-0 gy-0 pb-0 gx-0 px-3">
                <Col xs={4} sm={3}>
                  <h6 className="text-600 fw-bolder lh-sm mt-1">업무 분류</h6>
                </Col>
                <Col xs={8} sm={9}>
                  <p className="mb-0 text-1100 fw-semi-bold">
                    {getClassName(proc.class)}
                  </p>
                </Col>
              </Row>
              <Row className="mt-0 top-0 gy-2 pb-3 gx-0 px-3">
                <Col xs={4} sm={3}>
                  <h6 className="text-600 fw-bolder lh-sm mt-1">업무 내용</h6>
                </Col>
                <Col xs={8} sm={9}>
                  <p className="fs-9 mb-0">
                    {proc.content.split('\n').map((line, i) => {
                      return (
                        <span key={i}>
                          {line}
                          <br />
                        </span>
                      );
                    })}
                  </p>
                </Col>
              </Row>
              <Row className="mt-0 top-0 gy-0 pb-0 gx-0 px-3">
                <Col xs={4} sm={3}>
                  <h6 className="text-600 fw-bolder lh-sm mt-1">소요일</h6>
                </Col>
                <Col xs={8} sm={9}>
                  <p className="mb-0 text-1100 fw-semi-bold">
                    {proc.timeFrame} 일
                  </p>
                </Col>
              </Row>

              {/* 전달 */}
              {proc.delivery && (
                <>
                  <Row className="mt-0 top-0 gy-0 pb-0 gx-0 px-3">
                    <Col xs={4} sm={3}>
                      <h6 className="text-600 fw-bolder lh-sm mt-1">수령인</h6>
                    </Col>
                    <Col xs={8} sm={9}>
                      <p className="mb-0 text-1100 fw-semi-bold">
                        {proc.delivery.recipient &&
                          proc.delivery.recipient.map((v, i) => (
                            <span key={i}>
                              {i === 0 ? ' ' : ', '}
                              {getUserName(userList, v)}
                            </span>
                          ))}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-0 top-0 gy-0 pb-0 gx-0 px-3">
                    <Col xs={4} sm={3}>
                      <h6 className="text-600 fw-bolder lh-sm mt-1">
                        첨부파일
                      </h6>
                    </Col>
                    <Col xs={8} sm={9}>
                      <p className="mb-0 text-1100 fw-semi-bold">
                        {proc.delivery.attachment &&
                          proc.delivery.attachment.map((v, i) => (
                            <span key={i}>
                              {i === 0 ? ' ' : ', '}
                              <a
                                href="javascript:void(0)"
                                style={{ color: 'blue' }}
                                onClick={() => downloadFile(v.url, v.name)}
                              >
                                {v.name}
                              </a>
                            </span>
                          ))}
                      </p>
                    </Col>
                  </Row>
                </>
              )}

              {/* 승인 */}
              {proc.approval && (
                <>
                  <Row className="mt-0 top-0 gy-0 pb-0 gx-0 px-3">
                    <Col xs={4} sm={3}>
                      <h6 className="text-600 fw-bolder lh-sm mt-1">담당자</h6>
                    </Col>
                    <Col xs={8} sm={9}>
                      <p className="mb-0 text-1100 fw-semi-bold">
                        {getUserName(userList, proc.approval.manager)}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-0 top-0 gy-0 pb-0 gx-0 px-3">
                    <Col xs={4} sm={3}>
                      <h6 className="text-600 fw-bolder lh-sm mt-1">승인자</h6>
                    </Col>
                    <Col xs={8} sm={9}>
                      <p className="mb-0 text-1100 fw-semi-bold">
                        {proc.approval.approver &&
                          proc.approval.approver.map((v, i) => (
                            <span key={i}>
                              {i === 0 ? ' ' : ', '}
                              {getUserName(userList, v)}
                            </span>
                          ))}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-0 top-0 gy-0 pb-0 gx-0 px-3">
                    <Col xs={4} sm={3}>
                      <h6 className="text-600 fw-bolder lh-sm mt-1">
                        업무 양식
                      </h6>
                    </Col>
                    <Col xs={8} sm={9}>
                      <p className="mb-0 text-1100 fw-semi-bold">
                        {proc.approval.formFile &&
                          proc.approval.formFile.map((v, i) => (
                            <span key={i}>
                              {i === 0 ? ' ' : ', '}
                              <a
                                href="javascript:void(0)"
                                style={{ color: 'blue' }}
                                onClick={() => downloadFile(v.url, v.name)}
                              >
                                {v.name}
                              </a>
                            </span>
                          ))}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-0 top-0 gy-0 pb-0 gx-0 px-3">
                    <Col xs={4} sm={3}>
                      <h6 className="text-600 fw-bolder lh-sm mt-1">
                        첨부 자료
                      </h6>
                    </Col>
                    <Col xs={8} sm={9}>
                      <p className="mb-0 text-1100 fw-semi-bold">
                        {proc.approval.attachment &&
                          proc.approval.attachment.map((v, i) => (
                            <span key={i}>
                              {i === 0 ? ' ' : ', '}
                              <a
                                href="javascript:void(0)"
                                style={{ color: 'blue' }}
                                onClick={() => downloadFile(v.url, v.name)}
                              >
                                {v.name}
                              </a>
                            </span>
                          ))}
                      </p>
                    </Col>
                  </Row>
                </>
              )}

              {/* 요청 */}
              {proc.request &&
                proc.request.length > 0 &&
                proc.request.map((v, i) => (
                  <>
                    <Row className="mt-0 top-0 gy-0 pb-0 gx-0 px-3">
                      <Col xs={4} sm={3}>
                        <h6 className="text-600 fw-bolder lh-sm mt-1">
                          {'발송인 ' + (i + 1)}
                        </h6>
                      </Col>
                      <Col xs={8} sm={9}>
                        <p className="mb-0 text-1100 fw-semi-bold">
                          {getUserName(userList, v.sender)}
                        </p>
                      </Col>
                    </Row>
                    <Row className="mt-0 top-0 gy-0 pb-0 gx-0 px-3">
                      <Col xs={4} sm={3}>
                        <h6 className="text-600 fw-bolder lh-sm mt-1">
                          요청 자료
                        </h6>
                      </Col>
                      <Col xs={8} sm={9}>
                        <p className="mb-0 text-1100 fw-semi-bold">
                          {v.attachment &&
                            v.attachment.map((v, i) => (
                              <span key={i}>
                                {i === 0 ? ' ' : ', '}
                                <a
                                  href="javascript:void(0)"
                                  style={{ color: 'blue' }}
                                  onClick={() => downloadFile(v.url, v.name)}
                                >
                                  {v.name}
                                </a>
                              </span>
                            ))}
                        </p>
                      </Col>
                    </Row>
                  </>
                ))}

              <Row className="mt-0 top-0 gy-0 pb-0 gx-0 px-3">
                <Col xs={4} sm={3}>
                  <h6 className="text-600 fw-bolder lh-sm mt-1">확 인</h6>
                </Col>
                <Col xs={8} sm={9}>
                  <p className="mb-0 text-1100 fw-semi-bold">
                    {checkList &&
                      checkList[index] &&
                      checkList[index].map((v, i) => (
                        <span key={i}>
                          {getUserName(userList, v.userId)}
                          {dayjs(v.checkedAt).format(' : YYYY-MM-DD HH:mm:ss')}
                        </span>
                      ))}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      ))}
    </div>
  );
};

export default ProjectDetail;
