export type ThemeVariant = 'light' | 'dark';
export type NavPositionVariant = 'horizontal' | 'vertical' | 'combo' | 'dual';
export type NavTopShapeVariant = 'default' | 'slim';
export type NavbarAppearanceVariant = 'default' | 'darker';
export interface Config {
  isNavbarVerticalCollapsed: boolean;
  openNavbarVertical: boolean;
  theme: ThemeVariant;
  navbarVerticalAppearance: NavbarAppearanceVariant;
  navbarTopAppearance: NavbarAppearanceVariant;
  navbarPosition: NavPositionVariant;
  navbarTopShape: NavTopShapeVariant;
  isRTL: boolean;
  bodyClass?: string;
  isChatWidgetVisible: boolean;
}

export const initialConfig: Config = {
  isNavbarVerticalCollapsed: false,
  openNavbarVertical: false, // for responsive
  theme: 'light',
  navbarTopAppearance: 'default',
  navbarVerticalAppearance: 'default',
  navbarPosition: 'vertical',
  navbarTopShape: 'default',
  isRTL: false,
  isChatWidgetVisible: false
};

export const firebaseConfig = {
  apiKey: 'AIzaSyCSq6O1gmt_N3flojwHQjV6BAIu7JTfvwg',
  authDomain: 'easyproc-fdbd0.firebaseapp.com',
  projectId: 'easyproc-fdbd0',
  storageBucket: 'easyproc-fdbd0.appspot.com',
  messagingSenderId: '922688537291',
  appId: '1:922688537291:web:e5153c413d337f4f45514e',
  measurementId: 'G-XETK36VJ5E',
  databaseURL:
    'https://easyproc-fdbd0-default-rtdb.asia-southeast1.firebasedatabase.app'
};
