import Button from 'components/base/Button';
// import AuthSocialButtons from 'components/common/AuthSocialButtons';
import { Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { FormEvent, ChangeEvent, useState } from 'react';
import {
  getAuth,
  createUserWithEmailAndPassword,
  sendEmailVerification
} from 'firebase/auth';

const SignUpForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [terms, setTerms] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (!terms) {
      alert('약관 및 개인정보 보호정책에 동의가 필요합니다.');
      return;
    }

    if (email.length <= 0) {
      alert('이메일 주소를 입력하세요');
      return;
    }

    if (password.length < 6) {
      alert('비밀번호는 6자리 이상 입력하세요');
      return;
    }

    if (password != confirmPassword) {
      alert('비밀번호와 확인용 비밀번호가 일치하지 않습니다');
      return;
    }

    const auth = getAuth();

    createUserWithEmailAndPassword(auth, email, password)
      .then(userCredential => {
        // Signed in
        const user = userCredential.user;

        sendEmailVerification(user)
          .then(() => {
            alert(
              '입력하신 메일주소로 인증 메일을 보냈습니다,\n이메일 인증 후 1회 로그인을 하면 가입 요청이 진행 됩니다.'
            );
            navigate('/pages/auth/sign-in');
          })
          .catch(error => {
            alert(error.message + '(code:' + error.code + ')');
          });
      })
      .catch(error => {
        switch (error.code) {
          case 'auth/email-already-in-use':
            alert('이미 사용중인 이메일 주소입니다.');
            break;

          default:
            alert(error.message + '(code:' + error.code + ')');
            break;
        }
      });
  };

  return (
    <>
      <div className="text-center mb-7">
        <h3 className="text-1000">Sign Up</h3>
        <p className="text-700">Create your account today</p>
      </div>
      {/* <AuthSocialButtons title="Sign up" />
      <div className="position-relative mt-4">
        <hr className="bg-200" />
        <div className="divider-content-center">or use email</div>
      </div> */}
      <Form onSubmit={handleSubmit}>
        {/* <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="name">Name</Form.Label>
          <Form.Control 
            id="name" 
            type="text" 
            placeholder="Name" 
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setName(e.target.value)
            }
          />
        </Form.Group> */}
        <Form.Group className="mb-3 text-start">
          <Form.Label htmlFor="email">Email address</Form.Label>
          <Form.Control
            id="email"
            type="email"
            placeholder="name@example.com"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
          />
        </Form.Group>
        <Row className="g-3 mb-3">
          <Col sm={12} lg={6}>
            <Form.Group>
              <Form.Label htmlFor="password">Password</Form.Label>
              <Form.Control
                id="password"
                type="password"
                placeholder="Password"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setPassword(e.target.value)
                }
              />
            </Form.Group>
          </Col>
          <Col sm={12} lg={6}>
            <Form.Group>
              <Form.Label htmlFor="confirmPassword">
                Confirm Password
              </Form.Label>
              <Form.Control
                id="confirmPassword"
                type="password"
                placeholder="Confirm Password"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setConfirmPassword(e.target.value)
                }
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Check type="checkbox" className="mb-3">
          <Form.Check.Input
            type="checkbox"
            name="termsService"
            id="termsService"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setTerms(e.target.checked)
            }
          />
          <Form.Check.Label htmlFor="termsService" className="fs-9 text-none">
            I accept the <Link to="#!">terms </Link>and{' '}
            <Link to="#!">privacy policy</Link>
          </Form.Check.Label>
        </Form.Check>
        <Button variant="primary" type="submit" className="w-100 mb-3">
          Sign up
        </Button>
        <div className="text-center">
          <Link to={`/pages/auth/sign-in`} className="fs-9 fw-bold">
            Sign in to an existing account
          </Link>
        </div>
      </Form>
    </>
  );
};

export default SignUpForm;
