import { getFileExtension } from 'helpers/utils';
import AttachmentPreview from 'components/common/AttachmentPreview';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  Col,
  Row,
  Form,
  InputGroup,
  FormControl,
  Modal
} from 'react-bootstrap';
import Button from 'components/base/Button';
import {
  ChangeEvent,
  useEffect,
  useState,
  Dispatch,
  SetStateAction
} from 'react';
import { ITeamInfo, IUserInfo, getTeamUserInfo } from 'lib/firebase';
import { IProcess, IProcRequest, ITeamUserTable, IProcFile } from 'interfaces';
import { fileSizeToString } from 'lib/fbStorage';
import SelectionTeamUser from 'components/modules/project-management/SelectionTeamUser';

const ProjectRequest = ({
  procList,
  setProcList,
  teamList,
  userList,
  show,
  setShow,
  procIndex
}: {
  procList: IProcess[];
  setProcList: Dispatch<SetStateAction<IProcess[]>>;
  teamList: ITeamInfo[];
  userList: IUserInfo[];
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  procIndex: number;
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [attachments, setAttachments] = useState<IProcFile[]>([]);
  const [attachments2, setAttachments2] = useState<IProcFile[]>([]);
  const [inputMode, setInputMode] = useState({
    index: -1,
    button: '추가'
  });
  const [inputs, setInputs] = useState({
    title: '',
    content: '',
    timeFrame: 0
  });

  const [openSelectSender, setOpenSelectSender] = useState(false);
  const [selectedSender, setSelectedSender] = useState<ITeamUserTable>({
    team: '',
    teamId: '',
    user: '',
    userId: ''
  });
  const [openSelectSender2, setOpenSelectSender2] = useState(false);
  const [selectedSender2, setSelectedSender2] = useState<ITeamUserTable>({
    team: '',
    teamId: '',
    user: '',
    userId: ''
  });

  const hCloseModal = () => {
    setShow(false);
    setOpenModal(false);
  };

  const hAddProcess = () => {
    const requestData: IProcRequest[] = [];

    if (selectedSender.userId) {
      requestData.push({
        sender: selectedSender.userId,
        attachment: []
      });
      if (attachments.length) {
        const pAttchment = requestData.at(0);
        if (pAttchment) {
          attachments.map(value => pAttchment.attachment.push(value));
        }
      }
    }

    if (selectedSender2.userId) {
      requestData.push({
        sender: selectedSender2.userId,
        attachment: []
      });
      if (attachments2.length) {
        const pAttchment = requestData.at(1);
        if (pAttchment) {
          attachments2.map(value => pAttchment.attachment.push(value));
        }
      }
    }

    if (inputMode.index < 0) {
      procList.push({
        id: '',
        class: 'request',
        title: inputs.title,
        content: inputs.content,
        timeFrame: inputs.timeFrame,
        request: JSON.parse(JSON.stringify(requestData))
      });
    } else {
      const proc = procList.at(inputMode.index);
      if (proc) {
        proc.title = inputs.title;
        proc.content = inputs.content;
        proc.timeFrame = inputs.timeFrame;
        proc.request = JSON.parse(JSON.stringify(requestData));
      }
    }
    setProcList([...procList]);
    hCloseModal();
  };

  const openProcModify = () => {
    const proc = procList.at(procIndex);

    if (proc) {
      setInputMode({
        index: procIndex,
        button: '수정'
      });

      if (proc.class === 'request') {
        const data = {
          title: proc.title,
          content: proc.content,
          timeFrame: proc.timeFrame
        };

        const sender = proc.request?.at(0)?.sender;
        if (sender) {
          const user = getTeamUserInfo(userList, teamList, sender);
          setSelectedSender(user);
        }

        const sender2 = proc.request?.at(1)?.sender;
        if (sender2) {
          const user = getTeamUserInfo(userList, teamList, sender2);
          setSelectedSender2(user);
        }
        setInputs(data);

        const attachment = proc.request?.at(0)?.attachment;
        if (attachment) {
          setAttachments(attachment);
        }

        const attachment2 = proc.request?.at(1)?.attachment;
        if (attachment2) {
          setAttachments2(attachment2);
        }

        setOpenModal(true);
      }
    }
  };

  const openProcAdd = () => {
    setInputMode({
      index: -1,
      button: '추가'
    });
    setInputs(old => {
      return {
        ...old,
        title: '',
        content: '',
        timeFrame: 0,
        senderTeam: '',
        senderTeamId: '',
        sender: '',
        senderId: '',
        sender2Team: '',
        sender2TeamId: '',
        sender2: '',
        sender2Id: ''
      };
    });
    setAttachments([]);
    setAttachments2([]);
    setSelectedSender(old => {
      return {
        team: '',
        teamId: '',
        user: '',
        userId: ''
      };
    });
    setSelectedSender2(old => {
      return {
        team: '',
        teamId: '',
        user: '',
        userId: ''
      };
    });
    setOpenModal(true);
  };

  useEffect(() => {
    if (show) {
      if (procIndex < 0) {
        openProcAdd();
      } else {
        openProcModify();
      }
    }
  }, [show]);

  return (
    <div>
      <Modal
        size="lg"
        show={openModal}
        centered
        backdrop="static"
        onHide={hCloseModal}
        contentClassName="bg-100 p-6 border"
      >
        <Modal.Header className="border-0 p-0 mb-2">
          <h3 className="mb-0">요청</h3>
          <Button variant="phoenix-secondary" onClick={hCloseModal} size="sm">
            <FontAwesomeIcon icon={faTimes} className="text-danger" />
          </Button>
        </Modal.Header>
        <Modal.Body className="px-0 mb-6">
          <Row>
            <Row className="g-1">
              <Form.Label column sm={2}>
                업무명
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  id="title"
                  type="text"
                  placeholder="업무명"
                  value={inputs.title}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setInputs(old => {
                      return {
                        ...old,
                        title: e.target.value
                      };
                    });
                  }}
                />
              </Col>
            </Row>
            <Row className="g-1">
              <Form.Label column sm={2}>
                업무내용
              </Form.Label>
              <Col sm={9}>
                <Form.Control
                  as="textarea"
                  rows={6}
                  value={inputs.content}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setInputs(old => {
                      return {
                        ...old,
                        content: e.target.value
                      };
                    });
                  }}
                />
              </Col>
            </Row>
            <Row className="g-1">
              <Form.Label column sm={2}>
                소요일
              </Form.Label>
              <Col sm={3}>
                <InputGroup>
                  <FormControl
                    placeholder="소요일"
                    aria-label="소요일"
                    aria-describedby="basic-addon2"
                    value={inputs.timeFrame}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setInputs(old => {
                        return {
                          ...old,
                          timeFrame: Number(e.target.value)
                        };
                      });
                    }}
                  />
                  <InputGroup.Text id="basic-addon2">일</InputGroup.Text>
                </InputGroup>
              </Col>
            </Row>
            <Row className="g-1">
              <Form.Label column sm={2}>
                발송인 1
              </Form.Label>
              <Col sm={3}>
                <Form.Control
                  id="sender_team"
                  type="text"
                  placeholder="선택 암함"
                  value={selectedSender.team}
                  readOnly
                />
              </Col>
              <Col sm={3}>
                <Form.Control
                  id="sender_user"
                  type="text"
                  placeholder="선택 안함"
                  value={selectedSender.user}
                  readOnly
                />
              </Col>
              <Col sm={2}>
                <Button
                  variant="phoenix-secondary"
                  onClick={() => {
                    setOpenSelectSender(true);
                  }}
                >
                  선택
                </Button>
              </Col>
            </Row>
            <Row className="g-1">
              <Form.Label column sm={2}>
                요청 자료
                <Button className="px-2">
                  <label
                    className="text-900 fs-9 cursor-pointer"
                    htmlFor="attachments"
                  >
                    <FontAwesomeIcon icon={faPlusSquare} />
                  </label>
                </Button>
                <Form.Control
                  className="d-none"
                  type="file"
                  id="attachments"
                  multiple
                  onChange={({
                    target: { files }
                  }: ChangeEvent<HTMLInputElement>) => {
                    if (files) {
                      for (let i = 0; i < files.length; i++) {
                        attachments.push({
                          name: files[i].name,
                          url: URL.createObjectURL(files[i]),
                          size: files[i].size,
                          projectId: ''
                        });
                      }
                      setAttachments([...attachments]);
                    }
                  }}
                />
              </Form.Label>
              <Col sm={9}>
                {attachments && (
                  <div className={classNames({ 'mb-2': attachments })}>
                    {attachments.map((value, index) => (
                      <AttachmentPreview
                        key={index}
                        attachment={{
                          name: value.name,
                          size: fileSizeToString(value.size),
                          format: getFileExtension(value.name)
                        }}
                        size="xl"
                        handleRemove={() =>
                          setAttachments(
                            attachments.filter((_, i) => index !== i)
                          )
                        }
                      />
                    ))}
                  </div>
                )}
              </Col>
            </Row>
            <Row className="g-1">
              <Form.Label column sm={2}>
                발송인 2
              </Form.Label>
              <Col sm={3}>
                <Form.Control
                  id="sender2_team"
                  type="text"
                  placeholder="선택 암함"
                  value={selectedSender2.team}
                  readOnly
                />
              </Col>
              <Col sm={3}>
                <Form.Control
                  id="sender2_user"
                  type="text"
                  placeholder="선택 안함"
                  value={selectedSender2.user}
                  readOnly
                />
              </Col>
              <Col sm={2}>
                <Button
                  variant="phoenix-secondary"
                  onClick={() => {
                    setOpenSelectSender2(true);
                  }}
                >
                  선택
                </Button>
              </Col>
            </Row>
            <Row className="g-1">
              <Form.Label column sm={2}>
                요청 자료
                <Button className="px-2">
                  <label
                    className="text-900 fs-9 cursor-pointer"
                    htmlFor="attachments2"
                  >
                    <FontAwesomeIcon icon={faPlusSquare} />
                  </label>
                </Button>
                <Form.Control
                  className="d-none"
                  type="file"
                  id="attachments2"
                  multiple
                  onChange={({
                    target: { files }
                  }: ChangeEvent<HTMLInputElement>) => {
                    if (files) {
                      for (let i = 0; i < files.length; i++) {
                        attachments2.push({
                          name: files[i].name,
                          url: URL.createObjectURL(files[i]),
                          size: files[i].size,
                          projectId: ''
                        });
                      }
                      setAttachments2([...attachments2]);
                    }
                  }}
                />
              </Form.Label>
              <Col sm={9}>
                {attachments2 && (
                  <div className={classNames({ 'mb-2': attachments2 })}>
                    {attachments2.map((value, index) => (
                      <AttachmentPreview
                        key={index}
                        attachment={{
                          name: value.name,
                          size: fileSizeToString(value.size),
                          format: getFileExtension(value.name)
                        }}
                        size="xl"
                        handleRemove={() =>
                          setAttachments2(
                            attachments2.filter((_, i) => index !== i)
                          )
                        }
                      />
                    ))}
                  </div>
                )}
              </Col>
            </Row>
          </Row>
        </Modal.Body>
        <Modal.Footer className="border-0 p-0">
          <Button
            variant="link"
            className="text-danger px-3 my-0"
            onClick={hCloseModal}
          >
            취소
          </Button>
          <Button variant="primary" className="my-0" onClick={hAddProcess}>
            {inputMode.button}
          </Button>
        </Modal.Footer>
      </Modal>

      <SelectionTeamUser
        teamList={teamList}
        userList={userList}
        show={openSelectSender}
        setShow={setOpenSelectSender}
        setTeamUserInfo={setSelectedSender}
      />

      <SelectionTeamUser
        teamList={teamList}
        userList={userList}
        show={openSelectSender2}
        setShow={setOpenSelectSender2}
        setTeamUserInfo={setSelectedSender2}
      />
    </div>
  );
};

export default ProjectRequest;
