import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { ChangeEvent, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
// import TeamsTable, { teamsTablecolumns } from 'components/tables/TeamsTable';
import { getTeams, ITeamInfo } from 'lib/firebase';
import { useAppContext } from 'providers/AppProvider';
import PhoenixLoader from 'components/common/PhoenixLoader';
import PhoenixDocCard from 'components/base/PhoenixDocCard';
import { Chart } from 'react-google-charts';

const chartOptions = {
  allowHtml: true,
  allowCollapse: false
};

const Teams = () => {
  const { userInfo, teamList, setTeamList } = useAppContext();
  const [loading, setLoading] = useState(true);
  // const [teamList, setTeamList] = useState<ITeamInfo[]>([]);
  const [treeList, setTreeList] = useState<ITeamInfo[]>([]);

  // const table = useAdvanceTable({
  //   data: teamList ? teamList : [],
  //   columns: teamsTablecolumns,
  //   pageSize: 10,
  //   pagination: true,
  //   sortable: true,
  //   selection: false
  // });

  // const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   table.setGlobalFilter(e.target.value || undefined);
  // };

  const updateTreeData = () => {
    const tree: ITeamInfo[] = [];

    if (userInfo && userInfo.companyUuid) {
      tree.push({
        parentId: '',
        code: 'top',
        name: 'top',
        phone: ''
      });
      tree.push({
        parentId: '',
        code: userInfo.companyUuid,
        name: userInfo.companyName,
        phone: ''
      });

      const newTeamList = Array.from(teamList);
      newTeamList.forEach(n => {
        tree.push({
          parentId: n.parentId,
          code: n.code,
          name: n.name,
          phone: n.phone
        });
      });
    }
    setTreeList(tree);
  };

  useEffect(() => {
    updateTreeData();
  }, [teamList]);

  useEffect(() => {
    if (userInfo && userInfo.companyUuid) {
      getTeams(userInfo.companyUuid).then(teamList => {
        setTeamList(teamList);
        setLoading(false);
      });
    }
  }, [userInfo]);

  const treeData = treeList.map(item => [
    { v: item.code, f: item.name },
    item.parentId,
    ''
  ]);

  if (loading) return <PhoenixLoader />;
  return (
    <div>
      <div className="mb-9">
        <h2 className="mb-5">조직도</h2>

        <Row>
          <Col xs={12} xxl={6}>
            <PhoenixDocCard className="mb-4">
              <PhoenixDocCard.Header noPreview />
              <PhoenixDocCard.Body>
                {/* <AdvanceTableProvider {...table}>
                  <div className="mb-4">
                    <Row className="g-3">
                      <Col xs="auto">
                        <SearchBox
                          placeholder="Search teams"
                          onChange={handleSearchInputChange}
                        />
                      </Col>
                      <Col
                        xs="auto"
                        className="scrollbar overflow-hidden-y flex-grow-1"
                      ></Col>
                    </Row>
                  </div>

                  <div className="px-4 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
                    <TeamsTable />
                  </div>
                </AdvanceTableProvider> */}
                <div className="mt-15">
                  <Chart
                    chartType="OrgChart"
                    data={treeData}
                    options={chartOptions}
                    width="100%"
                    height="400px"
                  />
                </div>
              </PhoenixDocCard.Body>
            </PhoenixDocCard>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Teams;
