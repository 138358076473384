import Button from 'components/base/Button';
import { useEffect, useRef, FormEvent, ChangeEvent, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useAppContext } from 'providers/AppProvider';
import PhoenixLoader from 'components/common/PhoenixLoader';
import Avatar from 'components/base/Avatar';
import AvatarDropzone from 'components/common/AvatarDropzone';
import avatarPlaceholder from 'assets/img/team/avatar.webp';
import { ICompanyInfo, setCompanyInfo } from 'lib/firebase';
import { uploadCompanyImage } from 'lib/fbStorage';
import SingleMsg from 'pages/pages/SingleMsg';
import PageBreadcrumb from 'components/common/PageBreadcrumb';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

interface IUserInput {
  conpanyName: string;
}

const BreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Admin',
    url: '#!'
  },
  {
    label: 'Rank Manager',
    active: true
  }
];

const CompanyInfo = () => {
  const { userInfo, updateUserInfo, admin } = useAppContext();
  const inputsRef = useRef<IUserInput>({
    conpanyName: ''
  });
  const [loading, setLoading] = useState(true);
  const [avatar, setAvatar] = useState(avatarPlaceholder);
  const [companyImage, setCompanyImage] = useState<File>();

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles[0].size <= 102400) {
      setAvatar(URL.createObjectURL(acceptedFiles[0]));
      setCompanyImage(acceptedFiles[0]);
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (userInfo && userInfo.companyUuid) {
      const companyData: ICompanyInfo = {
        companyName: '',
        companyImageUrl: ''
      };

      if (companyImage) {
        companyData.companyImageUrl = await uploadCompanyImage(
          userInfo.companyUuid,
          companyImage
        );
      }

      companyData.companyName = inputsRef.current.conpanyName;
      setCompanyInfo(userInfo.companyUuid, companyData);
      updateUserInfo();
    }
  };

  useEffect(() => {
    if (userInfo) {
      if (userInfo.companyName) {
        inputsRef.current.conpanyName = userInfo.companyName;
      }

      if (userInfo.companyImageUrl) {
        setAvatar(userInfo.companyImageUrl);
      }

      setLoading(false);
    }
  }, [userInfo]);

  if (!admin) {
    return <SingleMsg msg="관리자만 사용 가능합니다." />;
  }
  if (loading) return <PhoenixLoader />;
  return (
    <div>
      <PageBreadcrumb items={BreadcrumbItems} />
      <div className="container w-60">
        <Form onSubmit={handleSubmit}>
          <Row className="flex-center py-5 mb-4">
            <Col sm={10} md={8} lg={5} className="text-center mb-7">
              <h3 className="text-1000">COMPANY INFO</h3>
            </Col>
          </Row>

          <Row className="mb-2">
            <Form.Group className="mb-3 text-start">
              <Form.Label htmlFor="name">NAME</Form.Label>
              <Form.Control
                id="name"
                type="text"
                placeholder="Name"
                defaultValue={inputsRef.current.conpanyName}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  inputsRef.current.conpanyName = e.target.value;
                }}
              />
            </Form.Group>
          </Row>

          <Row className="mt-5 mb-5">
            <Col md="auto">
              <Avatar src={avatar} placeholder size="4xl" />
            </Col>
            <Col>
              <AvatarDropzone onDrop={onDrop} />
            </Col>
          </Row>

          <Row className="mt-5 mb-5">
            <Col></Col>
            <Col>
              <Button variant="primary" type="submit" className="w-100 mb-3">
                수정
              </Button>
            </Col>
            <Col></Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default CompanyInfo;
