export interface IProcFile {
  name: string;
  url: string;
  size: number;
  projectId: string;
}

export interface ICheckInfo {
  userId: string;
  checkedAt: number;
}

export interface IProcDelivery {
  recipient: string[]; //전달, 수령인
  attachment: IProcFile[]; //전달, 배포자료
}

export interface IProcApproval {
  manager: string; // 승인, 담당자
  approver: string[]; // 승인, 승인자
  formFile: IProcFile[]; // 승인, 업무 양식
  attachment: IProcFile[]; // 승인, 첨부 자료
}

export interface IProcRequest {
  sender: string; // 요청, 발송인
  attachment: IProcFile[]; // 요청, 첨부 자료
}

export interface IProcess {
  id: string;
  class: 'delivery' | 'approval' | 'request'; // 분류
  title: string; // 제목
  content: string; // 업무 내용
  timeFrame: number; // 소요일
  delivery?: IProcDelivery;
  approval?: IProcApproval;
  request?: IProcRequest[];
  //
  // 처리용
  complete?: boolean;
  completeAt?: number;
}

export interface IProjectTempleate {
  rootCategory: string;
  category: string;
  title: string; // 제목
  presideTeam: string; // 주관 부서
  president: string; // 주관인
  period: number; // 업무 주기
  description: string; // 업무 정의 및 범위
  manager: string; // 진행자
  referrer: string[]; // 업무 참조자(배열)
  process: IProcess[]; // 업무
  createAt?: number;
  modifyAt?: number;
  endAt?: number;
  //
  // 처리용
  key?: string;
  timeFrame?: number;
  complete?: boolean;
  completeAt?: number;
  progress?: number;
}

export interface INotifyInfo {
  key: string;
  classCode: string;
  rootCategory: string;
  projectTitle: string;
  processTitle: string;
  projectId: string;
  processId: number;
  receiveAt: number;
}

export interface ITeamUserTable {
  team: string;
  teamId: string;
  user: string;
  userId: string;
}

export interface IBulletinBoard {
  key: string;
  userId: string;
  title: string;
  text: string;
  createAt: number;
}

export const TYPE_PERIOD = ['일상', '주간', '분기', '년간', '발생시'];

export const getClassName = (procClass: string): string => {
  let name = 'unknown';

  switch (procClass) {
    case 'delivery':
      name = '전달';
      break;

    case 'approval':
      name = '승인';
      break;

    case 'request':
      name = '요청';
      break;
  }
  return name;
};
