import { getFileExtension } from 'helpers/utils';
import AttachmentPreview from 'components/common/AttachmentPreview';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusSquare,
  faTimes,
  faClose
} from '@fortawesome/free-solid-svg-icons';
import {
  Col,
  Row,
  Form,
  InputGroup,
  FormControl,
  Modal,
  Table
} from 'react-bootstrap';
import Button from 'components/base/Button';
import {
  ChangeEvent,
  useEffect,
  useState,
  Dispatch,
  SetStateAction
} from 'react';
import {
  ITeamInfo,
  IUserInfo,
  getUserList,
  getTeamUserInfo,
  getUserName,
  getProcessCheck
} from 'lib/firebase';
import { IProcess, getClassName, ICheckInfo } from 'interfaces';
import { fileSizeToString } from 'lib/fbStorage';
import { downloadFile } from 'lib/fbStorage';
import dayjs from 'dayjs';

const ProcessInfo = ({
  show,
  setShow,
  userInfo,
  userList,
  process,
  projectId,
  processIndex,
  processOk
}: {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  userInfo?: IUserInfo;
  userList: IUserInfo[];
  process?: IProcess;
  projectId: string;
  processIndex: number;
  processOk: Dispatch<number>;
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [okButton, setOkButton] = useState(false);
  const [checkList, setCheckList] = useState<ICheckInfo[]>([]);

  const hCloseModal = () => {
    setShow(false);
    setOpenModal(false);
  };

  const hProcessOk = () => {
    processOk(processIndex);
    hCloseModal();
  };

  const updateData = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    if (show) {
      if (userInfo) {
        getProcessCheck(userInfo, projectId, processIndex).then(checkList => {
          setCheckList(checkList);
        });
      }

      updateData();
    }
  }, [show]);

  useEffect(() => {
    let find = true;
    checkList.some(v => {
      if (v.userId === userInfo?.uid) {
        find = false;
        return true;
      }
    });
    setOkButton(find);
  }, [checkList]);

  return (
    <div>
      <Modal
        size="lg"
        show={openModal}
        centered
        backdrop="static"
        onHide={hCloseModal}
        contentClassName="bg-100 p-6 border"
      >
        <Modal.Header className="border-0 p-0 mb-2">
          <h3 className="mb-0"></h3>
          <Button variant="phoenix-secondary" onClick={hCloseModal} size="sm">
            <FontAwesomeIcon icon={faTimes} className="text-danger" />
          </Button>
        </Modal.Header>
        <Modal.Body className="px-0 mb-6">
          {process && (
            <>
              <Row className="mt-0 top-0 gy-0 pb-1 gx-0 px-3">
                <Col xs={4} sm={3}>
                  <h6 className="text-600 fw-bolder lh-sm mt-1">업무명</h6>
                </Col>
                <Col xs={8} sm={9}>
                  <h5 className="mb-0 text-1100 lh-sm">{process.title}</h5>
                </Col>
              </Row>
              <Row className="mt-0 top-0 gy-0 pb-0 gx-0 px-3">
                <Col xs={4} sm={3}>
                  <h6 className="text-600 fw-bolder lh-sm mt-1">업무 분류</h6>
                </Col>
                <Col xs={8} sm={9}>
                  <p className="mb-0 text-1100 fw-semi-bold">
                    {getClassName(process.class)}
                  </p>
                </Col>
              </Row>
              <Row className="mt-0 top-0 gy-2 pb-3 gx-0 px-3">
                <Col xs={4} sm={3}>
                  <h6 className="text-600 fw-bolder lh-sm mt-1">업무 내용</h6>
                </Col>
                <Col xs={8} sm={9}>
                  <p className="fs-9 mb-0">
                    {process.content.split('\n').map((line, i) => {
                      return (
                        <span key={i}>
                          {line}
                          <br />
                        </span>
                      );
                    })}
                  </p>
                </Col>
              </Row>
              <Row className="mt-0 top-0 gy-0 pb-0 gx-0 px-3">
                <Col xs={4} sm={3}>
                  <h6 className="text-600 fw-bolder lh-sm mt-1">소요일</h6>
                </Col>
                <Col xs={8} sm={9}>
                  <p className="mb-0 text-1100 fw-semi-bold">
                    {process.timeFrame} 일
                  </p>
                </Col>
              </Row>

              {/* 전달 */}
              {process.delivery && (
                <>
                  <Row className="mt-0 top-0 gy-0 pb-0 gx-0 px-3">
                    <Col xs={4} sm={3}>
                      <h6 className="text-600 fw-bolder lh-sm mt-1">수령인</h6>
                    </Col>
                    <Col xs={8} sm={9}>
                      <p className="mb-0 text-1100 fw-semi-bold">
                        {process.delivery.recipient &&
                          process.delivery.recipient.map((v, i) => (
                            <span key={i}>
                              {i === 0 ? ' ' : ', '}
                              {getUserName(userList, v)}
                              {/* {isProcessCheck(process, userId)
                                ? '(확인)'
                                : '(미확인)'} */}
                            </span>
                          ))}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-0 top-0 gy-0 pb-0 gx-0 px-3">
                    <Col xs={4} sm={3}>
                      <h6 className="text-600 fw-bolder lh-sm mt-1">
                        첨부파일
                      </h6>
                    </Col>
                    <Col xs={8} sm={9}>
                      <p className="mb-0 text-1100 fw-semi-bold">
                        {process.delivery.attachment &&
                          process.delivery.attachment.map((v, i) => (
                            <span key={i}>
                              {i === 0 ? ' ' : ', '}
                              <a
                                href="javascript:void(0)"
                                style={{ color: 'blue' }}
                                onClick={() => downloadFile(v.url, v.name)}
                              >
                                {v.name}
                              </a>
                            </span>
                          ))}
                      </p>
                    </Col>
                  </Row>
                </>
              )}

              {/* 승인 */}
              {process.approval && (
                <>
                  <Row className="mt-0 top-0 gy-0 pb-0 gx-0 px-3">
                    <Col xs={4} sm={3}>
                      <h6 className="text-600 fw-bolder lh-sm mt-1">담당자</h6>
                    </Col>
                    <Col xs={8} sm={9}>
                      <p className="mb-0 text-1100 fw-semi-bold">
                        {getUserName(userList, process.approval.manager)}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-0 top-0 gy-0 pb-0 gx-0 px-3">
                    <Col xs={4} sm={3}>
                      <h6 className="text-600 fw-bolder lh-sm mt-1">승인자</h6>
                    </Col>
                    <Col xs={8} sm={9}>
                      <p className="mb-0 text-1100 fw-semi-bold">
                        {process.approval.approver &&
                          process.approval.approver.map((v, i) => (
                            <span key={i}>
                              {i === 0 ? ' ' : ', '}
                              {getUserName(userList, v)}
                            </span>
                          ))}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-0 top-0 gy-0 pb-0 gx-0 px-3">
                    <Col xs={4} sm={3}>
                      <h6 className="text-600 fw-bolder lh-sm mt-1">
                        업무 양식
                      </h6>
                    </Col>
                    <Col xs={8} sm={9}>
                      <p className="mb-0 text-1100 fw-semi-bold">
                        {process.approval.formFile &&
                          process.approval.formFile.map((v, i) => (
                            <span key={i}>
                              {i === 0 ? ' ' : ', '}
                              <a
                                href="javascript:void(0)"
                                style={{ color: 'blue' }}
                                onClick={() => downloadFile(v.url, v.name)}
                              >
                                {v.name}
                              </a>
                            </span>
                          ))}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-0 top-0 gy-0 pb-0 gx-0 px-3">
                    <Col xs={4} sm={3}>
                      <h6 className="text-600 fw-bolder lh-sm mt-1">
                        첨부 자료
                      </h6>
                    </Col>
                    <Col xs={8} sm={9}>
                      <p className="mb-0 text-1100 fw-semi-bold">
                        {process.approval.attachment &&
                          process.approval.attachment.map((v, i) => (
                            <span key={i}>
                              {i === 0 ? ' ' : ', '}
                              <a
                                href="javascript:void(0)"
                                style={{ color: 'blue' }}
                                onClick={() => downloadFile(v.url, v.name)}
                              >
                                {v.name}
                              </a>
                            </span>
                          ))}
                      </p>
                    </Col>
                  </Row>
                </>
              )}

              {/* 요청 */}
              {process.request &&
                process.request.length > 0 &&
                process.request.map((v, i) => (
                  <>
                    <Row className="mt-0 top-0 gy-0 pb-0 gx-0 px-3">
                      <Col xs={4} sm={3}>
                        <h6 className="text-600 fw-bolder lh-sm mt-1">
                          {'발송인 ' + (i + 1)}
                        </h6>
                      </Col>
                      <Col xs={8} sm={9}>
                        <p className="mb-0 text-1100 fw-semi-bold">
                          {getUserName(userList, v.sender)}
                        </p>
                      </Col>
                    </Row>
                    <Row className="mt-0 top-0 gy-0 pb-0 gx-0 px-3">
                      <Col xs={4} sm={3}>
                        <h6 className="text-600 fw-bolder lh-sm mt-1">
                          요청 자료
                        </h6>
                      </Col>
                      <Col xs={8} sm={9}>
                        <p className="mb-0 text-1100 fw-semi-bold">
                          {v.attachment &&
                            v.attachment.map((v, i) => (
                              <span key={i}>
                                {i === 0 ? ' ' : ', '}
                                <a
                                  href="javascript:void(0)"
                                  style={{ color: 'blue' }}
                                  onClick={() => downloadFile(v.url, v.name)}
                                >
                                  {v.name}
                                </a>
                              </span>
                            ))}
                        </p>
                      </Col>
                    </Row>
                  </>
                ))}

              <Row className="mt-0 top-0 gy-0 pb-0 gx-0 px-3">
                <Col xs={4} sm={3}>
                  <h6 className="text-600 fw-bolder lh-sm mt-1">확 인</h6>
                </Col>
                <Col xs={8} sm={9}>
                  <p className="mb-0 text-1100 fw-semi-bold">
                    {checkList.map((v, i) => (
                      <span key={i}>
                        {getUserName(userList, v.userId)}
                        {dayjs(v.checkedAt).format(' : YYYY-MM-DD HH:mm:ss')}
                      </span>
                    ))}
                  </p>
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="border-0 p-0">
          {okButton && (
            <Button
              variant="link"
              className="text-primary px-3 my-0"
              onClick={hProcessOk}
            >
              확인
            </Button>
          )}
          <Button
            variant="link"
            className="text-secondary px-3 my-0"
            onClick={hCloseModal}
          >
            닫기
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ProcessInfo;
