// import { Suspense, lazy } from 'react';
import MainLayout from 'layouts/MainLayout';
import { RouteObject, createBrowserRouter } from 'react-router-dom';

import App from 'App';
import SignIn from 'pages/pages/authentication/SignIn';
import SignUp from 'pages/pages/authentication/SignUp';
import SignOut from 'pages/pages/authentication/SignOut';
import ResetPassword from 'pages/pages/authentication/ResetPassword';
// import LockScreen from 'pages/pages/authentication/LockScreen';
// import TwoFA from 'pages/pages/authentication/TwoFA';
import ForgotPassword from 'pages/pages/authentication/ForgotPassword';
import Error404 from 'pages/error/Error404';
import Error403 from 'pages/error/Error403';
import Error500 from 'pages/error/Error500';
import Calendar from 'pages/apps/calendar/Calendar';
import MainLayoutProvider from 'providers/MainLayoutProvider';
// import PhoenixLoader from 'components/common/PhoenixLoader';
import SignInNoAuth from 'pages/pages/SignInNoAuth';
// import ComingSoon from 'pages/ComingSoon';
import ProjectDetail from 'pages/pages/ProjectDetail';
import Dashboard from 'pages/pages/Dashboard';
import BulletinBoard from 'pages/pages/BulletinBoard';
import BulletinBoardDetail from 'pages/pages/BulletinBoardDetail';
import BulletinBoardCreate from 'pages/pages/BulletinBoardCreate';
import NotyfyList from 'pages/pages/NotyfyList';
import PrivateProjectList from 'pages/pages/PrivateProjectList';
import CoProjectList from 'pages/pages/CoProjectList';
import StandardProjectList from 'pages/pages/StandardProjectList';
import ProjectCreate from 'pages/pages/ProjectCreate';
import CategoryManager from 'pages/pages/CategoryManager';
import Profile from 'pages/pages/Profile';
import Members from 'pages/pages/Members';
import Teams from 'pages/pages/Teams';

import CompanyInfo from 'pages/pages/CompanyInfo';
import TeamManager from 'pages/pages/TeamManager';
import RankManager from 'pages/pages/RankManager';
import MemberManager from 'pages/pages/MemberManager';
import UserManager from 'pages/pages/UserManager';

//const Profile = lazy(() => import('pages/pages/Profile'));

// const FontAwesomeExample = lazy(
//   () => import('pages/modules/components/FontAwesomeExample')
// );

// const FeatherIconsExample = lazy(
//   () => import('pages/modules/components/FeatherIconsExample')
// );

// const UniconsExample = lazy(
//   () => import('pages/modules/components/UniconsExample')
// );

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        children: [
          {
            index: true,
            element: <Dashboard />
          },
          {
            path: 'bulletinboard',
            element: <BulletinBoard />
          },
          {
            path: 'bulletinboard-detail/:key',
            element: <BulletinBoardDetail />
          },
          {
            path: 'bulletinboard-create/:key',
            element: <BulletinBoardCreate />
          },
          {
            path: 'notify-list',
            element: <NotyfyList />
          },
          {
            path: 'co-project-list',
            element: <CoProjectList />
          },
          {
            path: 'private-project-list',
            element: <PrivateProjectList />
          },
          {
            path: 'standard-project-list',
            element: <StandardProjectList />
          },
          {
            path: 'project-create/:projectId',
            element: <ProjectCreate />
          },
          {
            path: 'project-detail/:projectId',
            element: <ProjectDetail />
          },
          {
            path: 'project-category',
            element: <CategoryManager showEdit={false} />
          },
          {
            path: 'calendar',
            element: <Calendar />
          },
          {
            path: 'profile',
            element: <Profile />
          },
          {
            path: 'company-info',
            element: <CompanyInfo />
          },
          {
            path: 'members',
            element: <Members />
          },
          {
            path: 'teams',
            element: <Teams />
          },
          {
            path: 'team-manager',
            element: <TeamManager />
          },
          {
            path: 'rank-manager',
            element: <RankManager />
          },
          {
            path: 'member-manager',
            element: <MemberManager />
          },
          {
            path: 'category-manager',
            element: <CategoryManager showEdit={true} />
          },
          {
            path: 'user-manager',
            element: <UserManager />
          }
        ]
      },
      {
        path: '/pages/auth/',
        children: [
          {
            path: 'sign-in',
            element: <SignIn />
          },
          {
            path: 'sign-up',
            element: <SignUp />
          },
          {
            path: 'sign-out',
            element: <SignOut />
          },
          {
            path: 'forgot-password',
            element: <ForgotPassword />
          },
          {
            path: 'reset-password',
            element: <ResetPassword />
          },
          {
            path: 'no-auth',
            element: <SignInNoAuth />
          }
        ]
      },
      {
        path: '/pages/errors/',
        children: [
          {
            path: '404',
            element: <Error404 />
          },
          {
            path: '403',
            element: <Error403 />
          },
          {
            path: '500',
            element: <Error500 />
          }
        ]
      },
      {
        path: '*',
        element: <Error404 />
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
