import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
  listAll,
  getBytes
} from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { IProcFile } from 'interfaces';

export const fileSizeToString = (size: number): string => {
  let ret = '? KB';
  if (size) {
    if (size >= 1073741824) {
      ret = (size / 1024 / 1024 / 1024).toFixed(2) + 'GB';
    } else if (size >= 1048576) {
      ret = (size / 1024 / 1024).toFixed(2) + 'MB';
    } else {
      ret = (size / 1024).toFixed(2) + 'KB';
    }
  }
  return ret;
};

export const uploadUserImage = async (userId: string, file: Blob) => {
  try {
    const storage = getStorage();
    const fileRef = ref(storage, `/users/${userId}/` + uuidv4());

    await uploadBytes(fileRef, file);

    return await getDownloadURL(fileRef);
  } catch (error) {
    return '';
  }
};

export const uploadCompanyImage = async (companyId: string, file: Blob) => {
  try {
    const storage = getStorage();
    const fileRef = ref(storage, `/companys/${companyId}/` + uuidv4());

    await uploadBytes(fileRef, file);

    return await getDownloadURL(fileRef);
  } catch (error) {
    return '';
  }
};

export const convertUrlToFile = async (url: string, name: string) => {
  const response = await fetch(url);
  const data = await response.blob();
  // const ext = url.split('.').pop(); // url 구조에 맞게 수정할 것
  // const filename = url.split('/').pop(); // url 구조에 맞게 수정할 것
  // const metadata = { type: `file/${ext}` };
  // return new File([data], filename!, metadata);
  return new File([data], name!);
};

export const uploadProjectFile = async (
  projectId: string,
  localFile: string,
  name: string
) => {
  try {
    const storage = getStorage();
    const refPath = `/project/${projectId}/` + uuidv4() + '/' + name;
    const fileRef = ref(storage, refPath);
    const file = await convertUrlToFile(localFile, name);
    await uploadBytes(fileRef, file);
    // return await getDownloadURL(fileRef);
    return refPath;
  } catch (error) {
    return '';
  }
};

export const copyProjectFiles = async (
  projectId: string,
  srcPath: string,
  fineName: string
) => {
  const storage = getStorage();
  const fileRef = ref(storage, srcPath);
  const arrayData = await getBytes(fileRef);

  if (arrayData) {
    const data = new Blob([arrayData]);

    if (data) {
      try {
        const refPath = `/project/${projectId}/` + uuidv4() + '/' + fineName;
        const destFileRef = ref(storage, refPath);
        await uploadBytes(destFileRef, data);
        return refPath;
      } catch (error) {
        return '';
      }
    }
  }

  return '';
};

export const convertUrltoData = async (url: string) => {
  const response = await fetch(url);
  const data = await response.blob();
  return URL.createObjectURL(data);
};

export const downloadFile = async (path: string, fileName: string) => {
  const storage = getStorage();
  const fileRef = ref(storage, path);
  const url = await getDownloadURL(fileRef);
  if (url) {
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};

export const deleteFile = async (url: string) => {
  const storage = getStorage();
  const fileRef = ref(storage, url);
  try {
    await deleteObject(fileRef);
  } catch (error) {
    console.log(error);
  }
};

export function deleteFolder(path: string) {
  const storage = getStorage();
  const fileRef = ref(storage, path);
  listAll(fileRef)
    .then(res => {
      res.items.forEach(fileRef => {
        deleteFile(fileRef.fullPath);
      });
      res.prefixes.forEach(folderRef => {
        deleteFolder(folderRef.fullPath);
      });
    })
    .catch(() => {
      alert('프로젝트 파일 삭제 실패');
    });
}

export const deleteProjectFiles = async (projectId: string) => {
  try {
    deleteFolder(`/project/${projectId}/`);
  } catch (error) {
    console.log(error);
  }
};
