import { IconProp } from '@fortawesome/fontawesome-svg-core';
// import { faHourglassHalf } from '@fortawesome/free-regular-svg-icons';
import { Icon, UilChartPie, UilCube } from '@iconscout/react-unicons';

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
  admin?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
  admin?: boolean;
}

export const routes: RouteItems[] = [
  {
    label: 'dashboard',
    horizontalNavLabel: 'home',
    active: true,
    icon: UilChartPie,
    labelDisabled: true,
    pages: [
      {
        name: 'dashboard',
        path: '/',
        pathName: 'dashboard',
        icon: 'home',
        active: true
      }
    ]
  },
  {
    label: 'apps',
    icon: UilCube,
    labelDisabled: true,
    pages: [
      {
        name: '게시판',
        icon: 'file-text',
        path: 'bulletinboard',
        pathName: 'bulletinboard',
        active: true
      },
      {
        name: '업무 알림',
        icon: 'message-square',
        path: 'notify-list',
        pathName: 'notify-list',
        active: true
      },
      {
        name: '협업 업무',
        icon: 'file',
        path: 'co-project-list',
        pathName: 'co-project-list',
        active: true
      },
      {
        name: '개인 업무',
        icon: 'file',
        path: 'private-project-list',
        pathName: 'private-project-list',
        active: true
      },
      {
        name: '표준 업무',
        icon: 'file',
        path: 'standard-project-list',
        pathName: 'standard-project-list',
        active: true
      },
      {
        name: '업무 등록',
        icon: 'file-plus',
        path: 'project-create/new',
        pathName: 'project-create',
        active: true
      },
      {
        name: '업무 카테코리',
        icon: 'folder',
        path: 'project-category',
        pathName: 'project-category',
        active: true
      },
      // {
      //   name: '달력',
      //   icon: 'calendar',
      //   path: 'calendar',
      //   pathName: 'app-calendar',
      //   active: true
      // },
      {
        name: '내정보',
        icon: 'user',
        path: 'profile',
        pathName: 'profile',
        active: true
      },
      {
        name: '직원',
        icon: 'users',
        path: 'members',
        pathName: 'members',
        active: true
      },
      {
        name: '조직도',
        icon: 'layers',
        path: 'teams',
        pathName: 'teams',
        active: true
      }
    ]
  },
  {
    label: '관리자',
    icon: UilCube,
    labelDisabled: false,
    admin: true,
    pages: [
      {
        name: '회사 정보',
        icon: 'info',
        path: 'company-info',
        pathName: 'company-info',
        active: true,
        admin: true
      },
      {
        name: '부서 관리',
        icon: 'layers',
        path: 'team-manager',
        pathName: 'team-manager',
        active: true,
        admin: true
      },
      {
        name: '직급 관리',
        icon: 'list',
        path: 'rank-manager',
        pathName: 'rank-manager',
        active: true,
        admin: true
      },
      {
        name: '직원 관리',
        icon: 'user-plus',
        path: 'member-manager',
        pathName: 'member-manager',
        active: true,
        admin: true
      },
      {
        name: '카테고리 관리',
        icon: 'folder-plus',
        path: 'category-manager',
        pathName: 'category-manager',
        active: true,
        admin: true
      }
    ]
  }
];
