import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import {
  ChangeEvent,
  useEffect,
  useState,
  createContext,
  useContext,
  FormEvent,
  KeyboardEvent
} from 'react';
import Button from 'components/base/Button';
import { Col, Row, Form } from 'react-bootstrap';
import RankManagerTable, {
  rankManagerTablecolumns
} from 'components/tables/RankManagerTable';
import { deleteRank, setRank, getRank } from 'lib/firebase';
import { useAppContext } from 'providers/AppProvider';
import PhoenixLoader from 'components/common/PhoenixLoader';
import PhoenixDocCard from 'components/base/PhoenixDocCard';
import SingleMsg from 'pages/pages/SingleMsg';

interface RankManagerContextInterFace {
  editRankInfo: (code: string) => void;
  deleteRankInfo: (code: string) => void;
}

export const RankManagerContext = createContext(
  {} as RankManagerContextInterFace
);

const RankManager = () => {
  const { userInfo, rankList, setRankList, admin } = useAppContext();
  const [loading, setLoading] = useState(true);
  // const [rankList, setRankList] = useState<TRankInfo[]>();
  const [inputs, setInputs] = useState({
    code: '',
    name: ''
  });

  const table = useAdvanceTable({
    data: rankList ? rankList : [],
    columns: rankManagerTablecolumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: false
  });

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const editRankInfo = (code: string) => {
    if (rankList) {
      const data = rankList.find(v => v.code === code);
      if (data) {
        setInputs({
          ...inputs,
          code: data.code,
          name: data.name
        });
      }
    }
  };

  const deleteRankInfo = (code: string) => {
    // console.log('deleteRankInfo ' + code);
    if (userInfo && userInfo.companyUuid && rankList) {
      setRankList(rankList.filter(rank => rank.code !== code));
      deleteRank(userInfo.companyUuid, code);
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!inputs.code) {
      alert('직급 코드를 입력하세요');
      return;
    }

    if (!inputs.name) {
      alert('직급 이름을 입력하세요');
      return;
    }

    if (rankList && userInfo && userInfo.companyUuid) {
      const data = rankList.find(v => v.code === inputs.code);
      if (data) {
        data.code = inputs.code;
        data.name = inputs.name;
        setRank(userInfo.companyUuid, data);
      } else {
        const newRank = {
          code: inputs.code,
          name: inputs.name
        };
        rankList.push(newRank);
        setRank(userInfo.companyUuid, newRank);
      }
      setRankList([...rankList]);
    }
  };

  useEffect(() => {
    if (userInfo && userInfo.companyUuid) {
      getRank(userInfo.companyUuid).then(rankList => {
        setRankList(rankList);
        setLoading(false);
      });
    }
  }, [userInfo]);

  if (!admin) {
    return <SingleMsg msg="관리자만 사용 가능합니다." />;
  }

  if (loading) return <PhoenixLoader />;
  return (
    <RankManagerContext.Provider value={{ editRankInfo, deleteRankInfo }}>
      <div>
        <div className="mb-9">
          <h2 className="mb-5">직급 관리</h2>

          <Row>
            <Col xs={12} xxl={6}>
              <PhoenixDocCard className="mb-4">
                <PhoenixDocCard.Body>
                  <AdvanceTableProvider {...table}>
                    <div className="mb-4">
                      <Row className="g-3">
                        <Col xs="auto">
                          <SearchBox
                            placeholder="Search"
                            onChange={handleSearchInputChange}
                            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                              if (e.code === 'Enter') {
                                e.preventDefault();
                              }
                            }}
                          />
                        </Col>
                        <Col
                          xs="auto"
                          className="scrollbar overflow-hidden-y flex-grow-1"
                        ></Col>
                      </Row>
                    </div>

                    <div className="px-4 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
                      <RankManagerTable />
                    </div>
                  </AdvanceTableProvider>
                </PhoenixDocCard.Body>
              </PhoenixDocCard>
            </Col>
            <Col xs={12} xxl={6}>
              <PhoenixDocCard className="mb-4">
                <PhoenixDocCard.Body>
                  <div>
                    <Form onSubmit={handleSubmit}>
                      <Row className="mb-2">
                        <Form.Group className="mb-3 text-start">
                          <Form.Label htmlFor="name">코드</Form.Label>
                          <Form.Control
                            id="code"
                            type="text"
                            placeholder="코드"
                            value={inputs.code}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              setInputs({
                                ...inputs,
                                [e.target.id]: e.target.value
                              });
                            }}
                          />
                        </Form.Group>

                        <Form.Group className="mb-3 text-start">
                          <Form.Label htmlFor="name">직급</Form.Label>
                          <Form.Control
                            id="name"
                            type="text"
                            placeholder="직급"
                            value={inputs.name}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                              setInputs({
                                ...inputs,
                                [e.target.id]: e.target.value
                              });
                            }}
                          />
                        </Form.Group>
                      </Row>

                      <Row className="mt-5 mb-5">
                        <Col></Col>
                        <Col>
                          <Button
                            variant="primary"
                            type="submit"
                            className="w-100 mb-3"
                          >
                            수정
                          </Button>
                        </Col>
                        <Col></Col>
                      </Row>
                    </Form>
                  </div>
                </PhoenixDocCard.Body>
              </PhoenixDocCard>
            </Col>
          </Row>
        </div>
      </div>
    </RankManagerContext.Provider>
  );
};

export const useRankManagerContext = () => useContext(RankManagerContext);
export default RankManager;
