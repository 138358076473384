import { useEffect, useState } from 'react';
import { useAppContext } from 'providers/AppProvider';
import { getUserName, dbBulletinBoardInfo } from 'lib/firebase';
// import { downloadFile } from 'lib/fbStorage';
import { useParams, useSearchParams } from 'react-router-dom';
import { IBulletinBoard } from 'interfaces';
// import classNames from 'classnames';
import SingleMsg from 'pages/pages/SingleMsg';
import { Card, Row, Col } from 'react-bootstrap';
import dayjs from 'dayjs';

const BulletinBoardDetail = () => {
  const { key } = useParams();
  const { userInfo, userList } = useAppContext();
  const [data, setData] = useState<IBulletinBoard>();

  useEffect(() => {
    if (userInfo && key) {
      dbBulletinBoardInfo(userInfo, key).then(retData => {
        setData(retData);
      });
    }
  }, [userInfo]);

  if (data && !data.key) {
    return <SingleMsg msg="데이터 없음" />;
  }

  return (
    <div className="d-flex flex-column gap-2 mb-4">
      <Card>
        <Card.Body>
          <Row className="gy-4 py-0 gx-0 h-100">
            <Col xs={12} lg={8} className="h-100 scrollbar">
              <Row className="mt-0 top-0 gy-4 pb-3 gx-0 px-3">
                <Col xs={4} sm={3}>
                  <h6 className="text-600 fw-bolder lh-sm mt-1">제목</h6>
                </Col>
                <Col xs={8} sm={9}>
                  <h4 className="mb-0 text-1100 lh-sm">{data?.title}</h4>
                </Col>
              </Row>
              <Row className="mt-0 top-0 gy-0 pb-1 gx-0 px-3">
                <Col xs={4} sm={3}>
                  <h6 className="text-600 fw-bolder lh-sm mt-1">작성자</h6>
                </Col>
                <Col xs={8} sm={9}>
                  <p className="mb-0 text-1100 fw-semi-bold">
                    {data && getUserName(userList, data.userId)}
                  </p>
                </Col>
              </Row>
              <Row className="mt-0 top-0 gy-0 pb-1 gx-0 px-3">
                <Col xs={4} sm={3}>
                  <h6 className="text-600 fw-bolder lh-sm mt-1">작성일</h6>
                </Col>
                <Col xs={8} sm={9}>
                  <p className="mb-0 text-1100 fw-semi-bold">
                    {data?.createAt &&
                      dayjs(data.createAt).format('YYYY-MM-DD HH:mm:ss')}
                  </p>
                </Col>
              </Row>
              <Row className="mt-0 top-0 gy-2 pb-3 gx-0 px-3">
                <Col xs={4} sm={3}>
                  <h6 className="text-600 fw-bolder lh-sm mt-1">본문</h6>
                </Col>
                <Col xs={8} sm={9}>
                  {data?.text.split('\n').map((value, index) => {
                    return (
                      <p className="fs-9 mb-0" key={index}>
                        {value}
                      </p>
                    );
                  })}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default BulletinBoardDetail;
