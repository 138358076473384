import Button from 'components/base/Button';
import { useEffect, useRef, FormEvent, ChangeEvent, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { setUserInfo, IUserInfo, changePassword } from 'lib/firebase';
import { uploadUserImage } from 'lib/fbStorage';
import { useAppContext } from 'providers/AppProvider';
import PhoenixLoader from 'components/common/PhoenixLoader';
import Avatar from 'components/base/Avatar';
import AvatarDropzone from 'components/common/AvatarDropzone';
import avatarPlaceholder from 'assets/img/team/avatar.webp';
import { useNavigate } from 'react-router-dom';

interface IUserInput {
  userName: string;
  mobilePhone: string;
  handle?: (code: string) => void;
}

const Profile = () => {
  const { user, userInfo, updateUserInfo } = useAppContext();
  const inputsRef = useRef<IUserInput>({
    userName: '',
    mobilePhone: ''
  });
  const [loading, setLoading] = useState(true);
  const [avatar, setAvatar] = useState(avatarPlaceholder);
  const [profilePhoto, setProfilePhoto] = useState<File>();
  const navigate = useNavigate();

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles[0].size <= 102400) {
      setAvatar(URL.createObjectURL(acceptedFiles[0]));
      setProfilePhoto(acceptedFiles[0]);
    } else {
      alert('파일 용량 초과');
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (user?.uid && userInfo) {
      const userData: IUserInfo = userInfo;

      if (profilePhoto) {
        userData.userImageUrl = await uploadUserImage(user.uid, profilePhoto);
      }

      userData.userName = inputsRef.current.userName;
      userData.mobilePhone = inputsRef.current.mobilePhone;
      // if (user.email) {
      //   userData.email = user.email;
      // }
      setUserInfo(userData);
      updateUserInfo();
    }
  };

  const onClickPwChange = async () => {
    if (user?.email) {
      const ret = await changePassword(user.email);
      if (ret) {
        alert(
          '비밀번호 재설정 이메일을 보냈습니다. 비밀번호 재설정 후 다시 로그인 하세요.'
        );
        navigate('/pages/auth/sign-in');
      } else {
        alert('비밀번호 재설정 메일 전송 실패');
      }
    } else {
      alert('사용자 정보가 없습니다.');
    }
  };

  useEffect(() => {
    if (userInfo) {
      if (userInfo.userName) {
        inputsRef.current.userName = userInfo.userName;
      }
      if (userInfo.mobilePhone) {
        inputsRef.current.mobilePhone = userInfo.mobilePhone;
      }
      if (userInfo.userImageUrl) {
        setAvatar(userInfo.userImageUrl);
      }
      setLoading(false);
    }
  }, [userInfo]);

  if (loading) return <PhoenixLoader />;
  return (
    <div className="container w-60">
      <Form onSubmit={handleSubmit}>
        <Row className="flex-center py-5 mb-4">
          <Col sm={10} md={8} lg={5} className="text-center mb-7">
            <h3 className="text-1000">PROFILE</h3>
          </Col>
        </Row>

        <Row className="mb-2">
          <Form.Group className="mb-3 text-start">
            <Form.Label htmlFor="name">NAME</Form.Label>
            <Form.Control
              id="name"
              type="text"
              placeholder="Name"
              defaultValue={inputsRef.current.userName}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                inputsRef.current.userName = e.target.value;
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3 text-start">
            <Form.Label htmlFor="name">MOBILE PHONE</Form.Label>
            <Form.Control
              id="mobile_phone"
              type="text"
              placeholder="Name"
              defaultValue={inputsRef.current.mobilePhone}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                inputsRef.current.mobilePhone = e.target.value;
              }}
            />
          </Form.Group>
        </Row>

        <Row className="mt-5 mb-5">
          <Col md="auto">
            <Avatar src={avatar} placeholder size="4xl" />
          </Col>
          <Col>
            <AvatarDropzone onDrop={onDrop} />
          </Col>
        </Row>

        <Row className="mt-5 mb-5">
          <Col></Col>
          <Col>
            <Button
              variant="secondary"
              className="w-100 mb-3"
              onClick={onClickPwChange}
            >
              비밀번호 변경
            </Button>
          </Col>
          <Col>
            <Button variant="primary" type="submit" className="w-100 mb-3">
              수정
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Profile;
