import { Stack } from 'react-bootstrap';
import starterImg from 'assets/img/spot-illustrations/2.png';
import starterDarkImg from 'assets/img/spot-illustrations/dark_2.png';
import Button from 'components/base/Button';
import { useNavigate } from 'react-router-dom';

interface SingleMsgProps {
  msg: string;
}

const SingleMsg = (props: SingleMsgProps) => {
  const navigate = useNavigate();

  return (
    <Stack className="flex-center content-min-h">
      <div className="text-center py-9">
        <img
          src={starterImg}
          width={470}
          className="img-fluid mb-7 d-dark-none"
          alt="starter"
        />
        <img
          src={starterDarkImg}
          width={470}
          className="img-fluid mb-7 d-light-none"
          alt="starter"
        />
        <h1 className="text-800 fw-normal mb-5">{props.msg}</h1>
        <Button onClick={() => navigate(-1)} className="btn btn-lg btn-primary">
          Go back
        </Button>
      </div>
    </Stack>
  );
};

export default SingleMsg;
